import React from "react";
import {
    useTheme,
    MenuItem,
    Stack,
    Typography,
    TextField,
} from "@mui/material";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import ButtonRemid from "core/components/remid-button/Button";
import { TransferUangViewModel } from "presentation/pages/viewmodel/transfer-uang-alfa";
import useLanguage from "config/hooks/useLanguage";
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";

type FormPengirimanUangProps = {
    viewModel: TransferUangViewModel;
};

const FormPengirimanUang: React.FC<FormPengirimanUangProps> = ({
    viewModel,
}) => {
    const { t } = useLanguage();

    const { palette } = useTheme();

    const {
        handleSubmit,
        onSubmit,
        errors,
        register,
        control,
        sumberDanaList,
        tujuanKirimList,
    } = viewModel;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "1.25rem",
                        textAlign: "start",
                        width: "100%",
                    }}
                >
                    {t("general.form_moneytf1")}
                </Typography>
                <TextFieldRemid
                    {...viewModel.nameRegister}
                    onChange={viewModel.handleNameChange}
                    fullWidth
                    shrink
                    label={t("general.form_moneytf2")}
                    error={Boolean(errors.receiverName)}
                    helperText={t(errors.receiverName?.message)}
                />
                {/* Minta receiverNoIdCard dibuang untuk menu C2A */}
                {/* <TextFieldRemid
                    {...register("receiverNoIdCard")}
                    number
                    fullWidth
                    shrink
                    inputProps={{
                        maxLength: 16,
                    }}
                    label={t("general.form_moneytf3")}
                    error={Boolean(errors.receiverNoIdCard)}
                    helperText={t(errors.receiverNoIdCard?.message)}
                /> */}
                <TextFieldRemid
                    {...viewModel.phoneRegister}
                    onChange={viewModel.handlePhoneChange}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 17,
                    }}
                    fullWidth
                    shrink
                    label={t("general.form_moneytf4")}
                    error={Boolean(errors.receiverPhoneNo)}
                    helperText={t(errors.receiverPhoneNo?.message)}
                />
                <Controller
                    control={control}
                    name="amount"
                    render={({ field: { onChange, name, value, onBlur } }) => (
                        <NumericFormat
                            onBlur={onBlur}
                            name={name}
                            value={value}
                            onKeyDown={(e) => {
                                const { key } = e;
                                if (key === "." || key === "-" || key === ",") {
                                    e.preventDefault();
                                }
                            }}
                            onValueChange={(e) => {
                                onChange(e.formattedValue);
                            }}
                            customInput={TextField}
                            allowLeadingZeros={false}
                            variant="filled"
                            fullWidth
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            InputProps={{
                                startAdornment: (
                                    <span
                                        style={{
                                            padding: "25px 12px 8px 0px",
                                        }}
                                    >
                                        Rp.
                                    </span>
                                ),
                                disableUnderline: true,
                            }}
                            label={t("general.form_moneytf5")}
                            autoComplete="off"
                            error={Boolean(errors.amount)}
                            helperText={t(errors.amount?.message)}
                            sx={{
                                "& input": {
                                    textAlign: "inherit",
                                },
                                filter: "drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))",
                                "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused":
                                    {
                                        color: palette.text.primary,
                                    },
                                "& .MuiFilledInput-root": {
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    backgroundColor: "white",
                                    "&:hover": {
                                        backgroundColor: "white",
                                    },
                                    "&.Mui-focused": {
                                        backgroundColor: "white",
                                    },
                                    "&.Mui-disabled": {
                                        color: "rgba(0, 0, 0, 0.38)",
                                        backgroundColor: "white",
                                    },
                                },
                            }}
                        />
                    )}
                />
                <Controller
                    name="sourceOfFund"
                    control={control}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            fullWidth
                            shrink
                            select
                            label={t("general.form_moneytf6")}
                            error={Boolean(errors.sourceOfFund)}
                            helperText={t(errors.sourceOfFund?.message)}
                        >
                            {Array.isArray(sumberDanaList?.data)
                                ? sumberDanaList?.data.map((item) => {
                                      return (
                                          <MenuItem
                                              key={item.id}
                                              value={item.id.toString()}
                                          >
                                              {item.labelName}
                                          </MenuItem>
                                      );
                                  })
                                : []}
                        </TextFieldRemid>
                    )}
                />
                <Controller
                    name="sendIntention"
                    control={control}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            fullWidth
                            shrink
                            select
                            label={t("general.form_moneytf7")}
                            error={Boolean(errors.sendIntention)}
                            helperText={t(errors.sendIntention?.message)}
                        >
                            {Array.isArray(tujuanKirimList?.data)
                                ? tujuanKirimList?.data.map((item) => {
                                      return (
                                          <MenuItem
                                              key={item.id}
                                              value={item.id.toString()}
                                          >
                                              {item.labelName}
                                          </MenuItem>
                                      );
                                  })
                                : []}
                        </TextFieldRemid>
                    )}
                />
                <ButtonRemid type="submit">{t("auth.next_text")}</ButtonRemid>
            </Stack>
        </form>
    );
};

export default FormPengirimanUang;
