import AuthApi from "data/Auth";
import OTPApi from "data/OTP";
import ForgotPinUseCase from "domain/interactor/Auth/ForgotPinUseCase";
import OTPUseCase from "domain/interactor/OTP";
import ForgotPinPage from "presentation/pages/view/forgot-pin/ForgotPinPage";
import useForgotPinViewModel from "presentation/pages/viewmodel/forgot-pin";

const useCase = new ForgotPinUseCase(new AuthApi());
const otpUseCase = new OTPUseCase(new OTPApi());

export default function () {
    const viewModel = useForgotPinViewModel({ otpUseCase, useCase });

    return <ForgotPinPage viewModel={viewModel} />;
}
