import { ALL_DIGIT } from "presentation/validation/constants";

export const unFormatPhone = (phone: string) => {
    return phone.replace(/ /g, "");
};

const phoneFormatter = (input: string) => {
    const unTransformInput = unFormatPhone(input);
    if (!ALL_DIGIT.test(unTransformInput)) return unTransformInput;
    return unTransformInput.replace(/\d{4}(?=.)/g, "$& ");
};

export default phoneFormatter;
