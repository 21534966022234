import React from "react";
import { SxProps, Stack, Typography, useTheme } from "@mui/material";
import ButtonRemid from "core/components/remid-button/Button";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import useLanguage from "config/hooks/useLanguage";
import { ForgotPasswordViewModel } from "presentation/pages/viewmodel/auth-forgot-password";

export type FormForgotPasswordUsernameProps = {
    viewModel: ForgotPasswordViewModel;
    sx?: SxProps;
};

const FormForgotPasswordUsername: React.FC<FormForgotPasswordUsernameProps> = (
    props
) => {
    const { t } = useLanguage();

    const { palette } = useTheme();

    const { viewModel } = props;

    const id = React.useId()

    const {
        handleSubmit,
        register,
        errors,
        submitButtonStatus,
        onSubmit,
        otpLoading,
    } = viewModel;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <Typography sx={{ fontWeight: 700, fontSize: "1.25rem" }}>
                    {/* Gunakan Email atau Nomor HP untuk Ubah Password Anda */}
                    {/* {t("forgot_pass.label1")} */}
                    {viewModel.selectedMethod === "sms" || viewModel.selectedMethod === "whatsapp"
                        ? "Gunakan Nomor HP untuk Ubah Password Anda"
                        : viewModel.selectedMethod === "email"
                            ? "Gunakan Email untuk Ubah Password Anda"
                            : "Gunakan Email atau Nomor HP untuk Ubah Password Anda"
                    }
                </Typography>

                <TextFieldRemid
                    {...register("username")}
                    id={id}
                    inputProps={{
                        autoComplete: id
                    }}
                    fullWidth
                    shrink
                    label={
                        viewModel.selectedMethod === "sms"
                            ? "No. Handphone"
                            : viewModel.selectedMethod === "whatsapp"
                                ? "No. Handphone (WhatsApp)"
                                : "Email"
                    }
                    error={Boolean(errors.username)}
                    helperText={t(errors.username?.message)}
                />

                <Typography sx={{ fontSize: ".62rem" }}>
                    {t("forgot_pass.label2")}&nbsp;
                    <Typography
                        component="span"
                        fontSize="inherit"
                        sx={{ color: palette.primary.main }}
                    >
                        {t("forgot_pass.label3")}&nbsp;
                    </Typography>
                    <Typography component="span" fontSize="inherit">
                        {/* {t("forgot_pass.label4")} */}

                        {viewModel.selectedMethod === "sms"
                            ? "ke Nomor Handphone Anda"
                            : viewModel.selectedMethod === "whatsapp"
                                ? "ke Nomor Handphone (WhatsApp) Anda"
                                : "ke Email Anda"
                        }
                    </Typography>
                </Typography>

                <ButtonRemid
                    disabled={!submitButtonStatus}
                    loading={otpLoading}
                    type="submit"
                >
                    {t("general.send")}
                </ButtonRemid>
            </Stack>
        </form>
    );
};

export default FormForgotPasswordUsername;
