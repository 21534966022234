import * as yup from "yup";
import {
    MAXIMUM_TRANSFER_AMOUNT,
    MINIMUM_TRANSFER_AMOUNT,
} from "presentation/validation/constants";

const transferAmountValidation = yup
    .string()
    .required("validation.amount_required")
    .test({
        name: "minimum_amoun",
        message: "validation.transfer_min",
        test: (value) => {
            if (value === undefined) return false;

            const cast = Number(value.replace(/\./g, ""));

            return cast >= MINIMUM_TRANSFER_AMOUNT;
        },
    })
    .test({
        name: "maximum_amount",
        message: "validation.transfer_max",
        test: (value) => {
            if (value === undefined) return false;

            const cast = Number(value.replace(/\./g, ""));

            return cast <= MAXIMUM_TRANSFER_AMOUNT;
        },
    });

export default transferAmountValidation;
