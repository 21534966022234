import React from "react";
import ReportIcon from "@mui/icons-material/Report";
import { IconButton, TextField, TextFieldProps, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";

type TextFieldRemidProps = {
    number?: boolean;
    shrink?: boolean;
    readOnly?: boolean;
    password?: boolean;
    iconButton?: React.ReactElement;
    onIconButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
} & TextFieldProps;

const TextFieldRemid = React.forwardRef<HTMLDivElement, TextFieldRemidProps>(
    (props, ref) => {
        const { palette } = useTheme();

        const {
            number,
            password,
            iconButton,
            onIconButtonClick,
            error,
            onChange,
            InputProps,
            inputProps,
            readOnly,
            sx,
            variant,
            onKeyDown,
            shrink = false,
            ...rest
        } = props;

        const style: SxProps = {
            "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":
                {
                    WebkitBoxShadow: "0 0 0px 1000px #ffffff inset !important",
                },

            "& input": {
                textAlign: "inherit",
            },
            "& input[type=number]": {
                appearance: "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
                appearance: "none",
                margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
                appearance: "none",
                margin: 0,
            },
            filter: "drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))",
            "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                color: palette.text.primary,
            },
            "& .MuiFilledInput-root": {
                borderRadius: "4px",
                border: "1px solid #ccc",
                backgroundColor: "white",
                "&:hover": {
                    backgroundColor: "white",
                },
                "&.Mui-focused": {
                    backgroundColor: "white",
                },
                "&.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.38)",
                    backgroundColor: "white",
                },
            },
        };

        const handleChange = (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
            onChange?.(e);
        };

        const handleOnlyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const { key } = e;
            if (number && key === "e") {
                e.preventDefault();
                return;
            }
            onKeyDown?.(e);
        };

        const onIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            onIconButtonClick?.(e);
        };

        return (
            <TextField
                ref={ref}
                variant={"filled"}
                error={error}
                type={number ? "number" : password ? "password" : "text"}
                onChange={handleChange}
                onKeyDown={number ? handleOnlyNumber : () => {}}
                InputProps={{
                    disableUnderline: true,
                    endAdornment:
                        error && !password ? (
                            <ReportIcon color="error" />
                        ) : iconButton ? (
                            <IconButton onClick={onIconClick}>
                                {iconButton}
                            </IconButton>
                        ) : null,
                    ...InputProps,
                }}
                inputProps={{
                    readOnly,
                    inputMode: number ? "numeric" : "text",
                    ...inputProps,
                }}
                InputLabelProps={{ shrink }}
                sx={{ ...style, ...sx }}
                {...rest}
            />
        );
    }
);

export default TextFieldRemid;
