import * as yup from 'yup'
import { ALL_DIGIT, PIN_LENGTH } from '../constants'

const pinValidation = yup.string()
    .min(PIN_LENGTH, "validation.pin_length")
    .test({
        message: "validation.pin_not_valid",
        test: (value) => {
            return !Boolean(value && !ALL_DIGIT.test(value))
        }
    })
    // .test({
    //     message: "auth.pin_length",
    //     test: (value) => {
    //         return !Boolean(value && value.length !== PIN_LENGTH)
    //     }
    // })

export default pinValidation