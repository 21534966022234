import { Navigate, RouteObject } from "react-router-dom";
import Home from "presentation/pages/view/home/";
import TransaksiList from "presentation/pages/view/list-transaksi";
import Profile from "presentation/pages/view/profile";
import ChangePhone from "presentation/pages/view/change-phone";
import ForgotPin from "presentation/pages/view/forgot-pin";
import ConfirmIdentity from "presentation/pages/view/confirm-identity";
import ChangeEmail from "presentation/pages/view/change-email";
import ChangePin from "presentation/pages/view/change-pin";
import TrxDetail from "presentation/pages/view/list-transaksi-detail";
import GenerateQR from "presentation/pages/view/generate-qr";
import VerifyEmail from "presentation/pages/view/verify-email";

const MainRoutes: RouteObject[] = [
    {
        path: "/dashboard",
        children: [
            {
                path: "/dashboard/home",
                element: <Home />,
            },
            {
                path: "/dashboard/transaction",
                element: <TransaksiList />,
            },
            {
                path: "/dashboard/profile",
                element: <Profile />,
            },
            {
                index: true,
                element: <Navigate to="/dashboard/home" />,
            },
        ],
    },
    {
        path: "/confirm-identity",
        element: <ConfirmIdentity />,
    },
    {
        path: "/change-phone",
        element: <ChangePhone />,
    },
    {
        path: "/forgot-pin",
        element: <ForgotPin />,
    },
    {
        path: "/change-email",
        element: <ChangeEmail />,
    },
    {
        path: "/verify-email",
        element: <VerifyEmail />,
    },
    {
        path: "/change-pin",
        element: <ChangePin />,
    },
    {
        path: "/transaction-detail",
        element: <TrxDetail />,
    },
    {
        path: "/money-withdrawal",
        element: <GenerateQR />,
    },
];

export default MainRoutes;
