import { get, post } from 'config/infra';
import IAuthRepository from "domain/repository/Transaction";
import { TransactionRequest, TransactionResponse } from 'domain/entity/Auth/model/Transaction'
import argsToParams from 'config/shared/utils/args-to-params';
import { TransactionDetailRequest, TransactionDetailResponse } from 'domain/entity/Auth/model/TransactionDetail';
import { DepositQRRequest, DepositQRResponse } from 'domain/entity/Deposit';

export default class TransactionAPI implements IAuthRepository {
    async listTrxDtl(body: TransactionDetailRequest): Promise<TransactionDetailResponse> {
        return post<TransactionDetailResponse, TransactionDetailRequest>('/deposit/detail', body);
    }
    async listTrx(body: TransactionRequest): Promise<TransactionResponse> {
        const param = argsToParams(body);
        return get<TransactionResponse>(`/deposit/list?${param}`);
    }

    async postDepositQR(body: DepositQRRequest): Promise<DepositQRResponse> {
        return post('/deposit/generateqr', body)
    }
}