import { ReversalQRRequest, ReversalQRResponse, ReversalRequest, ReversalResponse } from "domain/entity/Reversal";
import ReversalRepository from "domain/repository/Reversal";
import { get, post } from "config/infra";
import { CheckUserResponse } from "domain/entity/Deposit";

export default class ReversalApi implements ReversalRepository {
    async postReversal(body: ReversalRequest): Promise<ReversalResponse> {
        return post('/reversal/insert', body)
    }

    async postReversalQR(body: ReversalQRRequest): Promise<ReversalQRResponse> {
        return post('/reversal/generateqr', body)
    }

    async getCheckUser(): Promise<CheckUserResponse> {
        return get('/checkuser')
    }
}