import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import { OTPData } from "config/shared/types";
import { GlobalContext } from "config/context/GlobalContext";
import log from "config/log";
import { useMutation } from "react-query";
import EmailUseCase from "domain/interactor/Email";
import { ChangeEmailRequest } from "domain/entity/Email";
import OTPUseCase from "domain/interactor/OTP";
import { OTPRequest } from "domain/entity/OTP";
import { ALL_DIGIT } from "presentation/validation/constants";
import { OTPPayload } from "../auth-otp";
import useLanguage from "config/hooks/useLanguage";

const initialValues = {
    email: "",
};

const validationSchema = yup.object({
    email: yup.string().email("Format email salah, contoh: rem@remid.com"),
});

type ChangeEmailUseCases = {
    emailUseCase: EmailUseCase;
    otpUseCase: OTPUseCase;
};

const useChangeEmailViewModel = ({
    emailUseCase,
    otpUseCase,
}: ChangeEmailUseCases) => {
    const { t } = useLanguage();

    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const location = useLocation();

    const navigate = useNavigate();

    const [success, setSuccess] = React.useState(false);

    const [showOTP, setShowOTP] = React.useState(false);

    const [messageData, setMessageData] = React.useState({
        header: "",
        subHeader: "",
    });

    const [pageType, setPageType] =
        React.useState<"change" | "verification" | "">("");

    const {
        formState: { errors },
        handleSubmit,
        register,
        watch,
        setValue,
        getValues,
    } = useForm({
        values: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const _email = watch("email");

    const otpDataRef = React.useRef<OTPPayload | null>(null);

    const submitButtonStatus = _email !== "";

    // const otpData: OTPData = {
    //     type: "email",
    //     value: _email,
    // };

    const otpData: OTPPayload = {
        userId: "",
        email: getValues("email") || "",
        phone: "",
        method: "email",
        type: "change-email",
        sendWhere: "email",
    };

    const { mutateAsync: postOTP, isLoading: otpLoading } = useMutation(
        "send-otp",
        (body: OTPRequest) => otpUseCase.postOTP(body)
    );

    const { mutateAsync: changeEmail } = useMutation(
        "/email/change",
        (body: ChangeEmailRequest) => emailUseCase.postChangeEmail(body)
    );

    const onSubmit = async (data: typeof initialValues) => {
        try {
            let email = "";
            let phone = "";
            let userId = "";
            const _username = getValues("email");

            if (ALL_DIGIT.test(_username)) {
                phone = _username;
            } else {
                email = _username;
            }

            postOTP({
                userId,
                email,
                phone,
                method: "email",
                type: "change-email",
            })
                .then(() => {
                    otpDataRef.current = {
                        userId,
                        email,
                        phone,
                        method: "email",
                        type: "change-email",
                        sendWhere: email ? "email" : "phone",
                    };

                    setShowOTP(true);
                })
                .catch((err) => {
                    const { response } = err;

                    if (response && response.data) {
                        const { message, rc } = response.data;

                        dispatch({
                            type: "SHOW_OVERLAY",
                            payload: {
                                header: t("general.general_error"),
                                type: "warning",
                                btnText: "Kembali",
                                subHeader: t("rc." + rc),
                            },
                        });
                    }
                });
        } catch (error) {
            log(error);
        }
    };

    const onVerificationSubmit = () => {
        setSuccess(true);
        if (user !== null) {
            dispatch({
                type: "SAVE_USER",
                payload: {
                    ...user,
                    email: _email,
                    isPhoneVerified: "1",
                },
            });
            window.location.reload();
        }
    };

    const onVerifySubmit = (otp: string) => {
        return emailUseCase.postChangeEmail({
            email: getValues("email"),
            userId: user?.userId|| "",
            otp,
        });
    };

    const handleSuccessVerifyEmail = () => {
        dispatch({
            type: "SAVE_USER",
            payload: { ...user, email: getValues("email") },
        });
        setSuccess(true);
    };

    const handleSuccessRedirect = () => {
        navigate(-1);
    };

    React.useEffect(() => {
        if (!Boolean(location.state)) {
            log("you shall not pass");
            navigate("/");
        } else {
            const { data } = location?.state;
            setMessageData({ ...data });
        }
    }, [location]);

    React.useEffect(() => {
        if (user?.email) {
            setValue("email", user.email);
        }
    }, [user?.email]);

    return {
        errors,
        register,
        submitButtonStatus,
        handleSubmit,
        onSubmit,
        handleSuccessRedirect,
        success,
        showOTP,
        onVerificationSubmit,
        messageData,
        otpData,
        pageType,
        otpDataRef,
        onVerifySubmit,
        handleSuccessVerifyEmail,
        setShowOTP,
    };
};

export type ChangeEmailViewModel = ReturnType<typeof useChangeEmailViewModel>;

export default useChangeEmailViewModel;
