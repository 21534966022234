import { ChangePasswordRequest, ChangePasswordResponse } from "domain/entity/Password";
import PasswordRepository from "domain/repository/Password";


export default class PasswordUseCase {
    constructor(private repo: PasswordRepository) { }

    postChangePassword(body: ChangePasswordRequest): Promise<ChangePasswordResponse> {
        return this.repo.postChangePassword(body)
    }
}