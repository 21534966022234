import React from "react";
import { useTheme, Stack, Box, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

type Field = {
    status: boolean;
    text: string;
};

type PasswordHelperProps = {
    data: Array<Field>;
};

const PasswordHelper: React.FC<PasswordHelperProps> = (props) => {
    const { palette } = useTheme();

    const { data = [] } = props;

    return (
        <Stack sx={{ width: "100%" }} spacing={1}>
            {data.map(({ status, text }, indx) => {
                return (
                    <Box
                        key={`${status}-${text}-${indx}`}
                        sx={{
                            width: "100%",
                            fontSize: 12,
                        }}
                    >
                        <Typography
                            sx={{
                                display: "flex",
                                alignItem: "center",
                                color: palette.text.secondary,
                                gap: ".5em",
                                fontSize: "1em",
                            }}
                        >
                            {status ? (
                                <CheckCircleRoundedIcon
                                    fontSize="inherit"
                                    color="success"
                                />
                            ) : (
                                <CancelRoundedIcon
                                    fontSize="inherit"
                                    color="error"
                                />
                            )}

                            {text}
                        </Typography>
                    </Box>
                );
            })}
        </Stack>
    );
};

export default PasswordHelper;
