const log = (...value: any[]) => {
    if (process.env.NODE_ENV !== "development") {
        return;
    }

    if (typeof value === "object") {
        console.log(JSON.stringify(value))
        return;
    }

    console.log(value)
}

export default log