const dummyCountry = [
    {
        id: 'INDONESIA',
        label: "Indonesia",
    },
    {
        id: 'America',
        label: "America",
    },
    {
        id: 'Japan',
        label: "Japan",
    },
    {
        id: 'England',
        label: "England",
    },
];

const dummyProvince = [
    { id: '1', label: "Jakarta", },
    { id: '2', label: "Jawa Tengah" },
];

const dummyDistrict = {
    '1': [
        {
            id: '11',
            label: "Jakarta Selatan",
        },
        { id: '12', label: "Jakarta Pusat" },
    ],
    '2': [
        {
            id: '21',
            label: "Boyolali",
        },
        { id: '22', label: "Surakarta" },
    ],
};

const dummySubDistrict = {
    '11': [
        { id: '30', label: "Mampang Prapatan" },
        { id: '31', label: "Tebet" },
    ],
    '12': [
        { id: '42', label: "Gambir" },
        { id: '53', label: "Menteng" },
    ],
    '21': [
        { id: '54', label: "Ampel" },
        { id: '65', label: "Andong" },
    ],
    '22': [
        { id: '76', label: "Jebres" },
        { id: '87', label: "Mojosongo" },
    ],
};

const dummyVillage = {
    '30': [
        { id: '131', label: "Kuningan Barat" },
        { id: '132', label: "Tegal Parang" },
    ],
    '31': [
        { id: '233', label: "Kebon Baru" },
        { id: '234', label: "Bukit Duri" },
    ],
    '42': [
        { id: '335', label: "Kebon Kelapa" },
        { id: '336', label: "Petojo Selatan" },
    ],
    '53': [
        { id: '437', label: "Kebon Kelapa" },
        { id: '438', label: "Cikini" },
    ],
    '54': [
        { id: '539', label: "Tanduk" },
        { id: '540', label: "Sidomulyo" },
    ],
    '65': [
        { id: '641', label: "Kacangan" },
        { id: '642', label: "Pakang" },
    ],
    '76': [
        { id: '743', label: "Jagalan" },
        { id: '744', label: "Sewu" },
    ],
    '87': [
        { id: '845', label: "Jatirejo" },
        { id: '', label: "Ngemplak" },
    ],
};

const dummyJob = [
    { id: "1", label: "Pelajar" },
    { id: "2", label: "Pegawai" },
    { id: "3", label: "Ibu Rumah Tangga" },
]

export { dummyCountry, dummyDistrict, dummyProvince, dummySubDistrict, dummyVillage, dummyJob }