import React, { useEffect, useState } from 'react';
import {
    Box,
} from "@mui/material";
import splashImage from './iphoneplus_splash.png';
import App from "./App";

// const SplashScreen: React.FC = () => {
//     const [hideSplash, setHideSplash] = useState(false);

//     useEffect(() => {
//         const timeout = setTimeout(() => {
//             setHideSplash(false);
//         }, 3000);
//         return () => clearTimeout(timeout);
//     }, []);

//     return (
//         // <Box className={`splash ${hideSplash ? 'hidden' : ''}`}>
//         //     <Box className="splash-inner">
//         //         <img src={splashImage} alt="Splash Screen" />
//         //     </Box>
//         // </Box>
//         <Box>
//             {hideSplash ? (
//                 <Box className="splash-screen">
//                     <img src={splashImage} alt="Splash Screen" />
//                 </Box>
//             ) : (
//                 <App />
//             )}
//         </Box>
//     );
// };

const SplashScreen = () => {
    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#ffffff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {/* <img src={splashImage} alt="Splash Screen" /> */}
            <Box
                component="img"
                sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                }}
                alt="Splash Screen"
                src={splashImage}
            />
        </Box >
    );
};

export default SplashScreen;