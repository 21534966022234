import State from "./State"
import Action from "./Action"
import { setUser } from "config/storage"

const reducer = (state: State, action: Action): State => {
    const { type } = action

    switch (type) {
        case "SET_LANGUAGE":
            return { ...state, language: action.payload }
        case "CLEAR_USER":
            return { ...state, user: null }
        case "SET_USER":
            return { ...state, user: action.payload }
        case "SET_BOTTOM_NAVIGATION":
            return { ...state, bottomNavigation: action.payload }
        case "SHOW_OVERLAY":
            return { ...state, overlay: { ...action.payload, open: true } }
        case "CLOSE_OVERLAY":
            return { ...state, overlay: { open: false } }
        case "SAVE_USER":
            setUser(JSON.stringify(action.payload))
            return { ...state, user: action.payload }
        default:
            return state
    }
}

export default reducer