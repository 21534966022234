import {
    Box
} from '@mui/material';
import TransaksiHeader from "presentation/pages/view/list-transaksi/components/header";
import List from "presentation/pages/view/list-transaksi/components/List";
import LayoutHome from "config/components/layout-home";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { ListTransaksiViewModel } from "presentation/pages/viewmodel/list-transaksi";
import PINMobileLayout from "config/components/layout-pin/mobile";

type ListTransaksiPageProps = {
    viewModel: ListTransaksiViewModel;
};

const ListTransaksiPage: React.FC<ListTransaksiPageProps> = ({ viewModel }) => {
    console.log('status', viewModel.listStatus);

    return (
        viewModel.showPIN ? <PINMobileLayout
            primary="white"
            onSubmit={viewModel.onPINValidation}
            onBack={viewModel.onBack}
            loading={viewModel.pinloading}
        /> : <LayoutHome>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateRows: "minmax(150px, 1fr) 3fr",
                    minHeight: "100%",
                    gridAutoFlow: "row",
                }}
            >
                <TransaksiHeader />
                <Box sx={{ background: "white", pb: "3rem" }}>
                    <Box sx={{ margin: '10px 15px', display: 'flex', justifyContent: 'space-between' }}>
                        <FormControl fullWidth sx={{ mb: '1rem', mr: '0.5rem' }}>
                            <InputLabel id="demo-simple-select-label">Produk</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Produk"
                                value={viewModel.productId ? viewModel.productId : ""}
                                onChange={viewModel.handleChangeProduct}
                            >
                                <MenuItem value={""}>All</MenuItem>
                                {Array.isArray(viewModel.produkList?.data)
                                    ? viewModel.produkList?.data.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: '1rem' }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Status"
                                value={viewModel.status ? viewModel.status : ""}
                                onChange={viewModel.handleChangeStatus}
                                disabled={viewModel.productId === ""}
                            >
                                <MenuItem value={""}>All</MenuItem>
                                {Array.isArray(viewModel.listStatus?.data)
                                    ? viewModel.listStatus?.data.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.name}
                                                value={item.name}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })
                                    : []}
                                {/* <MenuItem value={"Menunggu disetor"}>Menunggu Disetor</MenuItem>
                                <MenuItem value={"Sudah disetor"}>Sudah Disetor</MenuItem>
                                <MenuItem value={"Sudah diambil"}>Sudah Diambil</MenuItem>
                                <MenuItem value={"Dibatalkan"}>Dibatalkan</MenuItem>
                                <MenuItem value={"Menunggu Scan"}>Menunggu Scan</MenuItem>
                                <MenuItem value={"Berhasil Scan"}>Berhasil Scan</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Box>

                    <List onClick={viewModel.handlePIN} items={viewModel.trxData?.data || []} />
                </Box>
            </Box>
        </LayoutHome>
    );
};

export default ListTransaksiPage;
