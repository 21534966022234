import ForgotPasswordPage from "./ForgotPasswordPage";
import useForgotPasswordViewModel from "presentation/pages/viewmodel/auth-forgot-password";
import AuthApi from "data/Auth";
import ForgotPasswordUseCase from "domain/interactor/Auth/ForgotPasswordUseCase";
import OTPUseCase from "domain/interactor/OTP";
import OTPApi from "data/OTP";

const useCase = new ForgotPasswordUseCase(new AuthApi());
const otpUseCase = new OTPUseCase(new OTPApi());

export default function ForgotPassword() {
    const viewModel = useForgotPasswordViewModel({ otpUseCase, useCase });

    return <ForgotPasswordPage viewModel={viewModel} />;
}
