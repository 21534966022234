import { RouteObject } from "react-router-dom";
import SignInPage from "presentation/pages/view/auth-signin";
import SignInSSOPage from "presentation/pages/view/auth-signin-sso";
import RegisterPage from "presentation/pages/view/auth-signup";
import ForgotPasswordPage from "presentation/pages/view/auth-fogot-password";

const AuthRoutes: RouteObject[] = [
    {
        path: "/login",
        element: <SignInPage />,
    },
    {
        path: "/register",
        element: <RegisterPage />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
    },
    {
        path: "/ssologin",
        element: <SignInSSOPage />,
    },
];

export default AuthRoutes;
