import HomeContent from "./components/HomeContent";
import HomeLayout from "config/components/layout-home";

const HomePage = () => {
    return (
        <HomeLayout>
            <HomeContent />
        </HomeLayout>
    );
};

export default HomePage;
