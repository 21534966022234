import useConfirmIdentityViewModel from "presentation/pages/viewmodel/confirm-identity";
import ConfirmIdentityPage from "./ConfirmIdentityPage";
import GlobalResourceUseCase from "domain/interactor/GlobalResource";
import GlobalResourceApi from "data/GlobalResource";
import AktivasiKTPUseCase from "domain/interactor/AktivasiKTP";
import AktivasiKTPApi from "data/AktivasiKTP";

const globalResourceUseCase = new GlobalResourceUseCase(
    new GlobalResourceApi()
);
const aktivasiKTPUseCase = new AktivasiKTPUseCase(new AktivasiKTPApi());

export default function () {
    const viewModel = useConfirmIdentityViewModel({
        globalResourceUseCase,
        aktivasiKTPUseCase,
    });

    return <ConfirmIdentityPage viewModel={viewModel} />;
}
