import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalContext } from "config/context/GlobalContext";
import { useForm } from "react-hook-form";
import { userIdentityValidation } from "presentation/validation/userIden";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useMutation } from "react-query";
import { setUser } from "config/storage";
import { AxiosError } from "axios";
import log from "config/log";
import SignInUseCase from "domain/interactor/Auth/SignInUseCase";
import { LoginRequest } from "domain/entity/Auth/model/Login";
import useLanguage from "config/hooks/useLanguage";
import Reaptcha from "reaptcha";

const initialValues: LoginRequest = {
    username: "",
    password: "",
    token: "",
};

const validationSchema = yup.object({
    username: userIdentityValidation,
    password: yup.string().required("validation.password_required"),
});

const useSignInViewModel = (useCase: SignInUseCase) => {
    const { t } = useLanguage();

    const location = useLocation();

    const [, dispatch] = React.useContext(GlobalContext);

    const navigate = useNavigate();

    const [success, setSuccess] = React.useState(false);

    const [showPassword, setShowPassword] = React.useState(false);

    const [captchaStatus, setCaptchaStatus] = React.useState(false);

    const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);

    const reaptchaRef = React.useRef<Reaptcha>(null);

    const resetReaptcha = () => {
        
        if (reaptchaRef.current) {
            reaptchaRef.current.reset();
        }
    };

    const { mutateAsync, isLoading } = useMutation((data: LoginRequest) =>
        useCase.execute(data)
    );

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
    } = useForm<LoginRequest>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const submitButtonStatus = captchaStatus && isValid;

    const onSubmit = async (data: LoginRequest) => {
        try {
            data.token = captchaToken || '';

            log(data);

            const result = await mutateAsync(data);

            const { data: logindata } = result;

            setUser(JSON.stringify(logindata));

            // redirect to home
            setSuccess(true);
        } catch (e: unknown) {
            log(e);

            const { response = null } = e as AxiosError;

            if (!response) {
                dispatch({
                    type: "SHOW_OVERLAY",
                    payload: {
                        header: t("general.general_error"),
                        subHeader: "Terjadi Kesalahan",
                        type: "warning",
                    },
                });
            } else {
                const { message = "", rc = "" } = response.data as any;

                if (rc === "0007") {
                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: "Informasi Akun",
                            // subHeader: message,
                            subHeader: t("rc." + rc),
                            type: "warning",
                        },
                    });
                } else if (rc === "0058") {
                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: "Informasi Akun",
                            // subHeader: message,
                            subHeader: "Mohon maaf akun Anda belum dapat diakses, silahkan menghubungi customer service kami 021-50127667.",
                            type: "warning",
                        },
                    });
                } else {
                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.general_error"),
                            // subHeader: message,
                            subHeader: t("rc." + rc),
                            type: "warning",
                        },
                    });
                }

                // Reset Reaptcha on login failure
                resetReaptcha();
            }
        }
    };

    const onCaptchaChange = (token: string | null) => {
        setCaptchaToken(token);
        setCaptchaStatus(token !== null);
    };

    const handleShowPassword = () => {
        setShowPassword((pass) => !pass);
    };

    const handleSuccessRedirect = () => {
        window.location.reload();
    };

    const handleNavigateToRegister = () => {
        navigate("/register");
    };

    const handleTOSRedirect = () => {
        navigate("/info");
    };

    const handleNavigateToForgotPassword = () => {
        // navigate("/forgot-password");
        navigate("/send-otp-to");
    };

    React.useEffect(() => {
        const data = location.state?.data;
        if (data) {
            setValue("username", data.phoneNumber);
            setValue("password", data.password);
        }
    }, [location]);

    return {
        isLoading,
        onSubmit,
        register,
        handleSubmit,
        errors,
        success,
        showPassword,
        handleShowPassword,
        handleSuccessRedirect,
        handleNavigateToRegister,
        submitButtonStatus,
        handleNavigateToForgotPassword,
        onCaptchaChange,
        handleTOSRedirect,
        reaptchaRef,
        resetReaptcha,
    };
};

export type SignInViewModelType = ReturnType<typeof useSignInViewModel>;

export default useSignInViewModel;
