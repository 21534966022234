import React from "react";
import { Box, Stack, SxProps, Typography, useTheme } from "@mui/material";
import { ReactComponent as RemidLogo } from "assets/svg/RemidDesktopLogo.svg";
import ButtonRemid from "core/components/remid-button";
import AuthLayoutInterface from "config/components/layout-auth/AuthLayoutInterface";

type AuthDesktopLayoutProps = {
    authType?: "login" | "register";
} & AuthLayoutInterface;

const AuthDesktopLayout: React.FC<AuthDesktopLayoutProps> = (props) => {
    const { palette } = useTheme();

    const style: SxProps = { height: "100vh" };

    const {
        childForm,
        header,
        authType,
        onTextClickNavigate,
        bottomTextNews,
        navigateTextNews,
        onTextClickNavigateNews,
    } = props;

    return (
        <Box sx={style}>
            <Box sx={{ display: "flex", height: "100%" }}>
                <Box
                    sx={{
                        width: "37.5%",
                        minWidth: 250,
                        maxWidth: 540,
                        backgroundColor: palette.primary.main,
                        backgroundImage:
                            authType === "login"
                                ? "url('/svg/AuthDesktopLogin.svg')"
                                : "url('/svg/AuthDesktopRegister.svg')",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box
                        sx={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                        }}
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                right: "-20%",
                            }}
                        >
                            {authType === "login" ? (
                                <Box
                                    sx={{
                                        position: "relative",
                                        borderRadius: 50,
                                        background: "white",
                                        width: 250,
                                        heigth: 96,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: "fit-content",
                                            fontSize: "1.5rem",
                                            p: "2rem",
                                            fontWeight: 600,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Login
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: "1.5rem",
                                        p: "2rem",
                                        color: "#fff",
                                        textTransform: "uppercase",
                                        cursor: "pointer",
                                        width: "fit-content",
                                    }}
                                    onClick={onTextClickNavigate}
                                >
                                    Login
                                </Typography>
                            )}

                            {authType === "register" ? (
                                <Box
                                    sx={{
                                        borderRadius: 50,
                                        background: "white",
                                        width: 250,
                                        heigth: 96,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: "fit-content",
                                            fontSize: "1.5rem",
                                            p: "2rem",
                                            fontWeight: 600,
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Daftar
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: "1.5rem",
                                        p: "2rem 1rem",
                                        color: "#fff",
                                        textTransform: "uppercase",
                                        cursor: "pointer",
                                        width: "fit-content",
                                    }}
                                    onClick={onTextClickNavigate}
                                >
                                    Daftar
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "center",
                        overflow: "auto",
                    }}
                >
                    <Stack
                        sx={{
                            width: "100%",
                            height: "100%",
                            maxWidth: 600,
                            pb: "3em",
                        }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box
                            sx={{
                                width: "50%",
                                maxWidth: 300,
                                mb: "3rem",
                            }}
                        >
                            <RemidLogo />

                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontWeight: 600,
                                    fontSize: "2rem",
                                    color: palette.primary.main,
                                    textTransform: "uppercase",
                                }}
                            >
                                {header}
                            </Typography>
                        </Box>
                        {childForm}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography sx={{ fontSize: ".75rem" }}>
                                {bottomTextNews}
                            </Typography>
                            <ButtonRemid variant="text" onClick={onTextClickNavigateNews}>
                                {navigateTextNews}
                            </ButtonRemid>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};

export default AuthDesktopLayout;
