import React from "react";
import { Stack, Typography } from "@mui/material";
import { ChangePasswordViewModel } from "presentation/pages/viewmodel/change-password";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import PasswordHelper from "config/components/password-helper";
import ButtonRemid from "core/components/remid-button/Button";
import useLanguage from "config/hooks/useLanguage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type FormChangePasswordProps = {
    viewModel: ChangePasswordViewModel;
};

const FormChangePassword: React.FC<FormChangePasswordProps> = ({
    viewModel,
}) => {
    const { t } = useLanguage();

    const {
        register,
        handleSubmit,
        onSubmit,
        errors,
        showPassword,
        showConfirmPassword,
        setShowPassword,
        setShowConfirmPassword,
        showPasswordHelper,
        submitButtonStatus,
        passwordHelperData,
        usingOldPassword,
        showOldPassword,
        setShowOldPassword,
    } = viewModel;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: "1.5rem",
                        width: "100%",
                    }}
                >
                    {t("auth.helper_text_password3")}
                </Typography>
                {usingOldPassword && (
                    <TextFieldRemid
                        {...register("oldPassword")}
                        fullWidth
                        label={t("auth.old_password")}
                        type={showOldPassword ? "text" : "password"}
                        password
                        shrink
                        iconButton={
                            showOldPassword ? (
                                <VisibilityOffIcon />
                            ) : (
                                <VisibilityIcon />
                            )
                        }
                        onIconButtonClick={() =>
                            setShowOldPassword((state) => !state)
                        }
                        error={Boolean(errors.oldPassword)}
                    />
                )}
                <TextFieldRemid
                    {...register("password")}
                    fullWidth
                    label={t("auth.password")}
                    type={showPassword ? "text" : "password"}
                    password
                    shrink
                    iconButton={
                        showPassword ? (
                            <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                        )
                    }
                    helperText={
                        !showPasswordHelper && t("auth.helper_text_password")
                    }
                    onIconButtonClick={() => setShowPassword((state) => !state)}
                    error={Boolean(errors.password)}
                />
                <TextFieldRemid
                    {...register("confirmPassword")}
                    fullWidth
                    label={t("auth.confirm_password")}
                    type={showConfirmPassword ? "text" : "password"}
                    password
                    shrink
                    iconButton={
                        showConfirmPassword ? (
                            <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                        )
                    }
                    onIconButtonClick={() =>
                        setShowConfirmPassword((state) => !state)
                    }
                    error={Boolean(errors.confirmPassword)}
                />

                {showPasswordHelper && (
                    <PasswordHelper data={passwordHelperData} />
                )}

                <ButtonRemid
                    type="submit"
                    loading={viewModel.forgotPasswordLoading}
                    disabled={!submitButtonStatus}
                >
                    {t("general.save")}
                </ButtonRemid>
            </Stack>
        </form>
    );
};

export default FormChangePassword;
