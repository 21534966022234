import { Box, Card, CircularProgress, Stack } from "@mui/material";
import FieldDetailTransaksi from "config/components/field-detail-transaksi";
import ButtonRemid from "core/components/remid-button";
import TextFieldRemid from "core/components/remid-textfield";
import { CancelUangViewModel } from "presentation/pages/viewmodel/cancel-uang";
import React from "react";
import currencyFormatter from "config/shared/utils/currency-formatter";
import { phoneObfuscate } from "config/shared/utils/obfuscate";
import useLanguage from "config/hooks/useLanguage";

type FormCancelUangProps = {
    viewModel: CancelUangViewModel;
};

const FormCancelUang: React.FC<FormCancelUangProps> = ({ viewModel }) => {
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
        timeZone: "Asia/Jakarta",
    };

    const {
        isReversalInsertLoading,
        reversalInsertData,
        isReversalInsertSuccess,
        errors,
        handleSubmit,
        onSubmit,
        register,
        submitButtonStatus,
    } = viewModel;

    const { t } = useLanguage();

    const formatter = new Intl.DateTimeFormat("id-ID", options);

    const formattedDate = reversalInsertData?.data?.DateTime
        ? formatter.format(new Date(reversalInsertData?.data?.DateTime))
        : "";

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <TextFieldRemid
                    {...register("mtcn")}
                    fullWidth
                    shrink
                    label={t("transaction.mtcn_code")}
                    error={Boolean(errors.mtcn)}
                    helperText={t(errors.mtcn?.message)}
                    inputProps={{
                        inputMode: "numeric",
                    }}
                />
                <TextFieldRemid
                    {...register("kodeBatal")}
                    fullWidth
                    shrink
                    label={t("transaction.cancel_mtcn_code")}
                    error={Boolean(errors.kodeBatal)}
                    helperText={t(errors.kodeBatal?.message)}
                    inputProps={{
                        inputMode: "numeric",
                    }}
                />

                {isReversalInsertLoading && <CircularProgress />}

                {isReversalInsertSuccess && (
                    <Card sx={{ minWidth: "100%" }}>
                        <Box sx={{ p: ".5rem" }}>
                            <FieldDetailTransaksi
                                header="Status"
                                detailItems={[
                                    {
                                        label: "Order ID",
                                        value: reversalInsertData?.data
                                            ?.DepositId,
                                        color: "secondary",
                                        bold: true,
                                    },
                                    {
                                        label: t("transaction.trans_date"),
                                        value: formattedDate,
                                    },
                                    {
                                        label: t("transaction.money_amount"),
                                        value: currencyFormatter(
                                            reversalInsertData?.data
                                                ?.ReversalNominal || ""
                                        ),
                                        color: "primary",
                                    },
                                ]}
                            />
                        </Box>
                        <Box sx={{ p: ".5rem" }}>
                            <FieldDetailTransaksi
                                header="Detail"
                                detailItems={[
                                    {
                                        label: t("transaction.recipient_name"),
                                        value: reversalInsertData?.data
                                            ?.ReversalNama,
                                    },
                                    {
                                        label: t("transaction.recipient_no2"),
                                        value: phoneObfuscate(
                                            reversalInsertData?.data
                                                ?.ReversalNoHp || ""
                                        ),
                                    },
                                    {
                                        label: t("transaction.recipient_no3"),
                                        value:
                                            reversalInsertData?.data
                                                ?.ReversalId || "",
                                    },
                                ]}
                            />
                        </Box>
                        <Box sx={{ p: ".5rem" }}>
                            <FieldDetailTransaksi
                                header={t("transaction.money_cancel_locations")}
                                detailItems={[
                                    {
                                        label: t("confirm_identity.country"),
                                        value: reversalInsertData?.data
                                            ?.ReversalNamaNegara,
                                    },
                                    {
                                        label: t("transaction.store_name"),
                                        value: reversalInsertData?.data
                                            ?.ReversalNamaToko,
                                    },
                                ]}
                            />
                        </Box>
                    </Card>
                )}

                {isReversalInsertSuccess ? (
                    <ButtonRemid onClick={viewModel.onGenerateQRCode}>
                        Generate QR Code
                    </ButtonRemid>
                ) : (
                    <ButtonRemid
                        disabled={submitButtonStatus || isReversalInsertLoading}
                        type="submit"
                    >
                        {t("general.send")}
                    </ButtonRemid>
                )}
            </Stack>
        </form>
    );
};

export default FormCancelUang;
