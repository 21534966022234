import Axios, { AxiosRequestConfig } from 'axios'
import axiosHeaders from './headers'
import { clearLocalStorage } from 'config/storage'

let BASE_URL = process.env.REACT_APP_BASE_URL_API

const Http = Axios.create({
    baseURL: BASE_URL,
    headers: { ...axiosHeaders }
})

const get = async <Res,>(url: string, config?: AxiosRequestConfig<any>): Promise<Res> => {
    const res = await Http.get<Res>(url, { ...config })
    return res.data
}

const post = async <Res, Bod,>(url: string, body: Bod, config?: AxiosRequestConfig<any>): Promise<Res> => {
    const res = await Http.post<Res>(url, body, {
        ...config
    })
    return res.data
}

const put = async <Res, Bod>(url: string, body: Bod, config?: AxiosRequestConfig<any>): Promise<Res> => {
    const res = await Http.put<Res>(url, body, { ...config })
    return res.data
}

const del = async <Res,>(url: string, config?: AxiosRequestConfig<any>): Promise<Res> => {
    const res = await Http.delete<Res>(url, { ...config })
    return res.data
}

export { Http, get, post, put, del } 