import { PIN_LENGTH } from "presentation/validation/constants";

type PinHelperData = {
    status: boolean;
    text: string;
}

export const isPinEquals = (p1: string, p2: string) => {
    return p1 !== "" && p2 !== "" && p1 === p2;
};

export const isPinLengthValid = (p: string) => {
    return p.length >= PIN_LENGTH;
};

export const pinHelperData = [
    {
        status: false,
        text: "Minimal 6 karakter",
    },
    {
        status: false,
        text: "PIN baru dan konfirmasi PIN baru tidak sesuai",
    },
];

export const pinHelperDataValidate = (data: Array<PinHelperData>) => {
    for (const item of data) {
        if (!item.status)
            return false
    }
    return true;
}