import React from "react";
import LayoutAppBar from "config/components/layout-app-bar";
import DetailMoneyComponent from "config/components/detail-money";
import { DetailTransaksi } from "config/components/field-detail-transaksi";
import QRCodeComponent from "config/components/qr-code";
import PINMobileLayout from "config/components/layout-pin/mobile";
import TransactionSuccess from "config/components/transaction-success";
import { CancelUangViewModel } from "presentation/pages/viewmodel/cancel-uang";
import FormCancelUang from "presentation/pages/view/cancel-uang/components/form-cancel-uang";
import useLanguage from "config/hooks/useLanguage";

type CancelUangPageProps = {
    viewModel: CancelUangViewModel;
};

const dummy = {
    headerLabel: "Kode Pembatalan Uang (MTCN)",
    headerValue: "J2HD882OE",
    items: [
        {
            header: "Status",
            detailItems: [
                {
                    label: "Transaction ID",
                    value: "78147SU364Q8GS",
                    color: "secondary",
                    bold: true,
                },
                {
                    label: "Tanggal Pembatalan",
                    value: "07 Sep 2022 10.50 WIB",
                },
                {
                    label: "Jumlah Uang",
                    value: "Rp. 1.000.000",
                    color: "primary",
                },
            ],
        },
        {
            header: "Detail",
            detailItems: [
                {
                    label: "Nama Penerima",
                    value: "John Doe",
                    bold: true,
                },
                {
                    label: "No Id/No Hp Penerima",
                    value: "1271188888000002",
                    bold: true,
                },
                {
                    label: "Kode MTCN",
                    value: "7VQBU4J3I",
                    bold: true,
                },
                {
                    label: "Kode Pembatalan",
                    value: "J2HD882OE",
                    bold: true,
                },
            ],
        },
        {
            header: "Lokasi Pembatalan Uang",
            detailItems: [
                {
                    label: "Negara",
                    value: "Indonesia",
                    bold: true,
                },
                {
                    label: "Nama Toko",
                    value: "Alfamart",
                    bold: true,
                },
            ],
        },
    ] as Array<DetailTransaksi>,
};

const CancelUangPage: React.FC<CancelUangPageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    const renderChildren = () => {
        if (viewModel.isReversalQRSuccess) {
            return (
                <QRCodeComponent
                    onBack={viewModel.onClose}
                    value={viewModel.reversalQRData?.data || ""}
                />
            );
        } else {
            return <FormCancelUang {...props} />;
        }
    };

    const renderContent = () => {
        if (viewModel.showSuccess) {
            return <TransactionSuccess {...dummy} />;
        } else if (viewModel.showPINValidation) {
            return (
                <PINMobileLayout
                    primary="white"
                    onSubmit={viewModel.onPINValidation}
                    onBack={viewModel.onBack}
                    loading={viewModel.isReversalQRLoading}
                />
            );
        } else {
            return (
                <LayoutAppBar
                    title={t("operations.cancel_money")}
                    onBack={viewModel.showDetail ? viewModel.onBack : undefined}
                >
                    {renderChildren()}
                </LayoutAppBar>
            );
        }
    };

    // viewModel.isSuccess  && <TransactionSucces/>
    // viewModel.isError && <ErrorMsg/>
    // viewModel.showPINValidation && <PINMobileLayout/>
    // viewModel.

    return renderContent();
};

export default CancelUangPage;
