import { useMutation } from "react-query";
import HomeUseCase from "domain/interactor/Home";
import React from "react";
import log from "config/log";
import { GlobalContext } from "config/context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { CheckResponse } from "domain/entity/Home";

type UseCheck = {
    isAccountVerified: string;
    isEmailVerified: string;
    isPinVerified: string;
    levelStatus: string;
    note: string;
};

const useCheckVerif = (homeUseCase: HomeUseCase) => {
    const [{ }, dispatch] = React.useContext(GlobalContext);

    const [dataVerif, setDataVerif] = React.useState<UseCheck | null>(null);

    const navigate = useNavigate();

    const { mutateAsync, isLoading: pinloading } = useMutation(() =>
        homeUseCase.getCheckVerified()
    );

    React.useEffect(() => {
        const result = mutateAsync();
        result.then((value: CheckResponse) => {
            checkVerifiedId(value.data)
            checkRejectAcc(value.data)
            setDataVerif(value.data);
        })
    }, []);

    const checkRejectAcc = (value: UseCheck) => {
        if (value.levelStatus == 'Reject') {
            dispatch({
                type: "SHOW_OVERLAY",
                payload: {
                    header: "Verifikasi Akun",
                    subHeader:
                        "Anda harus melakukan verifikasi ulang atau silahkan hubungi CS kami.",
                    btnText: "Verifikasi Ulang",
                    notes: value.note,
                    onClick: () => {
                        navigate("/confirm-identity", {
                            state: { levelStatus: "Reject" },
                        });
                        dispatch({ type: "CLOSE_OVERLAY" });
                    },
                    negativeBtnText: "Tutup",
                    onNegativeClick: () => {
                        dispatch({ type: "CLOSE_OVERLAY" });
                    },
                },
            });
        }
    };

    const checkPIN = (value: UseCheck) => {
        if (!Number(value.isPinVerified)) {
            dispatch({
                type: "SHOW_OVERLAY",
                payload: {
                    header: "Atur PIN Transaksi",
                    subHeader:
                        "Atur PIN transaksi sekarang juga untuk keamanan saat bertransaksi.",
                    btnText: "Atur",
                    onClick: () => {
                        navigate("/change-pin", {
                            state: { parent: "/dashboard/home" },
                        });
                        dispatch({ type: "CLOSE_OVERLAY" });
                    },
                    // untuk mempermudah pengecekan
                    // negativeBtnText: "Tutup",
                    // onNegativeClick: () => {
                    //     dispatch({ type: "CLOSE_OVERLAY" });
                    // },
                },
            });
        }
    };

    const checkEmail = (value: UseCheck) => {
        if (!Number(value.isEmailVerified)) {
            dispatch({
                type: "SHOW_OVERLAY",
                payload: {
                    header: "Verifikasi Email",
                    subHeader:
                        "Verifikasi email untuk peningkatan keamanan akunmu.",
                    btnText: "Verifikasi",
                    onClick: () => {
                        navigate("/verify-email", {
                            state: {
                                parent: "/dashboard/home",
                                data: {
                                    header: "Berhasil dirubah!",
                                    subHeader:
                                        "Selamat email Anda berhasil diubah",
                                },
                            },
                        });
                        dispatch({ type: "CLOSE_OVERLAY" });
                    },
                    negativeBtnText: "Nanti Saja",
                    onNegativeClick: () => {
                        if (!Number(value.isPinVerified)) {
                            log("checkemail: pin not setup");
                            checkPIN(value);
                        } else {
                            log("checkEmail: close overlay ");
                            dispatch({ type: "CLOSE_OVERLAY" });
                        }
                    },
                },
            });
        }
    };

    const checkVerifiedId = (value: UseCheck) => {
        if (!Number(value.isAccountVerified)) {
            dispatch({
                type: "SHOW_OVERLAY",
                payload: {
                    header: "Aktivasi KTP",
                    subHeader:
                        "Aktivasi sekarang juga untuk menggunakan semua fitur Remid secara maksimal.",
                    btnText: "Aktivasi",
                    onClick: () => {
                        navigate("/confirm-identity");
                        dispatch({ type: "CLOSE_OVERLAY" });
                    },
                    negativeBtnText: "Nanti Saja",
                    onNegativeClick: () => {
                        if (!Number(value.isEmailVerified)) {
                            checkEmail(value);
                        } else if (!Number(value.isPinVerified)) {
                            checkPIN(value);
                        } else {
                            dispatch({ type: "CLOSE_OVERLAY" });
                        }
                    },
                },
            });
        } else if (!Number(value.isEmailVerified)) {
            checkEmail(value);
        } else if (!Number(value.isPinVerified)) {
            checkPIN(value);
        }
    };

    return { dataVerif }
};

export default useCheckVerif;