import React from "react";
import LayoutAppBar from "config/components/layout-app-bar";
import { ForgotPasswordViewModel } from "presentation/pages/viewmodel/auth-forgot-password";
import FormForgotPasswordUsername from "./components/form-username";
import OTPMobileLayout from "config/components/layout-otp/Mobile";
import useLanguage from "config/hooks/useLanguage";

type ForgotPasswordPageProps = {
    viewModel: ForgotPasswordViewModel;
};

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = (props) => {
    const { viewModel } = props;

    const { showOTP, formSubmitted } = viewModel;

    const { t } = useLanguage();

    return showOTP ? (
        <OTPMobileLayout
            primary="white"
            onBack={viewModel.onVerificationBack}
            otpRequest={viewModel.otpDataRef!.current}
            postOTP={viewModel.onPostOTP}
            onSuccess={viewModel.onVerificationSuccess}
            storageKey="forgot-password"
        />
    ) : (
        <LayoutAppBar
            title={t("auth.forgot_password")}
        >
            <FormForgotPasswordUsername {...props} />
        </LayoutAppBar>
    );
};

export default ForgotPasswordPage;
