import React from "react";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import ButtonRemid from "core/components/remid-button/Button";
import { Stack } from "@mui/material";
import { ChangePhoneViewModel } from "presentation/pages/viewmodel/change-phone";
import useLanguage from "config/hooks/useLanguage";

type FormChangePhoneProps = {
    viewModel: ChangePhoneViewModel;
};

const FormChangePhone: React.FC<FormChangePhoneProps> = ({ viewModel }) => {
    const { handleSubmit, onSubmit, errors, handlePhoneChange, phoneRegister, submitButtonStatus } =
        viewModel;

    const { t } = useLanguage();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <TextFieldRemid
                    {...phoneRegister}
                    onChange={handlePhoneChange}
                    fullWidth
                    shrink
                    label={t("general.form_chngphone1")}
                    inputProps={{
                        maxLength: 17,
                        inputMode: "numeric",
                    }}
                    error={Boolean(errors.phoneNumber)}
                    helperText={t(errors.phoneNumber?.message)}
                />
                <ButtonRemid type="submit" disabled={!submitButtonStatus}>{t("general.btn1")}</ButtonRemid>
            </Stack>
        </form>
    );
};

export default FormChangePhone;
