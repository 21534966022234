import React from "react";
import { useTheme, Button, ButtonProps, SxProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

type ButtonRemidProps = { loading?: boolean } & ButtonProps;

const style: SxProps = {
    background: "linear-gradient(270deg, #D63839 0%, #F1717C 100%)",
    borderRadius: 21,
    boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    color: "#fff",
    "&.MuiButtonBase-root.Mui-disabled": {
        background: "rgba(0, 0, 0, 0.12) !important",
    },
    px: "3rem",
    minWidth: {
        xs: 180,
        sm: 250,
    },
    width: "fit-content",
};

const ButtonRemid = React.forwardRef<HTMLButtonElement, ButtonRemidProps>(
    (props, ref) => {
        const { palette } = useTheme();

        const {
            disabled,
            loading = false,
            children,
            variant = "contained",
            sx,
            ...rest
        } = props;

        let variantStyle: SxProps = style;

        if (variant === "text") {
            variantStyle = {
                fontSize: ".75rem",
                color: palette.secondary.main,
                fontWeight: 700,
                textTransform: "none",
            };
        }

        if (variant === "outlined") {
            variantStyle = {
                ...style,
                background: "transparent",
                boxShadow: "none",
                color: palette.primary.main,
            };
        }

        return (
            <Button
                ref={ref}
                sx={{ ...variantStyle, ...sx }}
                variant={variant}
                disabled={disabled || loading}
                {...rest}
            >
                {loading ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    children
                )}
            </Button>
        );
    }
);

export default ButtonRemid;
