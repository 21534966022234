import { createTheme } from "@mui/material/styles";

export const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#D63839",
            light: "#DE5F60",
            dark: "#952727",
        },
        secondary: {
            main: "#EBA440",
            light: "#EFB666",
            dark: "#A4722C",
        },
        text: {
            primary: "#000000",
            secondary: "rgba(0, 0, 0, 0.6)",
            disabled: "rgba(0, 0, 0, 0.38)",
        },
        error: {
            main: "#D32F2F",
        },
    },
});
