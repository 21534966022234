import { get, post } from "config/infra";
import argsToParams from "config/shared/utils/args-to-params";
import { CheckUserResponse, DepositFormRequest, DepositFormResponse, DepositQRRequest, DepositQRResponse } from "domain/entity/Deposit";
import DepositRepository from "domain/repository/Deposit";
export default class DepositApi implements DepositRepository {

    async postDepositForm(body: DepositFormRequest): Promise<DepositFormResponse> {
        return post('/deposit/insert', body)
    }

    async postDepositQR(body: DepositQRRequest): Promise<DepositQRResponse> {
        const param = argsToParams(body);
        return post(`/deposit/generateqr?${param}`, null);
    }

    async getCheckUser(): Promise<CheckUserResponse> {
        return get('/checkuser')
    }
}