import React from "react";
import LayoutAppBar, {
    LayoutAppBarProps,
} from "config/components/layout-app-bar";
import FormSendTo, { FormSendToProps } from "config/components/send-otp-to";
import OTPMobileLayout from "../layout-otp/Mobile";

type OTPMobileLayoutProps = {} & FormSendToProps &
    Omit<LayoutAppBarProps, "children">;

const SendToMobileLayout: React.FC<OTPMobileLayoutProps> = (props) => {
    const { onBack, title = "Verifikasi", primary, ...rest } = props;

    return (
        <LayoutAppBar title={title} primary={primary} onBack={onBack}>
            <FormSendTo {...rest} />
        </LayoutAppBar>
    );
};

export default SendToMobileLayout;
