import React from "react";
import useLanguage from "config/hooks/useLanguage";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "config/context/GlobalContext";
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { BottomNavigation as BottomNavigationMenuType } from "config/shared/types";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PersonIcon from "@mui/icons-material/Person";
import log from "config/log";

const HomeLayout = ({ children }: React.PropsWithChildren) => {
    const [{ bottomNavigation }, dispatch] = React.useContext(GlobalContext);

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const { t } = useLanguage();

    const menu: Array<BottomNavigationMenuType> = [
        "home",
        "transaction",
        "profile",
    ];

    React.useEffect(() => {
        const path = pathname.replace("/dashboard/", "");

        if (menu.includes(path as any)) {
            dispatch({
                type: "SET_BOTTOM_NAVIGATION",
                payload: path as BottomNavigationMenuType,
            });
        }
    }, [pathname]);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                background: "#f4f4f4",
                minWidth: "100vw",
            }}
        >
            <Box
                sx={{
                    display: "grid",
                    gridTemplateRows: "auto 45px",
                    width: "100%",
                    maxWidth: 1024,
                    height: "100vh",
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateRows: 'calc(100vh-45px) 45px',
                        gap: '1rem',
                        width: '100%',
                        maxWidth: 1024,
                        height: '100vh',
                    }}
                >
                    {children}
                    <BottomNavigation
                        showLabels
                        value={bottomNavigation}
                        onChange={(_, newValue) => {
                            if (bottomNavigation !== newValue) {
                                log("bottom nav changed");
                                navigate(`/dashboard/${newValue}`, {
                                    replace: true,
                                });
                                dispatch({
                                    type: "SET_BOTTOM_NAVIGATION",
                                    payload: newValue,
                                });
                            }
                        }}
                        sx={{
                            position: "fixed",
                            width: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            bottom: 0,
                            maxHeight: 45,
                            maxWidth: 1024,
                            "& .Mui-selected, .Mui-selected > svg": {
                                fill: "#F1717C !important",
                                fillOpacity: "1",
                                color: "#D63839 !important",
                            },
                            "& .MuiBottomNavigationAction-root, .Mui-selected, svg":
                            {
                                fill: "#000 !important",
                                fillOpacity: "0.38",
                            },
                        }}
                    >
                        <BottomNavigationAction
                            icon={<HomeIcon />}
                            label={t("bottom_navigation.home")}
                            value={menu[0]}
                        />
                        <BottomNavigationAction
                            icon={<ReceiptLongIcon />}
                            label={t("bottom_navigation.transaction")}
                            value={menu[1]}
                        />
                        <BottomNavigationAction
                            icon={<PersonIcon />}
                            label={t("bottom_navigation.profile")}
                            value={menu[2]}
                        />
                    </BottomNavigation>
                </Box>
            </Box>
        </Box>
    );
};

export default HomeLayout;
