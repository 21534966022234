import React from "react";
import log from "config/log";

const determineOrientation = () => {
    return window.innerWidth <= 1024 &&
        window.screen.availHeight > window.screen.availWidth
        ? "portrait"
        : "landscape";
};

const useOrientation = () => {
    const [deviceHeight, setHeight] = React.useState(window.screen.availHeight);

    const [deviceWidth, setWidth] = React.useState(window.screen.availWidth);

    const [orientation, setOrientation] = React.useState<
        "portrait" | "landscape"
    >(determineOrientation());

    const isMobile = deviceWidth <= 1024;

    React.useEffect(() => {
        // Lock camera orientation to portrait mode
        // window.screen.orientation.lock('portrait')
        //     .catch((error) => {
        //         console.error('Failed to lock orientation', error);
        //     });

        const handleResize = () => {
            setHeight(window.screen.availHeight);
            setWidth(window.screen.availWidth);
        };

        const handleOrientation = () => {
            const currentOrientation = determineOrientation();
            log(currentOrientation);
            setOrientation(currentOrientation);
        };

        const handleEvent = () => {
            handleResize();
            handleOrientation();
        };

        window.addEventListener("resize", handleEvent);

        return () => {
            window.removeEventListener("resize", handleEvent);

            // Unlock camera orientation when component unmounts
            // window.screen.orientation.unlock();
        };
    }, []);

    return { orientation, deviceHeight, deviceWidth, isMobile };
};

export default useOrientation;
