import React from "react";
import { useRoutes, RouteObject, Navigate } from "react-router-dom";
import {
    MainRoutes,
    OperationRoutes,
} from "presentation/router/routes/private";
import { AuthRoutes, UtilRoutes } from "presentation/router/routes/public";

interface RouterProps {
    isLoggedIn: boolean;
}

const Router: React.FC<RouterProps> = ({ isLoggedIn }) => {
    let allowedRoutes: RouteObject[] = [...UtilRoutes];

    if (isLoggedIn) {
        allowedRoutes = [...MainRoutes, ...OperationRoutes, ...allowedRoutes];
    } else {
        allowedRoutes = [...AuthRoutes, ...allowedRoutes];
    }

    const routes = useRoutes([
        ...allowedRoutes,
        {
            path: "/",
            element: (
                <Navigate to={isLoggedIn ? "/dashboard/home" : "/login"} />
            ),
        },
        {
            path: "*",
            element: <Navigate to="/" />,
        },
    ]);

    return routes;
};

export default Router;
