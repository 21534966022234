import { CheckUserResponse } from "domain/entity/Deposit";
import { ReversalQRRequest, ReversalQRResponse, ReversalRequest, ReversalResponse } from "domain/entity/Reversal";
import ReversalRepository from "domain/repository/Reversal";


export default class ReversalUseCase implements ReversalRepository {
    constructor(private repository: ReversalRepository) { }

    async postReversal(body: ReversalRequest): Promise<ReversalResponse> {
        return this.repository.postReversal(body)
    }

    async postReversalQR(body: ReversalQRRequest): Promise<ReversalQRResponse> {
        return this.repository.postReversalQR(body)
    }

    getCheckUser(): Promise<CheckUserResponse> {
        return this.repository.getCheckUser()
    }
}