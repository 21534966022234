import React from "react";
import { TransferUangViewModel } from "presentation/pages/viewmodel/transfer-uang";
import LayoutAppBar from "config/components/layout-app-bar";
import FormPengirimanUang from "presentation/pages/view/transfer-uang/components/form-pengiriman-uang";
import FormLokasiPengirimanUang from "./components/form-lokasi-pengiriman-uang";
import TransactionSuccess from "config/components/transaction-success";
import useLanguage from "config/hooks/useLanguage";

type TransferUangPageProps = {
    viewModel: TransferUangViewModel;
};

const TransferUangPage: React.FC<TransferUangPageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    return viewModel.successInsert ? (
        <TransactionSuccess
            alert={t("transaction.alert1")}
            items={viewModel.submitResult}
            onClick={viewModel.redirectToHome}
        />
    ) : (
        <LayoutAppBar
            title={t("operations.send_money")}
            onBack={
                viewModel.nextStep ? viewModel.handleBackButtonClick : undefined
            }
        >
            {viewModel.nextStep ? (
                <FormLokasiPengirimanUang {...props} />
            ) : (
                <FormPengirimanUang {...props} />
            )}
        </LayoutAppBar>
    );
};

export default TransferUangPage;
