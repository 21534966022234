import ProfilePage from "./ProfilePage";
import useProfileViewModel from "presentation/pages/viewmodel/profile";
import OTPUseCase from "domain/interactor/OTP";
import OTPApi from "data/OTP";
import EmailUseCase from "domain/interactor/Email";
import EmailApi from "data/Email";

const useCase = new EmailUseCase(new EmailApi());
const otpUseCase = new OTPUseCase(new OTPApi());

export default function () {
    const viewModel = useProfileViewModel({ emailUseCase: useCase, otpUseCase });

    return <ProfilePage viewModel={viewModel} />;
}
