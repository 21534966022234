import React from "react";
import useOrientation from "config/hooks/useOrientation";

type ResponsiveWrapperProps = {
    mobileLayout?: any;
    desktopLayout?: any;
};

const ResponsiveWrapper: React.FC<ResponsiveWrapperProps> = (props) => {
    const { isMobile } = useOrientation();

    const { mobileLayout, desktopLayout } = props;

    return (
        <React.Fragment>
            {isMobile ? mobileLayout : desktopLayout}
        </React.Fragment>
    );
};

export default ResponsiveWrapper;
