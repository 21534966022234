import React from "react";
import SignIn from "presentation/pages/view/auth-signin-sso/components/form-sign-in";
import AuthDesktopLayout from "config/components/layout-auth/Desktop";
import AuthMobileLayout from "config/components/layout-auth/Mobile";
import useLanguage from "config/hooks/useLanguage";
import { SignInViewModelType } from "presentation/pages/viewmodel/auth-signin-sso";
import ResponsiveWrapper from "config/components/responsive-wrapper";
import Message from "config/components/message";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

type SignInPageProps = {
    viewModel: SignInViewModelType;
};

const SignInPage: React.FC<SignInPageProps> = (props) => {
    const { t } = useLanguage();

    const location = useLocation();

    const { success, handleSuccessRedirect, handleNavigateToRegister, handleTOSRedirect, mobilePhone } =
        props.viewModel;

    const authcode = new URLSearchParams(location.search).get('authcode') ?? "";
    // console.log('authcode', authcode);

    // let CryptoJS = require("crypto-js");
    // let decrypted = CryptoJS.AES.decrypt(authcode, "rmxtransfer77777").toString();

    // const hexToString = (decrypted: any) => {
    //     let str = '';
    //     for (let i = 0; i < decrypted.length; i += 2) {
    //         const charCode = parseInt(decrypted.substr(i, 2), 16);
    //         str += String.fromCharCode(charCode);
    //     }
    //     return str;
    // };
    // console.log('Decrypted Phone', hexToString(decrypted));

    // let mobilePhone = hexToString(decrypted);

    return (
        <>
            {success ? (
                <Box sx={{ height: "100vh", px: "2rem" }}>
                    <Message
                        header={t("auth.success_sign_in_header")}
                        subHeader={t("auth.success_sign_in_subheader")}
                        btnText={t("auth.next_text")}
                        onClick={handleSuccessRedirect}
                        type="success"
                    />
                </Box>
            ) : (
                <ResponsiveWrapper
                    mobileLayout={
                        <AuthMobileLayout
                            header={t("auth.header")}
                            subHeader={t("auth.sub_header")}
                            // bottomText={t("auth.do_not_have_account")}
                            // navigateText={t("auth.register")}
                            // onTextClickNavigate={handleNavigateToRegister}
                            // onTextClickNavigateNews={handleTOSRedirect}
                            // bottomTextNews={t("auth.news")}
                            // navigateTextNews={t("auth.news_detail")}
                            childForm={<SignIn {...props} mobilePhone={mobilePhone} />}
                        />
                    }
                    desktopLayout={
                        <AuthDesktopLayout
                            authType="login"
                            header="Login"
                            childForm={<SignIn {...props} mobilePhone={mobilePhone} />}
                        // onTextClickNavigate={handleNavigateToRegister}
                        // onTextClickNavigateNews={handleTOSRedirect}
                        // bottomTextNews={t("auth.news")}
                        // navigateTextNews={t("auth.news_detail")}
                        />
                    }
                />
            )}
        </>
    );
};

export default SignInPage;
