import AuthApi from "data/Auth";
import ForgotPasswordUseCase from "domain/interactor/Auth/ForgotPasswordUseCase";
import ChangePasswordPage from "presentation/pages/view/change-password/ChangePasswordPage";
import useChangePasswordViewModel from "presentation/pages/viewmodel/change-password";
import PasswordApi from "data/Password";
import PasswordUseCase from "domain/interactor/Password";

const changeUseCase = new PasswordUseCase(new PasswordApi());

const forgotUseCase = new ForgotPasswordUseCase(new AuthApi());

export default function () {
    const viewModel = useChangePasswordViewModel({
        changeUseCase,
        forgotUseCase,
    });

    return <ChangePasswordPage viewModel={viewModel} />;
}
