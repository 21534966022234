const constant = {
    userInfo: "userInfo",
    token: "token",
    otp: "otp"
}

export const setUser = (value: string) => localStorage.setItem(constant.userInfo, value)

export const getUser = () => localStorage.getItem(constant.userInfo)

export const setToken = (value: string) => localStorage.setItem(constant.token, value)

export const getToken = () => localStorage.getItem(constant.token)

export const setOtp = (value: string) => localStorage.setItem(constant.otp, value)

export const getOtp = () => localStorage.getItem(constant.otp)

export const clearLocalStorage = () => localStorage.clear()