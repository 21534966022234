import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { userIdentityValidation } from "presentation/validation/userIden";
import ForgotPasswordUseCase from "domain/interactor/Auth/ForgotPasswordUseCase";
import ForgotPassword from "domain/entity/Auth/model/FormForgotPassword";
import log from "config/log";
import { ALL_DIGIT, EMAIL } from "presentation/validation/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { MutationCache, useMutation } from "react-query";
import OTPUseCase from "domain/interactor/OTP";
import { OTPRequest } from "domain/entity/OTP";
import { GlobalContext } from "config/context/GlobalContext";
import { OTPPayload } from "../auth-otp";
import ForgotPasswordRequest from "domain/entity/Auth/model/FormForgotPassword";
import useLanguage from "config/hooks/useLanguage";
import { phoneValidation } from "presentation/validation";

const initialValues = {
    isEmail: false,
    username: "",
};

// const validationSchema = yup.object({
//     username: userIdentityValidation,
// });
// function getValidationSchema(selectedMethod: string) {
//     console.log("selected method yup", selectedMethod)
//     return yup.object().shape({
//         username: yup.string().when('selectedMethod', {
//             is: 'email',
//             then: userIdentityValidation, // email validation schema
//             otherwise: phoneValidation, // phone validation schema
//         }),
//     });
// }


const Skima = yup.object({
    isEmail: yup.boolean(),
    username: yup.string().when('isEmail', {
        is: true,
        then: yup.string().test(
            {
                name: "valid EMAIL",
                message: "Format email salah, contoh: rem@remid.com",
                test: (value) => {
                    return EMAIL.test(value ?? "")
                }
            }), // email validation schema
        otherwise: phoneValidation, // phone validation schema
    }), 
})

type ForgotPasswordUseCases = {
    useCase: ForgotPasswordUseCase;
    otpUseCase: OTPUseCase;
};

const useForgotPasswordViewModel = ({
    otpUseCase,
    useCase,
}: ForgotPasswordUseCases) => {
    const { t } = useLanguage();

    const location = useLocation();

    const selectedMethod = new URLSearchParams(location.search).get('method')

    // const validationSchema = getValidationSchema(selectedMethod ?? "email");

    const [_, dispatch] = React.useContext(GlobalContext);
    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isValid },
        setValue
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(Skima),
    });

    const { mutateAsync: postOTP, isLoading: otpLoading } = useMutation(
        "send-otp",
        (body: OTPRequest) => otpUseCase.postOTP(body)
    );

    const [showOTP, setShowOTP] = React.useState(false);

    const [success, setSuccess] = React.useState(false);

    const [formSubmitted, setFormSubmitted] = React.useState(false);

    const otpDataRef = React.useRef<OTPPayload | null>(null);

    const submitButtonStatus = isValid;

    const onSubmit = () => {
        // setFormSubmitted(true)
        try {
            let email = '';
            let phone = '';
            let userId = '';
            const _username = getValues('username');

            console.log(selectedMethod);
            if (ALL_DIGIT.test(_username) || selectedMethod === "sms") {
                phone = _username;
            } else if (ALL_DIGIT.test(_username) || selectedMethod === "whatsapp") {
                phone = _username;
            } else {
                email = _username;
            }

            postOTP({
                userId,
                email,
                phone,
                method: selectedMethod,
                type: "forgot-password",
            })
                .then(() => {
                    // set ref
                    otpDataRef.current = {
                        userId,
                        email,
                        phone,
                        method: selectedMethod,
                        type: "forgot-password",
                        sendWhere: email ? 'email' : 'phone'
                    }

                    setShowOTP(true);
                })
                .catch((err) => {
                    const { response } = err;

                    if (response && response.data) {
                        const { message, rc } = response.data;

                        dispatch({
                            type: "SHOW_OVERLAY",
                            payload: {
                                header: t("general.general_error"),
                                type: "warning",
                                btnText: "Kembali",
                                subHeader: t("rc." + rc),
                            },
                        });
                    }
                });
        } catch (error) {
            log(error);
        }
    };

    const otpStringRef = React.useRef<string | null>(null);

    const onPostOTP = (otp: string) => {
        otpStringRef.current = otp;
        return useCase.execute({ otp, password: "", username: getValues('username'), method: selectedMethod ?? "" });
    };

    const onVerificationBack = () => {
        setShowOTP(false);
        // setFormSubmitted(false);
    };

    const onVerificationSuccess = () => {
        // redirect to login karena memang belum login

        const _username = getValues('username');

        navigate("/change-password", {
            state: {
                parent: "/login",
                data: {
                    otp: otpStringRef.current,
                    username: _username,
                    method: selectedMethod,
                },
            },
        });
    };

    const handleSuccessRedirect = () => {
        navigate("/login");
    };

    useEffect(() => {
        if(selectedMethod === 'email') {
            setValue("isEmail", true)
        }
        console.log('mitud: ', selectedMethod)
    }, [selectedMethod])

    return {
        handleSubmit,
        register,
        errors,
        submitButtonStatus,
        onSubmit,
        showOTP,
        onVerificationBack,
        onVerificationSuccess,
        otpDataRef,
        otpLoading,
        handleSuccessRedirect,
        onPostOTP,
        formSubmitted,
        getValues,
        success,
        setSuccess,
        selectedMethod,
    };
};

export type ForgotPasswordViewModel = ReturnType<
    typeof useForgotPasswordViewModel
>;

export default useForgotPasswordViewModel;
