import React from "react";
import {
    useTheme,
    Box,
    Stack,
    Typography,
    CircularProgress,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import ButtonRemid from "core/components/remid-button";
import useOTPViewModel, {
    OTPViewModelProps,
} from "presentation/pages/viewmodel/auth-otp";
import { ALL_DIGIT } from "presentation/validation/constants";

export type FormOTPProps = OTPViewModelProps;

const FormOTP: React.FC<FormOTPProps> = (props) => {
    const { palette } = useTheme();

    const viewModel = useOTPViewModel(props);

    return (
        <Stack alignItems="center" sx={{ textAlign: "center" }} spacing={3}>
            <Typography sx={{ fontWeight: 700 }}>
                Masukan Kode Verifikasi
            </Typography>
            <Typography sx={{ fontSize: ".75rem" }}>
                {viewModel.headerValue.sendType}
                {viewModel.headerValue.formatedData}
            </Typography>
            <MuiOtpInput
                TextFieldsProps={{
                    type: "number",
                    onKeyDown: (e) => {
                        const { key } = e;
                        if (key === "-" || key === "+" || key === "e") {
                            e.preventDefault();
                        }
                    },
                    inputProps: {
                        inputMode: "numeric",
                    },
                    autoComplete: "off",
                    sx: {
                        "& input[type=number]::-webkit-outer-spin-button": {
                            appearance: "none",
                            margin: 0,
                        },
                        "& input[type=number]::-webkit-inner-spin-button": {
                            appearance: "none",
                            margin: 0,
                        },
                        "& input[type=number]": {
                            appearance: "textfield",
                        },
                    },
                }}
                length={viewModel.OTP_LENGTH}
                value={viewModel.otpInput}
                onChange={viewModel.handleChange}
                validateChar={(value) => {
                    return ALL_DIGIT.test(value) || value === "";
                }}
            />
            {viewModel.otpError && (
                <Typography
                    sx={{ fontSize: ".625rem", color: palette.error.main }}
                >
                    {viewModel.otpError
                        ? viewModel.otpError
                        : "Kode Verifikasi yang Anda masukan salah. Silahkan coba kembali"}
                </Typography>
            )}

            <ButtonRemid
                onClick={viewModel.onSubmit}
                disabled={viewModel.otpInput === ""}
                loading={viewModel.isLoading}
            >
                Verifikasi
            </ButtonRemid>

            <Stack>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: ".625rem",
                    }}
                >
                    {!viewModel.otpBlock && (
                        <>
                            <Typography fontSize="inherit">
                                Tidak menerima kode?
                            </Typography>
                            {!viewModel.timerRunning &&
                                (!viewModel.retryLoading ? (
                                    <ButtonRemid
                                        onClick={viewModel.onRetry}
                                        variant="text"
                                        sx={{ fontSize: ".625rem" }}
                                    >
                                        Kirim Ulang
                                    </ButtonRemid>
                                ) : (
                                    <CircularProgress
                                        size={16}
                                        sx={{ px: ".3rem" }}
                                    />
                                ))}
                        </>
                    )}
                    {viewModel.otpTimerLeft && viewModel.timerRunning && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: ".625rem",
                                mx: ".3rem",
                                gap: ".3rem",
                            }}
                        >
                            <Typography fontSize="inherit">
                                {" Kirim Ulang dalam: "}
                            </Typography>
                            <Typography
                                sx={{ color: palette.secondary.main }}
                                fontSize="inherit"
                            >
                                {viewModel.otpTimerText}
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography sx={{ fontSize: ".62rem" }}>
                        Tidak Menerima Kode?
                    </Typography>
                    <ButtonRemid
                        onClick={viewModel.onHubungiCS}
                        variant="text"
                        sx={{ fontSize: ".62rem" }}
                    >
                        Hubungi CS Kami
                    </ButtonRemid>
                </Box>
            </Stack>
        </Stack>
    );
};

export default FormOTP;
