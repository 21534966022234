import React from "react";
import {
    useTheme,
    Box,
    Card,
    CardActionArea,
    Typography,
    SxProps,
} from "@mui/material";

type MenuCardProps = {
    icon?: React.ReactNode;
    text?: string;
    onClick?: () => void;
    sx?: SxProps;
};

const MenuCard: React.FC<MenuCardProps> = (props) => {
    const { icon, text, onClick, sx } = props;

    const { palette } = useTheme();

    const handleClick = () => {
        onClick?.();
    };

    return (
        <Card sx={{ height: "15vh", minHeight: 120, ...sx }}>
            <CardActionArea
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={handleClick}
            >
                <Box sx={{ width: "2.5em", color: palette.secondary.main }}>
                    {icon}
                </Box>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontSize: ".875rem",
                        letterSpacing: ".05em",
                        px: ".5em",
                    }}
                >
                    {text}
                </Typography>
            </CardActionArea>
        </Card>
    );
};

export default MenuCard;
