import AuthApi from "data/Auth";
import AuthUseCase from "domain/interactor/Auth/SignInSSOUseCase";
import useSignInViewModel from "presentation/pages/viewmodel/auth-signin-sso";
import SignInPage from "presentation/pages/view/auth-signin-sso/SignInPage";

export default function SignIn() {
    const api = new AuthApi();
    const authUseCase = new AuthUseCase(api);
    const viewModel = useSignInViewModel(authUseCase);

    return <SignInPage viewModel={viewModel} />;
}
