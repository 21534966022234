import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Box, Typography, IconButton } from "@mui/material";
import CameraRoundedIcon from "@mui/icons-material/CameraRounded";
import CameraswitchRoundedIcon from "@mui/icons-material/CameraswitchRounded";
import Webcam from "react-webcam";
import useOrientation from "config/hooks/useOrientation";
import log from "config/log";
import UAParser from 'ua-parser-js';

type CameraComponentProps = {
    facingMode?: "user" | "environment";
    onCapture?: (imgData: string | null) => void;
    onBack?: () => void;
};

const baseCameraStyle: any = {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
};

const CameraComponent: React.FC<CameraComponentProps> = (props) => {
    const { facingMode, onCapture, onBack } = props;

    const [faceMode, setFaceMode] = React.useState(facingMode);

    const cameraContainerRef = React.useRef<HTMLDivElement | null>(null);

    const cameraInput = React.useRef<HTMLInputElement | null>(null);

    const cameraRef = React.useRef<Webcam | null>(null);

    const navigate = useNavigate();

    const { orientation, isMobile } = useOrientation();

    let aspectRatio = 1;

    let cameraStyle: any = { ...baseCameraStyle };

    const parser = new UAParser();

    const result = parser.getResult();

    // Check if the user is on a mobile device
    const isMobiles = !!result?.device?.type;

    // Check if the user is using an iOS device
    const isIOS = result?.os?.name === 'iOS';

    // Check if the user is using a specific browser
    const isChrome = result?.browser?.name === 'Chrome';

    // window.alert('window: ' + orientation);

    if (
        isMobile &&
        orientation === "landscape" &&
        cameraContainerRef.current !== null
    ) {
        const { width, height } =
            cameraContainerRef.current.getBoundingClientRect();

        cameraStyle = {
            objectFit: "cover",
            maxWidth: width,
            transformOrigin: "left bottom",
            transform: `rotate(90deg) translate(-75%)`,
        };
        aspectRatio = width / height;
    }

    const handleCapture = useCallback(() => {
        if (cameraRef.current === null) return;
        const imageSrc = cameraRef.current.getScreenshot();
        onCapture?.(imageSrc);
    }, [cameraRef, onCapture]);

    const handleFacingModeChange = () =>
        setFaceMode((value) => (value === "user" ? "environment" : "user"));

    const handleBack = () => {
        onBack?.();
    };

    const test = () => {
        if (!cameraInput.current?.value.length) {
            alert("camera close")
        }
    }

    React.useEffect(() => {
        const handleOrientationChange = () => {
            const isLandscape = window.orientation === 90 || window.orientation === -90;
            const previewElement = cameraRef.current?.video;

            if (previewElement) {
                if (isLandscape) {
                    previewElement.style.transform = 'rotate(90deg)';
                } else {
                    previewElement.style.transform = 'rotate(0deg)';
                }
            }
        };

        handleOrientationChange();

        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    // React.useEffect(() => {
    //     if(cameraInput.current) {
    //         // alert("clicked")
    //         cameraInput.current.click()
    //         document.body.onfocus = test
    //         // cameraInput.current.onfocus = test
    //         // @ts-ignore
    //         // cameraInput.current.onclose = () => 
    //         // cameraInput.current.addEventListener("onclose", () => { alert("ketutup dab")})
    //     }
    // }, [cameraInput.current])


    // if(cameraContainerRef.current) {
    //     const width = cameraContainerRef.current.clientWidth;
    //     const height = cameraContainerRef.current.clientHeight;

    //     cameraContainerRef.current.innerHTML = `<p>height: ${height} | width: ${width}</p>`
    // }

    // React.useEffect(() => {

    // }, [cameraContainerRef.current, cameraContainerRef.current?.clientWidth, cameraContainerRef.current?.clientHeight])

    React.useEffect(() => {
        // const backIfNotFullScreen = () => {
        //     // if not full screen navigate back to previous page
        //     if (window.innerHeight !== window.screen.height) {
        //         // navigate(-1);
        //     }
        // };

        // if (isMobile && window && document) {
        //     const ua = window.navigator.userAgent;

        //     const isIos = /iP(ad|hone)/i.test(ua);

        //     const de: HTMLElement & any = document.documentElement;

        //     if (!isIos && window.screen.orientation.lock) {
        //         if (de.requestFullscreen) {
        //             de.requestFullscreen();
        //         } else if (de.webkitRequestFullScree) {
        //             de.webkitRequestFullScree();
        //         }

        //         window.screen.orientation.lock("portrait");
        //         window.addEventListener("resize", backIfNotFullScreen);
        //     }
        // }

        // return () => {
        //     window.removeEventListener("resize", backIfNotFullScreen);
        // };
    }, [isMobile, window, document]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                background: "#f4f4f4",
            }}
        >
            <Stack
                sx={{
                    background: "red",
                    height: "100%",
                    width: "100%",
                    maxWidth: "1024px",
                    position: "fixed",
                    "@media screen and (orientation: landscape) and (max-width: 1025px)":
                    {
                        width: "100vh",
                        height: "100vw",
                        transformOrigin: "top left",
                        transform:
                            "rotate(-90deg) translate(-100%, -27.5%)",
                    },
                }}
            >
                <Box
                    id="camera__header"
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        backgroundColor: "black",
                        height: "10%",
                        minHeight: 88,
                    }}
                ></Box>
                <Box
                    id="camera__content"
                    sx={{
                        position: "relative",
                        flex: 1,
                        backgroundColor: "#333",
                        color: "#FFF"
                    }}
                    ref={cameraContainerRef}
                >
                    <Webcam
                        ref={cameraRef}
                        style={cameraStyle}
                        videoConstraints={{
                            facingMode: faceMode,
                            // aspectRatio,
                        }}
                        mirrored={faceMode === "user"}
                        forceScreenshotSourceSize={true}

                    />
                </Box>
                {/* <Box
                    id="camera__content"
                    sx={{
                        position: "relative",
                        flex: 1,
                        backgroundColor: "#333",
                        // transform: "rotate(90deg)",
                        color: "#FFF"
                    }}
                    ref={cameraContainerRef}
                >
                    <Webcam
                        ref={cameraRef}
                        style={cameraStyle}
                        videoConstraints={{
                            facingMode: faceMode,
                        }}
                        mirrored={faceMode === "user"}
                        forceScreenshotSourceSize={true}
                    />
                </Box> */}
                <Box
                    id="camera__actions"
                    sx={{
                        backgroundColor: "black",
                        height: "25%",
                        minHeight: 150,
                        maxHeight: 150,
                    }}
                >
                    <Stack
                        sx={{
                            width: "100%",
                            height: "100%",
                            color: "white",
                            gap: "1rem",
                        }}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="row"
                    >
                        <IconButton
                            onClick={handleBack}
                            sx={{ color: "white" }}
                        >
                            <Typography fontWeight={600} color="inherit">
                                BATAL
                            </Typography>
                        </IconButton>
                        <Stack alignItems="center">
                            <Typography
                                sx={{ fontSize: ".75rem" }}
                                color="inherit"
                            >
                                Foto
                            </Typography>
                            <IconButton color="inherit" onClick={handleCapture}>
                                <CameraRoundedIcon sx={{ fontSize: "4rem" }} />
                            </IconButton>
                        </Stack>
                        <IconButton
                            sx={{ fontSize: ".5rem" }}
                            color="inherit"
                            onClick={handleFacingModeChange}
                        >
                            <CameraswitchRoundedIcon />
                        </IconButton>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};

export default CameraComponent;
