import React from "react";
import { SxProps, Box, useTheme, Typography, IconButton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import log from "config/log";

export type AppBarProps = {
    title?: string;
    onBack?: () => void;
    primary?: "white" | "red";
};

const AppBar: React.FC<AppBarProps> = (props) => {
    const { palette } = useTheme();

    const location = useLocation();

    const navigate = useNavigate();

    const { onBack, title, primary = "red" } = props;

    const style: SxProps = {
        height: 64,
        width: "100%",
        color: primary === "red" ? "#fff" : palette.primary.main,
        backgroundColor: primary === "red" ? palette.primary.main : "#fff",
        backgroundImage: "url('svg/AppBarLine.svg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, -0.1), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 1px 5px rgba(0, 0, 0, 0)",
    };

    const handleClick = () => {
        if (typeof onBack === "function") {
            log("onBack using function");
            onBack();
        } else if (location.state !== null && location.state?.parent !== null) {
            log("navigate using parent");
            navigate(location.state.parent);
        } else {
            // pop history stack if no onBack provided
            log("onBack using -1");
            navigate(-1);
        }
    };

    return (
        <Box sx={style}>
            <Box
                sx={{
                    px: "1em",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <IconButton color="inherit" onClick={handleClick}>
                    <ArrowBackIosNewRoundedIcon color="inherit" />
                </IconButton>
                <Typography color="inherit" fontWeight="600">
                    {title}
                </Typography>
            </Box>
        </Box>
    );
};

export default React.memo(AppBar);
