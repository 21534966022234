import React, { useState } from "react";
import FormForgotPin from "presentation/pages/view/forgot-pin/components/form-forgot-pin";
import { ForgotPinViewModel } from "presentation/pages/viewmodel/forgot-pin";
import LayoutAppBar from "config/components/layout-app-bar";
import OTPMobileLayout from "config/components/layout-otp/Mobile";
import useLanguage from "config/hooks/useLanguage";
import SendToMobileLayout from "config/components/layout-send-otp-to";

type ForgotPinPageProps = {
    viewModel: ForgotPinViewModel;
};

const ForgotPinPage: React.FC<ForgotPinPageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    return !viewModel.success ? (
        <SendToMobileLayout
            primary="white"
            type={"forgot-pin"}
            otpMethodList={{
                sms: viewModel.user?.phone,
                whatsapp: viewModel.user?.phone,
                email: viewModel.user?.email,
            }}
            postOTP={viewModel.onPostOTP}
            onSuccess={viewModel.onVerificationSubmit}
            storageKey="forgot-pin"
        />
    ) : (
        <LayoutAppBar title={t("profile.forgot_pin2")}>
            <FormForgotPin {...props} />
        </LayoutAppBar>
    );
};

export default ForgotPinPage;
