import * as yup from 'yup'
import { ALL_DIGIT, MINIMUM_PHONE_LENGTH, MAXIMUM_PHONE_LENGTH, EMAIL } from 'presentation/validation/constants'

export const userIdentityValidation =
    yup.string()
        .test(
            {
                name: "shortPhoneLength",
                message: "Nomor terlalu pendek, minimal 10 karakter",
                test: (value) => {
                    return !Boolean(value && ALL_DIGIT.test(value) && value.length < MINIMUM_PHONE_LENGTH)
                }
            })
        .test(
            {
                name: "LongPhoneLength",
                message: "Nomor terlalu panjang, maksimal 14 karakter",
                test: (value) => {
                    return !Boolean(value && ALL_DIGIT.test(value) && value.length > MAXIMUM_PHONE_LENGTH)
                }
            })
        .test(
            {
                name: "valid EMAIL",
                message: "Format email salah, contoh: rem@remid.com",
                test: (value) => {
                    return !Boolean(value && !ALL_DIGIT.test(value) && !EMAIL.test(value))
                }
            })