import { EMAIL } from 'presentation/validation/constants'
const emailObfuscate = (email: string) => {

    if (!EMAIL.test(email)) {
        return email;
    }

    const strings = email.split("@")

    let username = strings[0]

    const domain = strings[1]

    if (username.length > 10) {
        const sliced = username.slice(1, 8)
        username = `${username[0]}${sliced}${username[username.length - 1]}`
    }

    const formatedUsername = username.split("")
        .map((item, idx) =>
            idx === 0 || idx === username.length - 1 ? item : "*").join("")

    return `${formatedUsername}@${domain}`
}

export default emailObfuscate