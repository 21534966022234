import * as yup from "yup";
import {
    ALL_DIGIT,
    MINIMUM_PHONE_LENGTH,
    MAXIMUM_PHONE_LENGTH,
} from "presentation/validation/constants";
import { unFormatPhone } from 'config/shared/utils/phone-formatter'

const phoneValidation = yup
    .string()
    .required("validation.phone_required")
    .test({
        name: "valid_phone",
        message: "validation.phone_format",
        test: (value) => {
            if (value === undefined || value === null) return false
            const testValue = unFormatPhone(value)
            return ALL_DIGIT.test(testValue)
        },
    })
    .test({
        name: "phone_length",
        message: "validation.phone_short",
        test: (value) => {
            if (value === undefined || value === null) return false
            const testValue = unFormatPhone(value)
            return testValue.length >= MINIMUM_PHONE_LENGTH
        }
    })
    .test({
        name: "phone_length",
        message: "validation.phone_long",
        test: (value) => {
            if (value === undefined || value === null) return false
            const testValue = unFormatPhone(value)
            return testValue.length <= MAXIMUM_PHONE_LENGTH
        }
    })

export default phoneValidation;
