const ALL_DIGIT = /^\d+$/
const EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/  // eslint-disable-line 
const MINIMUM_PHONE_LENGTH = 10
const MAXIMUM_PHONE_LENGTH = 14
const MINIMUM_PASSWORD_LENGTH = 8
const PASSWORD_COMBINATION = /^(?=.*[a-zA-Z])(?=.*[0-9])/
const PIN_LENGTH = 6
const MINIMUM_TRANSFER_AMOUNT = 50_000
const MAXIMUM_TRANSFER_AMOUNT = 2_500_000

const MINIMUM_TRANSFER_AMOUNT_ALFA = 10_000
const MAXIMUM_TRANSFER_AMOUNT_ALFA = 5_000_000

export {
    ALL_DIGIT,
    EMAIL,
    MINIMUM_PHONE_LENGTH,
    MAXIMUM_PHONE_LENGTH,
    PASSWORD_COMBINATION,
    MINIMUM_PASSWORD_LENGTH,
    PIN_LENGTH,
    MINIMUM_TRANSFER_AMOUNT,
    MAXIMUM_TRANSFER_AMOUNT,
    MINIMUM_TRANSFER_AMOUNT_ALFA,
    MAXIMUM_TRANSFER_AMOUNT_ALFA,
}