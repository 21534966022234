import React from "react";
import { Stack } from "@mui/material";
import ButtonRemid from "core/components/remid-button";
import TextFieldRemid from "core/components/remid-textfield";
import { ChangeEmailViewModel } from "presentation/pages/viewmodel/change-email";
import useLanguage from "config/hooks/useLanguage";

type FormChangeEmailProps = {
    viewModel: ChangeEmailViewModel;
};

const FormChangeEmail: React.FC<FormChangeEmailProps> = ({ viewModel }) => {
    const { handleSubmit, onSubmit, register, submitButtonStatus, errors } =
        viewModel;

    const { t } = useLanguage();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <TextFieldRemid
                    {...register("email")}
                    fullWidth
                    shrink
                    label="Email"
                    error={Boolean(errors.email)}
                    helperText={t(errors.email?.message)}
                />
                <ButtonRemid type="submit" disabled={!submitButtonStatus}>
                    {t("general.btn1")}
                </ButtonRemid>
            </Stack>
        </form>
    );
};

export default FormChangeEmail;
