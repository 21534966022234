import log from "config/log";
import { ReversalQRRequest, ReversalRequest } from "domain/entity/Reversal";
import ReversalUseCase from "domain/interactor/Reversal";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { GlobalContext } from "config/context/GlobalContext";
import { useNavigate } from "react-router-dom";
import useLanguage from "config/hooks/useLanguage";

const initialValues = {
    kodeBatal: "",
    mtcn: "",
};

const useCancelUangViewModel = (useCase: ReversalUseCase) => {
    const { t } = useLanguage();

    const navigate = useNavigate();

    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const [showDetail, setShowDetail] = React.useState(false);

    const [showPINValidation, setShowPINValidation] = React.useState(false);

    const [showSuccess, setShowSuccess] = React.useState(false);

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: initialValues,
    });

    const [_cancelCode, _mtcnCode] = watch(["kodeBatal", "mtcn"]);

    const submitButtonStatus = _cancelCode === "";

    const {
        mutateAsync,
        data: reversalInsertData,
        isLoading: isReversalInsertLoading,
        isError: isReversalInsertError,
        isSuccess: isReversalInsertSuccess,
    } = useMutation("/reversal/insert", (body: ReversalRequest) =>
        useCase.postReversal(body)
    );

    const {
        mutateAsync: mutateAsyncQR,
        data: reversalQRData,
        isLoading: isReversalQRLoading,
        isSuccess: isReversalQRSuccess,
    } = useMutation("/reversal/generate-qr", (body: ReversalQRRequest) =>
        useCase.postReversalQR(body)
    );

    const { mutateAsync: getCheck, isLoading: pinloading } = useMutation(() =>
        useCase.getCheckUser()
    );

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getCheck(); // Await the asynchronous function call
                // Handle the result as needed
            } catch (err) {
                const { response } = err as any; // should only handle axios Error

                if (response && response.data) {
                    const { message, rc } = response.data;

                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.failed1"),
                            type: "warning",
                            btnText: "Kembali",
                            subHeader: t("rc." + rc),
                            onClick: () => {
                                navigate("/dashboard/home");

                                dispatch({ type: "CLOSE_OVERLAY" });
                            }
                        },
                    });
                }
            }
        };

        fetchData(); // Call the async function
    }, []);

    const onSubmit = async () => {
        try {
            const userId = user?.userId ? user?.userId.toString() : "";

            await mutateAsync({
                kodeBatal: _cancelCode,
                mtcn: _mtcnCode,
                userId,
                productId: "5",
            });
        } catch (err) {
            const { response = null } = err as any;

            if (response && response.data) {
                // const { message = "asd" } = response.data;
                const { rc } = response.data;
                const message = t("rc." + rc);

                setError("kodeBatal", { message });
            }
        }
    };

    const onGenerateQRCode = () => {
        setShowPINValidation(true);
    };

    const onPINValidation = async (pin: string) => {
        try {
            const userId = user?.userId ? user?.userId.toString() : "";

            await mutateAsyncQR({
                userId,
                reversalId: reversalInsertData?.data?.ReversalId || "",
                pin,
            });

            setShowPINValidation(false);
        } catch (err) {
            throw err;
        }
    };

    const onClose = () => {
        // setShowSuccess(true);
        navigate("/dashboard/transaction");
    };

    const onBack = () => {
        return showPINValidation
            ? setShowPINValidation(false)
            : setShowDetail(false);
    };

    return {
        showDetail,
        showPINValidation,
        showSuccess,
        register,
        handleSubmit,
        errors,
        submitButtonStatus,
        onSubmit,
        onGenerateQRCode,
        onPINValidation,
        onClose,
        onBack,
        isReversalInsertError,
        isReversalInsertLoading,
        isReversalInsertSuccess,
        reversalInsertData,
        isReversalQRSuccess,
        reversalQRData,
        isReversalQRLoading
    };
};

export type CancelUangViewModel = ReturnType<typeof useCancelUangViewModel>;

export default useCancelUangViewModel;
