import { CheckUserResponse, DepositFormRequest, DepositFormResponse, DepositQRRequest, DepositQRResponse } from "domain/entity/Deposit";
import DepositRepository from "domain/repository/Deposit";
export default class DepositUseCase implements DepositRepository {
    constructor(private repository: DepositRepository) { }

    postDepositForm(body: DepositFormRequest): Promise<DepositFormResponse> {
        return this.repository.postDepositForm(body)
    }

    postDepositQR(body: DepositQRRequest): Promise<DepositQRResponse> {
        return this.repository.postDepositQR(body)
    }

    getCheckUser(): Promise<CheckUserResponse> {
        return this.repository.getCheckUser()
    }
}