import useListTransaksiDetailViewModel from "presentation/pages/viewmodel/list-transaksi-detail";
import ListTransaksiDetailPage from "presentation/pages/view/list-transaksi-detail/ListTransaksiDetailPage";
import DepositUseCase from "domain/interactor/Deposit";
import DepositApi from "data/Deposit";

const useCase = new DepositUseCase(new DepositApi());

export default function TrxDetail () {
    const viewModel = useListTransaksiDetailViewModel(useCase);

    return <ListTransaksiDetailPage viewModel={viewModel} />;
}