import React from "react";
import { Stack, Box } from "@mui/material";
import AppBar, { AppBarProps } from "config/components/app-bar";

export type LayoutAppBarProps = {} & AppBarProps;

const LayoutAppBar = (props: React.PropsWithChildren<LayoutAppBarProps>) => {
    const { children, ...rest } = props;

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    width: "100%",
                    maxWidth: 1024,
                }}
            >
                <AppBar {...rest} />
                <Box
                    sx={{
                        flex: 1,
                        px: "1rem",
                        pb: "1rem",
                        overflow: "auto",
                        background: "white"
                    }}
                >
                    {children}
                </Box>
            </Stack>
        </Box>
    );
};

export default LayoutAppBar;
