import { AktivasiKTPRequest, AktivasiKTPResponse } from "domain/entity/AktivasiKTP";
import AktivasiKTPRepository from "domain/repository/AktivasiKTP";
import { get, post } from "config/infra";

export default class AktivasiKTPApi implements AktivasiKTPRepository {
    async postKTP(body: FormData): Promise<any> {
        return post('/fulfillment', body, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    async postKTPRefill(): Promise<AktivasiKTPResponse> {
        return get('/fulfillment/refill')
    }
}