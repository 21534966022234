import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import pinValidation from "presentation/validation/pin";
import {
    isPinEquals,
    isPinLengthValid,
    pinHelperData,
    pinHelperDataValidate,
} from "config/shared/utils/pin";
import log from "config/log";
import { useMutation } from "react-query";

/** tidy later */
import { GlobalContext } from "config/context/GlobalContext";
import PinUseCase from "domain/interactor/Pin";
import { setUser } from "config/storage";
import { ChangePinRequest, ForgotPinRequest } from "domain/entity/Auth/model/Pin";
import OTPUseCase from "domain/interactor/OTP";
import useLanguage from "config/hooks/useLanguage";

const initialValues = {
    oldPin: "", // ini harus di remap jadi old_pin
    pin: "", // ini nanti kalau change pin dirubah jadi new_pin
    confirmPin: "", // ini helper FE aja gak masuk body
};

type ForgotPinUseCases = {
    useCase: PinUseCase;
    otpUseCase: OTPUseCase;
};

const useChangePinViewModel = ({
    otpUseCase,
    useCase,
}: ForgotPinUseCases) => {
    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const { t } = useLanguage();

    const location = useLocation();

    const navigate = useNavigate();

    const [usingOldPin, setUsingOldPin] = React.useState(false);

    const [showOldPin, setShowOldPin] = React.useState(false);

    const [showPassword, setShowPassword] = React.useState(false);

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const [success, setSuccess] = React.useState(false);

    const validationSchema = yup.object({
        // oldPin: usingOldPin ? pinValidation : yup.string(),
        oldPin: usingOldPin
            ? yup.string().required("validation.old_pin_required")
            : yup.string(),
        pin: pinValidation,
        confirmPin: yup
            .string()
            .oneOf([yup.ref("pin"), null], "Pin tidak sama"),
    });

    const {
        formState: { errors },
        register,
        handleSubmit,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const _pin = watch("pin");

    const _confirmPin = watch("confirmPin");

    const showPinHelper = _pin !== "";

    if (showPinHelper) {
        /**
         * harcoded index
         *  {0} - pin length
         *  {1} - same pin and confirm
         */

        pinHelperData[0].status = isPinLengthValid(_pin);
        pinHelperData[1].status = isPinEquals(_pin, _confirmPin);
    }

    // const submitButtonStatus = _pin !== "" && _confirmPin !== "";
    const submitButtonStatus = React.useMemo(() => {
        log("validate pin running");
        return pinHelperDataValidate(pinHelperData);
    }, [_pin, _confirmPin]);

    const { mutateAsync: mutateAsyncWithout } = useMutation(
        "without-oldpin-change-pin",
        (body: Pick<typeof initialValues, "pin"> & { userId: string }) =>
            useCase.postSetPin({ ...body })
    );

    const { mutateAsync: changePin } = useMutation(
        "/pin/change", (body: ChangePinRequest) => useCase.postChangePin(body)
    );

    const { mutateAsync: forgotPin } = useMutation(
        "/pin/forgot", (body: ForgotPinRequest) => useCase.postForgotPin(body)
    );

    const onSubmit = async (data: typeof initialValues) => {
        const { change, datas } = location.state;
        const { pin, oldPin } = data;

        if (!user?.userId) {
            log("gak ada user id nya bro");
            return;
        }

        // pin - change PIN
        if (usingOldPin) {
        }

        const userId = user?.userId ? user.userId.toString() : "";

        if (change) {
            log('masuk ubah pin')

            try {
                await changePin({
                    userId: userId,
                    oldPin: oldPin,
                    newPin: pin
                })

                setSuccess(true);
            } catch (err) {
                const { response = null } = err as any;

                if (response && response.data) {
                    const { message, rc } = response.data;

                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.title3"),
                            subHeader: t("rc." + rc),
                            type: "warning",
                        },
                    });
                }
            }
        } else if (datas) {
            log('masuk forgot pin')

            try {
                await forgotPin({
                    username: datas.username,
                    pin: pin,
                    otp: datas.otp,
                    method: datas.method
                }).then(() => {
                    setSuccess(true);
                }).catch((err) => {
                    const { response } = err;
                    if (response && response.data) {
                        const { message, rc } = response.data;

                        dispatch({
                            type: "SHOW_OVERLAY",
                            payload: {
                                header: t("general.general_error"),
                                type: "warning",
                                btnText: "Kembali",
                                subHeader: t("rc." + rc),
                            },
                        });
                    }
                });
            } catch (error) {
                log(error);
            }
        } else {
            log('masuk set pin')

            // pin - set PIN
            if (!usingOldPin) {
                try {
                    await mutateAsyncWithout({ pin, userId });

                    // setUser(JSON.stringify({ ...user, is_pin_verified: "1" }));

                    dispatch({
                        type: "SAVE_USER",
                        payload: { ...user, isPinVerified: "1" },
                    });

                    setSuccess(true);
                } catch (err) {
                    log(err);
                }
            }
        }
    };

    const handleSuccessRedirect = () => {
        const parentRedirect = location.state.parent as any;

        log(["redirect from parent:", parentRedirect]);

        navigate(parentRedirect);
    };

    React.useEffect(() => {
        if (!Boolean(location.state)) {
            log("you shall not pass");
            navigate("/");
        } else {
            const { parent, change = false } = location.state;
            if (parent === "/dashboard/profile" && change) {
                setUsingOldPin(true);
            }
        }
    }, [location.state]);

    return {
        errors,
        register,
        handleSubmit,
        onSubmit,
        showPassword,
        setShowPassword,
        showConfirmPassword,
        setShowConfirmPassword,
        submitButtonStatus,
        success,
        handleSuccessRedirect,
        usingOldPin,
        showOldPin,
        setShowOldPin,
        showPinHelper,
        pinHelperData,
    };
};

export type ChangePinViewModel = ReturnType<typeof useChangePinViewModel>;

export default useChangePinViewModel;
