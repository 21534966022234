import React from "react";
import { Stack, Box, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LayoutDesktop from "config/components/layout-desktop";

type ConfirmIdentityLayoutProps = {
    onBack?: () => void;
    content?: React.ReactNode;
};

const ConfirmIdentityLayout: React.FC<ConfirmIdentityLayoutProps> = (props) => {
    const { onBack, content } = props;

    const handleCloseButton = () => {
        onBack?.();
    };

    return (
        <LayoutDesktop>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <IconButton onClick={handleCloseButton}>
                    <CloseRoundedIcon />
                </IconButton>
            </Box>
            <Stack sx={{ minHeight: "80vh" }}>{content}</Stack>
        </LayoutDesktop>
    );
};

export default ConfirmIdentityLayout;
