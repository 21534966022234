import { useContext } from "react";
import { SnackbarContext } from "../context/Snackbar";

export const useSnackbar = () => {
    const snackbarCtx = useContext(SnackbarContext);
    if (!snackbarCtx)
        throw Error("useSnackbar must be used inside SnackbarProvider");

    return { ...snackbarCtx };
};
