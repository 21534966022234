import React from "react";
import { Stack } from "@mui/material";
import ButtonRemid from "core/components/remid-button";
import TextFieldRemid from "core/components/remid-textfield";
import { ChangePinViewModel } from "presentation/pages/viewmodel/change-pin";
import useLanguage from "config/hooks/useLanguage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PinHelper from "config/components/pin-helper";

type FormChangePinProps = {
    viewModel: ChangePinViewModel;
};

const FormChangePin: React.FC<FormChangePinProps> = ({ viewModel }) => {
    const { t } = useLanguage();

    const {
        errors,
        handleSubmit,
        onSubmit,
        register,
        showPassword,
        showConfirmPassword,
        setShowConfirmPassword,
        setShowPassword,
        submitButtonStatus,
        usingOldPin,
        showOldPin,
        setShowOldPin,
        showPinHelper,
        pinHelperData,
    } = viewModel;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                {usingOldPin && (
                    <TextFieldRemid
                        {...register("oldPin")}
                        number
                        fullWidth
                        label={t("pin.old_pin")}
                        type={showOldPin ? "text" : "password"}
                        password
                        shrink
                        iconButton={
                            showOldPin ? (
                                <VisibilityOffIcon />
                            ) : (
                                <VisibilityIcon />
                            )
                        }
                        inputProps={{
                            maxLength: 6,
                        }}
                        onIconButtonClick={() =>
                            setShowOldPin((state) => !state)
                        }
                        error={Boolean(errors.oldPin)}
                    />
                )}
                <TextFieldRemid
                    {...register("pin")}
                    number
                    fullWidth
                    label={t("pin.new_pin")}
                    type={showPassword ? "text" : "password"}
                    password
                    shrink
                    iconButton={
                        showPassword ? (
                            <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                        )
                    }
                    inputProps={{
                        maxLength: 6,
                    }}
                    onIconButtonClick={() => setShowPassword((state) => !state)}
                    error={Boolean(errors.pin)}
                />
                <TextFieldRemid
                    {...register("confirmPin")}
                    fullWidth
                    number
                    label={t("pin.confirmnew_pin")}
                    type={showConfirmPassword ? "text" : "password"}
                    password
                    shrink
                    inputProps={{
                        maxLength: 6,
                    }}
                    iconButton={
                        showConfirmPassword ? (
                            <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                        )
                    }
                    onIconButtonClick={() =>
                        setShowConfirmPassword((state) => !state)
                    }
                    error={Boolean(errors.confirmPin)}
                    helperText={t(errors.confirmPin?.message)}
                />

                {showPinHelper && <PinHelper data={pinHelperData} />}

                <ButtonRemid
                    type="submit"
                    // loading={viewModel.isLoading}
                    disabled={!submitButtonStatus}
                >
                    {t("general.save")}
                </ButtonRemid>
            </Stack>
        </form>
    );
};
export default FormChangePin;
