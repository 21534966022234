import { Card, Slide, Button, Modal, CardContent, ModalProps } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = Omit<ModalProps, "children">

export default function SlideModal(props: PropsWithChildren<Props>) {
    const { open, children, ...restModalProps } = props

    return (

        <Modal open={open} {...restModalProps}>
            <Slide
                direction="up"
                in={open}
                mountOnEnter
                unmountOnExit
            >
                <Card
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        zIndex: 999,
                        borderTopRightRadius: 16,
                        borderTopLeftRadius: 16,
                        width: "100vw"
                    }}
                >
                    <CardContent
                        sx={{ maxHeight: "60vh", overflow: "auto" }}
                    >
                        {children}
                    </CardContent>
                </Card>
            </Slide>
        </Modal>

    );
}