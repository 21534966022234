import { get, post } from "config/infra";
import { CheckUserResponse } from "domain/entity/Deposit";
import { WithdrawRequest, WithdrawResponse, WithdrawQRRequest, WithdrawQRResponse, WithdrawMtcnRequest, WithdrawMtcnResponse } from "domain/entity/Withdraw";
import WithdrawRepository from "domain/repository/Withdraw";

export default class WithdrawApi implements WithdrawRepository {
    async postWithdrawMtcn(body: WithdrawMtcnRequest): Promise<WithdrawMtcnResponse> {
        return post('/withdraw/mtcn', body)
    }

    async postWithdraw(body: WithdrawRequest): Promise<WithdrawResponse> {
        return post('/withdraw/insert', body)
    }

    async postWithdrawQR(body: WithdrawQRRequest): Promise<WithdrawQRResponse> {
        return post('/withdraw/generateqr', body)
    }

    async getCheckUser(): Promise<CheckUserResponse> {
        return get('/checkuser')
    }
}