import TransactionRepository from "domain/repository/Transaction";
import { TransactionRequest } from 'domain/entity/Auth/model/Transaction'
import { TransactionDetailRequest } from "domain/entity/Auth/model/TransactionDetail";

export default class TransactionUseCase {
    constructor(private service: TransactionRepository) { }

    execute(body: TransactionRequest) {
        return this.service.listTrx(body);
    }

    listtrxdtl(body: TransactionDetailRequest) {
        return this.service.listTrxDtl(body);
    }
}