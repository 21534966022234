import React from "react";
import {
    useTheme,
    Typography,
    Stack,
    Box,
    SxProps,
    Button,
    IconButton,
    CircularProgress,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import useLanguage from "config/hooks/useLanguage";

type FieldProfileProps = {
    label?: string;
    value?: string;
    status?: "normal" | "verified" | "unverified";
    onClickPositive?: () => void;
    onClickNegative?: () => void;
    loading?: boolean;
    sx?: SxProps;
};

const FieldProfile: React.FC<FieldProfileProps> = (props) => {
    const { palette } = useTheme();

    const { t } = useLanguage();

    const {
        label,
        value,
        status = "normal",
        onClickNegative,
        onClickPositive,
        sx,
        loading,
    } = props;

    const handleClickPositive = () => {
        onClickPositive?.();
    };

    const handleClickNegative = () => {
        onClickNegative?.();
    };

    return (
        <Stack
            sx={{
                fontSize: ".75rem",
                gap: ".5rem",
                background: "#fff",
                borderRadius: 2,
                boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                "& .MuiTypography-root": {
                    fontSize: "inherit",
                },
                px: "1.1rem",
                py: ".5rem",
                ...sx,
            }}
            justifyContent="center"
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                }}
            >
                <Typography sx={{ flex: 1 }}>{label}</Typography>

                <Box sx={{ display: "flex", gap: ".3rem" }}>
                    <Stack justifyContent="end">
                        <Typography>{value}</Typography>

                        {status === "unverified" && (
                            <Typography
                                sx={{
                                    color: palette.error.main,
                                    fontSize: ".625rem",
                                }}
                            >{`(${label} ${t("profile.not_verif")})`}</Typography>
                        )}

                        {status === "verified" && (
                            <Typography
                                sx={{ color: "#2E7D32", fontSize: ".75rem" }}
                            >{`(${label} ${t("profile.already_verif")})`}</Typography>
                        )}
                    </Stack>
                </Box>

                {(status === "verified" || status === "normal") && (
                    <IconButton onClick={handleClickPositive}>
                        <EditRoundedIcon fontSize="medium" />
                    </IconButton>
                )}
            </Box>

            {status === "unverified" && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        variant="outlined"
                        onClick={handleClickNegative}
                        sx={{ fontSize: ".625rem", width: "95%" }}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : (
                            t("profile.verification")
                        )}
                    </Button>
                </Box>
            )}
        </Stack>
    );
};

export default FieldProfile;
