import { get } from "config/infra";
import argsToParams from "config/shared/utils/args-to-params";
import { InformationRequest, InformationResponse } from "domain/entity/Information";
import InformationRepository from "domain/repository/Information";

export default class InformationAPI implements InformationRepository {
    async getInformation(p: InformationRequest): Promise<InformationResponse> {
        const param = argsToParams(p)
        console.log('param', param)
        return get(`/information/list?${param}`)
    }
}