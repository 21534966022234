import React from "react";
import { get } from "lodash";
import { GlobalContext } from "config/context/GlobalContext";
import { en, id } from "assets/lang";

const useLanguage = () => {
    const [{ language }] = React.useContext(GlobalContext);

    const t = (key?: string) => {
        if (typeof key === "undefined") return;
        switch (language) {
            case "en":
                return get(en, key, key);
            case "id":
            default:
                return get(id, key, key);
        }
    };

    return { t };
};

export default useLanguage;
