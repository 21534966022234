import * as yup from 'yup'
import { PASSWORD_COMBINATION, MINIMUM_PASSWORD_LENGTH } from 'presentation/validation/constants'

const passwordValidation =
    yup.string()
        .min(MINIMUM_PASSWORD_LENGTH, "validation.password_short")
        .test({
            name: "combination",
            message: "validation.password_combination",
            test: (value) => {
                return !Boolean(value && !PASSWORD_COMBINATION.test(value))
            }
        })

export default passwordValidation