import useChangePinViewModel from "presentation/pages/viewmodel/change-pin";
import ChangePinPage from "presentation/pages/view/change-pin/ChangePinPage";
import PinApi from "data/Pin";
import PinUseCase from "domain/interactor/Pin";
import ForgotPinUseCase from "domain/interactor/Auth/ForgotPinUseCase";
import AuthApi from "data/Auth";
import OTPUseCase from "domain/interactor/OTP";
import OTPApi from "data/OTP";

const useCase = new PinUseCase(new PinApi());
const otpUseCase = new OTPUseCase(new OTPApi());

export default function ChangePin() {
    const viewModel = useChangePinViewModel({
        useCase,
        otpUseCase,
    });

    return <ChangePinPage viewModel={viewModel} />;
}
