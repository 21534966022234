import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import passwordValidation from "presentation/validation/password";
import { useNavigate, useLocation } from "react-router-dom";
import {
    isPasswordEquals,
    isPasswordFormatValid,
    isPasswordLengthValid,
    passwordHelperData,
    passwordHelperDataValidate,
} from "config/shared/utils/password";
import log from "config/log";
import ForgotPasswordUseCase from "domain/interactor/Auth/ForgotPasswordUseCase";
import ForgotPasswordRequest from "domain/entity/Auth/model/FormForgotPassword";
import PasswordUseCase from "domain/interactor/Password";
import { useMutation } from "react-query";
import { ChangePasswordRequest } from "domain/entity/Password";
import { GlobalContext } from "config/context/GlobalContext";
import useLanguage from "config/hooks/useLanguage";

const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
};

type ChangePasswordUseCases = {
    changeUseCase: PasswordUseCase;
    forgotUseCase: ForgotPasswordUseCase;
};

const useChangePasswordViewModel = ({
    changeUseCase,
    forgotUseCase,
}: ChangePasswordUseCases) => {
    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const location = useLocation();

    const { t } = useLanguage();

    const navigate = useNavigate();

    const [success, setSuccess] = React.useState(false);

    const [usingOldPassword, setUsingOldPassword] = React.useState(false);

    const [showPassword, setShowPassword] = React.useState(false);

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const [showOldPassword, setShowOldPassword] = React.useState(false);

    const [showOTP, setShowOTP] = React.useState(false);

    const forgotPasswordArgs =
        React.useRef<{ otp: string; username: string, method: string } | null>(null);

    const { mutateAsync: changePassword } = useMutation(
        "/password/change",
        (body: ChangePasswordRequest) => changeUseCase.postChangePassword(body)
    );

    const {
        mutateAsync: postForgotPassword,
        isLoading: forgotPasswordLoading,
    } = useMutation("forgot-password", (body: ForgotPasswordRequest) =>
        forgotUseCase.execute(body)
    );

    const validationSchema = yup.object({
        oldPassword: usingOldPassword
            ? yup.string().required("validation.old_password_required")
            : yup.string(),
        password: passwordValidation,
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Password tidak sama"),
    });

    const {
        formState: { errors },
        register,
        handleSubmit,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const [_password, _confirmPassword] = watch([
        "password",
        "confirmPassword",
    ]);

    const showPasswordHelper = _password !== "";

    if (showPasswordHelper) {
        /**
         * harcoded index
         *  {0} - password length
         *  {1} - password format
         *  {2} - same password and confirm
         */

        passwordHelperData[0].status = isPasswordLengthValid(_password);
        passwordHelperData[1].status = isPasswordFormatValid(_password);
        passwordHelperData[2].status = isPasswordEquals(
            _password,
            _confirmPassword
        );
    }

    const submitButtonStatus = React.useMemo(() => {
        return passwordHelperDataValidate(passwordHelperData);
    }, [_password, _confirmPassword]);

    const onSubmit = (data: typeof initialValues) => {
        if (usingOldPassword) {
            /** old password kalo ganti password dari profile */

            if (!user || !user?.userId) {
                log("gak ada user id nya bro");
                return;
            }

            const { password, oldPassword } = data;

            changePassword({
                userId: user.userId,
                newPassword: password,
                oldPassword: oldPassword,
            })
                .then(() => {
                    setSuccess(true);
                })
                .catch((err) => {
                    const { response = null } = err as any;

                    if (response && response.data) {
                        const { message, rc } = response.data;

                        dispatch({
                            type: "SHOW_OVERLAY",
                            payload: {
                                header: t("forgot_pass.label5"),
                                subHeader: t("rc." + rc),
                                type: "warning",
                            },
                        });
                    }
                });
        } else {
            /** kalau ganti password dari forgot password */
            if (!forgotPasswordArgs.current) return;

            const { otp, username, method } = forgotPasswordArgs.current;

            postForgotPassword({
                otp,
                username,
                password: _password,
                method
            })
                .then(() => {
                    setSuccess(true);
                })
                .catch((err) => {
                    const { response } = err;

                    if (response && response.data) {
                        const { message, rc } = response.data;

                        const cb = () => {
                            navigate("/forgot-password");
                        };

                        dispatch({
                            type: "SHOW_OVERLAY",
                            payload: {
                                header: t("auth.forgot_password"),
                                subHeader: t("rc." + rc),
                                btnText: "OK",
                                type: "warning",
                                onClick: cb,
                            },
                        });
                    }
                });
        }
    };

    const handleSuccessRedirect = () => {
        const parentRedirect = location.state.parent as any;

        log(["redirect from parent:", parentRedirect]);

        navigate(parentRedirect);
    };

    const onVerificationBack = () => { };

    React.useEffect(() => {
        if (!Boolean(location.state)) {
            log("you shall not pass");
            navigate("/");
        } else {
            const { parent, data } = location.state;
            if (parent === "/dashboard/profile") {
                setUsingOldPassword(true);
            }

            /** ini digunakan kalau change-password diakses dari menu forgot-password */
            forgotPasswordArgs.current = {
                otp: data?.otp || "",
                username: data?.username || "",
                method: data?.method || ""
            };
        }
    }, [location.state]);

    return {
        errors,
        handleSubmit,
        register,
        onSubmit,
        showConfirmPassword,
        setShowPassword,
        showPassword,
        setShowConfirmPassword,
        showPasswordHelper,
        submitButtonStatus,
        passwordHelperData,
        success,
        handleSuccessRedirect,
        usingOldPassword,
        showOldPassword,
        setShowOldPassword,
        showOTP,
        onVerificationBack,
        forgotPasswordLoading,
    };
};

export type ChangePasswordViewModel = ReturnType<typeof useChangePasswordViewModel>;

export default useChangePasswordViewModel;
