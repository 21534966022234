import React from "react";
import useLanguage from "config/hooks/useLanguage";
import { MenuItem, Stack, Typography } from "@mui/material";
import { TransferUangViewModel } from "presentation/pages/viewmodel/transfer-uang";
import ButtonRemid from "core/components/remid-button/Button";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import { Controller } from "react-hook-form";

type FormLokasiPengirimanUangType = {
    viewModel: TransferUangViewModel;
};

const FormLokasiPengirimanUang: React.FC<FormLokasiPengirimanUangType> = ({
    viewModel,
}) => {
    const { t } = useLanguage();

    const { handleSubmit2, onSubmit2, register2, errors2, control2, merchantList } =
        viewModel;

    return (
        <form onSubmit={handleSubmit2(onSubmit2)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "1.25rem",
                        textAlign: "start",
                        width: "100%",
                    }}
                >
                    {t("transaction.money_deposit_locations")}
                </Typography>
                <Controller
                    name="localityOfSender"
                    control={control2}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            fullWidth
                            shrink
                            select
                            label={t("confirm_identity.country")}
                            error={Boolean(errors2.localityOfSender)}
                            helperText={t(errors2.localityOfSender?.message)}
                        >
                            <MenuItem value="1">Indonesia</MenuItem>
                        </TextFieldRemid>
                    )}
                />
                <Controller
                    name="sourceOfSender"
                    control={control2}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            fullWidth
                            shrink
                            select
                            label={t("transaction.store_name")}
                            error={Boolean(errors2.sourceOfSender)}
                            helperText={t(errors2.sourceOfSender?.message)}
                        >
                            {Array.isArray(merchantList?.data)
                                ? merchantList?.data.map((item) => {
                                    return (
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    );
                                })
                                : []}
                            {/* <MenuItem value="2">Alfamart</MenuItem> */}
                        </TextFieldRemid>
                    )}
                />

                
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "1.25rem",
                        textAlign: "start",
                        width: "100%",
                    }}
                >
                    {t("transaction.money_withdrawal_locations")}
                </Typography>
                <Controller
                    name="localityOfReceiver"
                    control={control2}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            fullWidth
                            shrink
                            select
                            label={t("confirm_identity.country")}
                            error={Boolean(errors2.localityOfReceiver)}
                            helperText={t(errors2.localityOfReceiver?.message)}
                        >
                            <MenuItem value="1">Indonesia</MenuItem>
                        </TextFieldRemid>
                    )}
                />
                <Controller
                    name="sourceOfReceiver"
                    control={control2}
                    render={({ field }) => (
                        <TextFieldRemid
                            {...field}
                            fullWidth
                            shrink
                            select
                            label={t("transaction.store_name")}
                            error={Boolean(errors2.sourceOfReceiver)}
                            helperText={t(errors2.sourceOfReceiver?.message)}
                        >
                            {Array.isArray(merchantList?.data)
                                ? merchantList?.data.map((item) => {
                                    return (
                                        <MenuItem
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    );
                                })
                                : []}
                            {/* <MenuItem value="2">Alfamart</MenuItem> */}
                        </TextFieldRemid>
                    )}
                />
                <ButtonRemid disabled={viewModel.loadingInsert} type="submit">
                    {t("general.send")}
                </ButtonRemid>
            </Stack>
        </form>
    );
};

export default FormLokasiPengirimanUang;
