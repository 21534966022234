import ChangePassword from "presentation/pages/view/change-password";
import ChangePin from "presentation/pages/view/change-pin";
import SendOtpToPage from "presentation/pages/view/send-otp-to/SendOtpToPage";
import TermOfService from "presentation/pages/view/term-of-service";
import Information from "presentation/pages/view/information";
import { RouteObject } from "react-router-dom";

const UtilRoutes: RouteObject[] = [
    {
        path: "/change-pin",
        element: <ChangePin />,
    },
    {
        path: "/change-password",
        element: <ChangePassword />,
    },
    {
        path: "/tos",
        element: <TermOfService />,
    },
    {
        path: "/info",
        element: <Information />,
    },
    {
        path: "/send-otp-to",
        element: <SendOtpToPage />
    }
];

export default UtilRoutes;
