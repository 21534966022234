import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import ButtonRemid from "core/components/remid-button";
import TextFieldRemid from "core/components/remid-textfield";
import { ForgotPinViewModel } from "presentation/pages/viewmodel/forgot-pin";
import useLanguage from "config/hooks/useLanguage";

type FormForgotPinProps = {
    viewModel: ForgotPinViewModel;
};

const FormForgotPin: React.FC<FormForgotPinProps> = ({ viewModel }) => {
    const { t } = useLanguage();

    const { palette } = useTheme();

    const {
        submitButtonStatus
        , register
        , handleSubmit
        , onSubmit
        , errors
    } = viewModel;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: "1.25rem",
                        width: "100%",
                    }}
                >
                    {t("forgot_pass.label6")}
                </Typography>
                <TextFieldRemid
                    {...register("username")}
                    fullWidth
                    shrink
                    label={t("auth.username")}
                    error={Boolean(errors.username)}
                    helperText={t(errors.username?.message)}
                />
                <Typography sx={{ fontSize: ".62rem" }}>
                    {t("forgot_pass.label2")}&nbsp;
                    <Typography
                        component="span"
                        fontSize="inherit"
                        sx={{ color: palette.primary.main }}
                    >
                        {t("forgot_pass.label3")}&nbsp;
                    </Typography>
                    <Typography component="span" fontSize="inherit">
                        {t("forgot_pass.label4")}
                    </Typography>
                </Typography>
                <ButtonRemid
                    type="submit"
                    // loading={viewModel.isLoading}
                    disabled={!submitButtonStatus}
                >
                    {t("general.send")}
                </ButtonRemid>
            </Stack>
        </form>
    );
};

export default FormForgotPin;
