import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as IdentityCard } from "assets/svg/IdentityCard.svg";
import { ReactComponent as ScanIdentity } from "assets/svg/KTP.svg";
import { ReactComponent as SelfieWithIdentity } from "assets/svg/SelfieKTP.svg";
import { identityNumberValidation } from "presentation/validation";
import log from "config/log";
import neighborhoodFormatter from "config/shared/utils/neighborhood-formatter";
import GlobalResourceUseCase from "domain/interactor/GlobalResource";
import { GlobalContext } from "config/context/GlobalContext";
import { useMutation, useQuery } from "react-query";
import { AktivasiKTPForm, AktivasiKTPRequest } from "domain/entity/AktivasiKTP";
import AktivasiKTPUseCase from "domain/interactor/AktivasiKTP";
import getFileFromBase64 from "config/shared/utils/base64-to-file";
import { removeDot } from "config/shared/utils/remove-dot.index";
import useLanguage from "config/hooks/useLanguage";
import { dummyCountry } from "./dummy";
import { useSnackbar } from "config/hooks/useSnackbar";
import { clearLocalStorage } from "config/storage";

type CaptureFlag = "KTP" | "SELFIE" | "DONE";

const initialValues = {
    sameDomisiliCheckBox: true,

    kewarganegaraan: "WNI",
    negara: "INDONESIA",
    idCard: "",
    nama: "",
    tempatLahir: "",
    tanggalLahir: "",
    jenisKelamin: "",
    provinceId: 0,
    cityId: 0,
    subDistrictId: 0,
    villageId: 0,
    neighborhood: "",
    alamat: "",
    domisiliProvinceId: 0,
    domisiliCityId: 0,
    domisiliSubDistrictId: 0,
    domisiliVillageId: 0,
    domisiliNeighborhood: "",
    alamatDomisili: "",
    professionId: 0,
    namaPerusahaan: "",
    bidangPerusahaan: 0,
    jabatan: 0,
    alamatKantor: "",
    noTeleponKantor: "",
    pendapatanBulanan: "",
};

const validationSchema = yup.object().shape({
    sameDomisiliCheckBox: yup.boolean(),

    kewarganegaraan: yup.string().required("validation.citizenship_required"),
    negara: yup.string().required("validation.country_required"),
    idCard: identityNumberValidation,
    nama: yup.string().required("validation.fullname_required"),
    tempatLahir: yup.string().required("validation.pob_required"),
    tanggalLahir: yup
        .date()
        .required("validation.dob_required")
        .typeError("validation.dob_required"),
    jenisKelamin: yup.string().required("validation.gender_required"),
    provinceId: yup.number().required("validation.province_required"),
    cityId: yup.number().required("validation.district_required"),
    subDistrictId: yup.number().required("validation.subdistrict_required"),
    villageId: yup.number().required("validation.village_required"),
    neighborhood: yup
        .string()
        .required("validation.neighborhood_required")
        .matches(
            /^[0-9]{3}\/[0-9]{3}$/,
            "Format RT/RW tidak valid (contoh: 001/001)"
        ),
    alamat: yup.string().required("validation.address_required"),
    domisiliProvinceId: yup.number().when("sameDomisiliCheckBox", {
        is: false,
        then: (schema) => schema.required("validation.province_required"),
    }),
    domisiliCityId: yup.number().when("sameDomisiliCheckBox", {
        is: false,
        then: (schema) => schema.required("validation.district_required"),
    }),
    domisiliSubDistrictId: yup.number().when("sameDomisiliCheckBox", {
        is: false,
        then: (schema) => schema.required("validation.subdistrict_required"),
    }),
    domisiliVillageId: yup.number().when("sameDomisiliCheckBox", {
        is: false,
        then: (schema) => schema.required("validation.village_required"),
    }),
    domisiliNeighborhood: yup.string().when("sameDomisiliCheckBox", {
        is: false,
        then: (schema) => schema.required("validation.neighborhood_required"),
    }),
    alamatDomisili: yup.string().when("sameDomisiliCheckBox", {
        is: false,
        then: (schema) => schema.required("validation.address_required"),
    }),
    professionId: yup.number().required("validation.occupation_required"),
    namaPerusahaan: yup.string().when("professionId", {
        is: (value: string) => {
            return (
                Number(value) !== 1 && Number(value) !== 2 && Number(value) !== 3
            );
        },
        then: yup.string().required("validation.company_required"),
    }),
    alamatKantor: yup.string().when("professionId", {
        is: (value: string) => {
            return (
                Number(value) !== 1 && Number(value) !== 2 && Number(value) !== 3
            );
        },
        then: yup.string().required("validation.companyaddr_required"),
    }),
    noTeleponKantor: yup.string().optional(),
    pendapatanBulanan: yup.string().when("professionId", {
        is: (value: string) => {
            return (
                Number(value) !== 1 && Number(value) !== 2 && Number(value) !== 3
            );
        },
        then: yup.string().required("validation.monthlyincome_required"),
    }),
});

type ConfirmationUseCases = {
    aktivasiKTPUseCase: AktivasiKTPUseCase;
    globalResourceUseCase: GlobalResourceUseCase;
};

const ADDRESSES = ["provinceId", "cityId", "subDistrictId", "villageId", "neighborhood"] as const

const DOMISILIADDRESSES = ["domisiliProvinceId", "domisiliCityId", "domisiliSubDistrictId", "domisiliVillageId", "domisiliNeighborhood"] as const

const useConfirmIdentityViewModel = ({
    globalResourceUseCase,
    aktivasiKTPUseCase,
}: ConfirmationUseCases) => {
    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const navigate = useNavigate();

    const { t } = useLanguage();

    const location = useLocation();

    const [openCamera, setOpenCamera] = React.useState(false);

    const [imageData, setImageData] = React.useState<string | null>(null);

    const ktpImageRef = React.useRef<File | null>(null);

    const selfieImageRef = React.useRef<File | null>(null);

    const [stepIndex, setStepIndex] = React.useState(0);

    const [showForm, setShowForm] = React.useState(false);

    const [success, setSuccess] = React.useState(false);

    const [refillData, setRefillData] = React.useState(null);

    const { showMessage } = useSnackbar();

    const captureFlag: CaptureFlag =
        stepIndex === 1 ? "KTP" : stepIndex === 2 ? "SELFIE" : "DONE";

    const facingMode: "user" | "environment" =
        captureFlag === "KTP" ? "environment" : "user";

    const btnGuideText = stepIndex === 0 ? "Lanjut" : "Mulai";

    const contentItems = [
        {
            icon: <IdentityCard />,
            header: "Aktivasi KTP Anda",
            subHeader:
                "Kami menggunakan foto KTP untuk membandingkan dan memverifikasi foto KTP dan foto selfie Anda.",
            alert: "Data yang Anda masukkan dijamin keamanannya",
        },
        {
            icon: <ScanIdentity />,
            header: "Foto KTP",
            subHeader: "Foto KTP Anda dengan jelas dan pencahayaan yang cukup.",
        },
        {
            icon: <SelfieWithIdentity />,
            header: "Foto Selfie dengan KTP",
            subHeader:
                "Foto KTP Anda dengan wajah yang terlihat jelas dan pencahayaan yang cukup.",
        },
    ];

    const handleIncrementStep = () => setStepIndex((value) => value + 1);

    const handleDecrementStep = () => {
        if (imageData) {
            setImageData(null);
        } else if (openCamera) {
            setOpenCamera(false);
        } else if (showForm) {
            setShowForm(false);
        } else if (stepIndex > 0) {
            setStepIndex((value) => value - 1);
        } else {
            navigate(-1);
        }
    };

    const handleActionClick = () => {
        if (captureFlag !== "DONE") {
            setOpenCamera(true);
        } else {
            handleIncrementStep();
        }
    };

    const handleCapture = (imgData: string | null) => {
        setImageData(imgData);
        setOpenCamera(false);

        if (captureFlag === "KTP") {
            const date = new Date();
            const fileName = `KTP${date.getFullYear()}${date.getMonth()}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;

            ktpImageRef.current = getFileFromBase64(imgData, fileName);
        } else if (captureFlag === "SELFIE") {
            const date = new Date();
            const fileName = `SELFIE${date.getFullYear()}${date.getMonth()}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;

            selfieImageRef.current = getFileFromBase64(imgData, fileName);
        }
    };

    const closeCamera = () => setOpenCamera(false);

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        watch,
        setValue,
        getValues,
        setError,
        clearErrors
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
        mode: "onTouched",
    });

    React.useEffect(() => {
        console.log('form err: ', errors)
    })

    // this one for clearning addresses
    const clearFields = (start: number, domisili?: boolean) => {
        // lenght ADDRESSES & DOMISILIADDRESS sama
        for (let i = start; i < ADDRESSES.length; i++) {
            setValue(domisili ? DOMISILIADDRESSES[i] : ADDRESSES[i], "");
        }
    };

    const { mutateAsync: postKTP, isLoading: ktpLoading } = useMutation(
        "aktivasi-ktp",
        (body: AktivasiKTPRequest) => aktivasiKTPUseCase.postKTP(body)
    );

    const levelStatus = location?.state?.levelStatus;

    // console.log('level', levelStatus)
    // console.log('dor! ambil datanya dong', getValues())

    const {
        mutateAsync: postKTPRefill,
        isLoading: loadingKTPRefill
    } = useMutation('/fulfillment/refill', () =>
        aktivasiKTPUseCase.postKTPRefill()
    );

    const submitImage = () => {
        if (stepIndex === 2) {
            const form = getValues();

            const {
                neighborhood,
                domisiliNeighborhood,
                sameDomisiliCheckBox,
                pendapatanBulanan,
                ...readyForm
            } = form;

            if (!user || !user?.userId) return;

            let account: AktivasiKTPForm = {
                userId: user.userId.toString(),
                phone: user?.phone ?? "",
                email: user?.email ?? "",
                rt: "",
                rw: "",
                domisiliRt: "",
                domisiliRw: "",
                pendapatanBulanan: removeDot(pendapatanBulanan),
                ...readyForm,
            };

            /**
             * handle submit here
             *
             *  - format pendapatan bulanan hapus  <.>
             *  - cek flag domisili
             */

            const splitNeighboorHood = neighborhood.split("/");

            const splitDomisiliNeighboorhood = domisiliNeighborhood.split("/");

            if (splitNeighboorHood.length === 2) {
                account.rt = splitNeighboorHood[0];
                account.rw = splitNeighboorHood[1];
            }

            if (splitDomisiliNeighboorhood.length === 2) {
                account.domisiliRt = splitDomisiliNeighboorhood[0];
                account.domisiliRw = splitDomisiliNeighboorhood[1];
            }

            if (_sameDomisiliCheckBox) {
                account.domisiliRt = account.rt;
                account.domisiliRw = account.rw;
                account.domisiliProvinceId = account.provinceId;
                account.domisiliCityId = account.cityId;
                account.domisiliSubDistrictId = account.subDistrictId;
                account.domisiliVillageId = account.villageId;
                account.alamatDomisili = account.alamat;
            }

            account.tanggalLahir = dayjs(account.tanggalLahir).format(
                "YYYY-MM-DD"
            );

            if (ktpImageRef.current && selfieImageRef.current) {
                console.log(account)
                postKTP({
                    ktp: ktpImageRef.current,
                    selfie: selfieImageRef.current,
                    account,
                })
                    .then(() => {
                        setSuccess(true);
                        dispatch({
                            type: "SAVE_USER",
                            payload: { ...user, isAccountVerified: "1" },
                        });
                    })
                    .catch((err) => {
                        let msg = "Error";
                        let rcmsg = "00";
                        const { response } = err;
                        log(err);
                        if (response && response.data) {
                            const { message, rc } = response.data;
                            msg = message
                            rcmsg = rc
                            if (rc === "0094") {
                                const message = t("rc." + rc);
                                setError("idCard", { message });
                            }
                            if (rc === "0058") {
                                dispatch({
                                    type: "SHOW_OVERLAY",
                                    payload: {
                                        header: "Informasi Akun",
                                        subHeader: "Mohon maaf akun Anda belum dapat diakses, silahkan menghubungi customer service kami 021-50127667.",
                                        type: "warning",
                                        onClick: () => {
                                            clearLocalStorage();
                                            window.location.href = "/";
                                        },
                                    },
                                });
                            }
                            setShowForm(true);
                            setStepIndex(1);
                        }
                        if (rcmsg !== "0058")
                            showMessage(msg, "error")
                    });
            }
        }

        if (stepIndex === 1) setShowForm(true);

        setOpenCamera(false);
        setImageData(null);
    };

    const { onChange: onNeighborhoodChange, ...neighborhoodRegister } =
        register("neighborhood");

    const handleNeighborhoodChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.target.value = neighborhoodFormatter(e.target.value);
        onNeighborhoodChange(e);
    };

    const { onChange: onNeighborhoodChange2, ...neighborhoodRegister2 } =
        register("domisiliNeighborhood");

    const handleNeighborhoodChange2 = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.target.value = neighborhoodFormatter(e.target.value);
        onNeighborhoodChange2(e);
    };

    const [countryList, setCountryList] = React.useState([...dummyCountry]);

    const _citizenship = watch("kewarganegaraan");

    const _job = watch("professionId");

    /**
     * Job 1 -> Pengacara(Pengangguran Banyak Acara)
     * job 2 -> student
     * job 3 -> ibu rumah tetangga
     */

    const showExtraField = (_job) !== 1 &&
        (_job) !== 2 &&
        (_job) !== 3;

    // React.useEffect(() => {
    //     if (!showExtraField) clearErrors()
    // }, [showExtraField])

    const _sameDomisiliCheckBox = watch("sameDomisiliCheckBox");

    const disableCountry = _citizenship === "WNI";

    const _province = watch("provinceId");

    const _district = watch("cityId");

    const _subDistrict = watch("subDistrictId");

    const _village = watch("villageId");

    const _domisiliProvinceId = watch("domisiliProvinceId");

    const _domisiliCityId = watch("domisiliCityId");

    const _domisiliSubDistrictId = watch("domisiliSubDistrictId");

    const _domisiliVillageId = watch("domisiliVillageId");

    const {
        data: listTempatLahir,
        mutateAsync: fetchTempatLahir,
        isLoading: loadingTempatLahir,
    } = useMutation(["tempat-lahir"], (labelName: string) =>
        globalResourceUseCase.getDistricts({ labelName }).then((item) => {
            return {
                ...item,
                data: item.data.map(({ labelName }) => {
                    return {
                        id: labelName,
                        labelName,
                    };
                }),
            };
        })
    );

    const testCb = (str: string) => {
        console.log("autoComplete cb: ", str);
    };

    const { data: listProvinsi } = useQuery("provinsi", () =>
        globalResourceUseCase.getProvince("1")
    ); // dihardcode 1 dulu untuk negara indonesia

    const { data: listKabupaten, refetch: refetchKabupaten } = useQuery(
        ["districts", _province],
        () => globalResourceUseCase.getDistricts({ id: _province }),
        { enabled: !!_province }
    );

    const { data: listKecamatan, refetch: refetchKecamatan } = useQuery(
        ["subdistricts", _district],
        () => globalResourceUseCase.getSubDistricts(_district),
        { enabled: !!_district }
    );

    const { data: listDesa, refetch: refetchDesa } = useQuery(
        ["villages", _subDistrict],
        () => globalResourceUseCase.getVillages(_subDistrict),
        { enabled: !!_subDistrict }
    );

    const { data: listDomisiliKabupaten, refetch: refetchDomisiliKabupaten } =
        useQuery(
            ["domisili-districts", _domisiliProvinceId],
            () =>
                globalResourceUseCase.getDistricts({ id: _domisiliProvinceId }),
            { enabled: !!_domisiliProvinceId }
        );

    const { data: listDomisiliKecamatan, refetch: refetchDomisiliKecamatan } =
        useQuery(
            ["domisili-subdistricts", _domisiliCityId],
            () => globalResourceUseCase.getSubDistricts(_domisiliCityId),
            { enabled: !!_domisiliCityId }
        );

    const { data: listDomisiliDesa, refetch: refetchDomisiliDesa } = useQuery(
        ["domisili-villages", _domisiliSubDistrictId],
        () => globalResourceUseCase.getVillages(_domisiliSubDistrictId),
        { enabled: !!_domisiliSubDistrictId }
    );

    const { data: listPekerjaan } = useQuery("list-pekerjaan", () =>
        globalResourceUseCase.getJobs()
    );

    const { data: listJabatan } = useQuery("employee-position", () =>
        globalResourceUseCase.getPosition()
    );

    const { data: listBidangUsaha } = useQuery("business-field", () =>
        globalResourceUseCase.getBusinessFields()
    );

    React.useEffect(() => {
        console.log('refill')
        const fetchData = async () => {
            try {
                const result = await postKTPRefill();
                const personalData = result.data;

                console.log('data refill', personalData)
                setValue("idCard", personalData.idCard)
                setValue("nama", personalData.nama)
                setValue("tempatLahir", personalData.tempatLahir)
                if (personalData.tanggalLahir)
                    setValue("tanggalLahir", (dayjs(personalData.tanggalLahir) as any))
                setValue("jenisKelamin", personalData.jenisKelamin)

                setValue("alamat", personalData.alamat)
                setValue("provinceId", personalData.provinceId);
                setValue("cityId", personalData.cityId);
                setValue("subDistrictId", personalData.subDistrictId);
                setValue("villageId", personalData.villageId);
                setValue("neighborhood", personalData.rt + "/" + personalData.rw);

                // dari erik tidak simpan flagnya jadi ndak ada balikannya
                if (personalData.alamat === personalData.alamatDomisili) {
                    // If they are the same, set "sameDomisiliCheckBox" to true
                    setValue("sameDomisiliCheckBox", true);
                } else {
                    // If they are different, set "sameDomisiliCheckBox" to false
                    setValue("sameDomisiliCheckBox", false);
                }

                setValue("alamatDomisili", personalData.alamatDomisili)
                setValue("domisiliProvinceId", personalData.domisiliProvinceId);
                setValue("domisiliCityId", personalData.domisiliCityId);
                setValue("domisiliSubDistrictId", personalData.domisiliSubDistrictId);
                setValue("domisiliVillageId", personalData.domisiliVillageId);
                setValue("domisiliNeighborhood", personalData.domisiliRt + "/" + personalData.domisiliRw);

                setValue("professionId", personalData.professionId);
                setValue("namaPerusahaan", personalData.namaPerusahaan ?? "");
                setValue("bidangPerusahaan", personalData.bidangPerusahaan);
                setValue("jabatan", personalData.jabatan);
                setValue("alamatKantor", personalData.alamatKantor ?? "");
                setValue("noTeleponKantor", personalData.noTeleponKantor ?? "");
                setValue("pendapatanBulanan", personalData.pendapatanBulanan);
            } catch (error) {
                // Handle error
                console.error('Error fetching refill data:', error);
            }
        };

        console.log('dan levelnya adalah?', levelStatus)
        // Fetch data only when levelStatus is "rejected"
        if (levelStatus === 'Reject') {
            fetchData();
        }
    }, [levelStatus]);

    // React.useEffect(() => {
    //     if (_province !== "") refetchKabupaten();

    //     setValue("cityId", "");
    //     setValue("subDistrictId", "");
    //     setValue("villageId", "");
    //     setValue("neighborhood", "");
    // }, [setValue, _province]);

    // React.useEffect(() => {
    //     if (_domisiliProvinceId !== "") refetchDomisiliKabupaten();

    //     setValue("domisiliCityId", "");
    //     setValue("domisiliSubDistrictId", "");
    //     setValue("domisiliVillageId", "");
    //     setValue("domisiliNeighborhood", "");
    // }, [setValue, _domisiliProvinceId]);

    // React.useEffect(() => {
    //     if (_district !== "") refetchKecamatan();

    //     setValue("subDistrictId", "");
    //     setValue("villageId", "");
    //     setValue("neighborhood", "");
    // }, [setValue, _district]);

    // React.useEffect(() => {
    //     if (_domisiliCityId !== "") refetchDomisiliKecamatan();

    //     setValue("domisiliSubDistrictId", "");
    //     setValue("domisiliVillageId", "");
    //     setValue("domisiliNeighborhood", "");
    // }, [setValue, _domisiliCityId]);

    // React.useEffect(() => {
    //     if (_subDistrict !== "") refetchDesa();

    //     setValue("villageId", "");
    //     setValue("neighborhood", "");
    // }, [setValue, _subDistrict]);

    // React.useEffect(() => {
    //     if (_domisiliSubDistrictId !== "") refetchDomisiliDesa();

    //     setValue("domisiliVillageId", "");
    //     setValue("domisiliNeighborhood", "");
    // }, [setValue, _domisiliSubDistrictId]);

    // React.useEffect(() => {
    //     setValue("neighborhood", "");
    // }, [setValue, _village]);

    // React.useEffect(() => {
    //     setValue("domisiliNeighborhood", "");
    // }, [setValue, _domisiliVillageId]);

    const onFormSubmit = () => {
        handleIncrementStep();
        setShowForm(false);
    };

    const redirectToHome = () => {
        navigate("/dashboard/home");
    };

    return {
        stepIndex,
        handleDecrementStep,
        handleActionClick,
        contentItems,
        btnGuideText,
        openCamera,
        imageData,
        setImageData,
        captureFlag,
        submitImage,
        showForm,
        facingMode,
        handleCapture,
        register,
        errors,
        handleSubmit,
        onFormSubmit,
        success,
        redirectToHome,
        disableCountry,
        countryList,
        control,
        setValue,
        _province,
        _district,
        _subDistrict,
        _village,
        handleNeighborhoodChange,
        neighborhoodRegister,
        _domisiliProvinceId,
        _domisiliCityId,
        _domisiliSubDistrictId,
        _domisiliVillageId,
        handleNeighborhoodChange2,
        neighborhoodRegister2,
        closeCamera,
        listProvinsi,
        listKabupaten,
        listKecamatan,
        listDesa,
        listDomisiliKabupaten,
        listDomisiliKecamatan,
        listDomisiliDesa,
        listPekerjaan,
        listJabatan,
        listBidangUsaha,
        _sameDomisiliCheckBox,
        showExtraField,
        listTempatLahir,
        testCb,
        fetchTempatLahir,
        loadingTempatLahir,
        ktpLoading,
        refillData,
        clearFields
    };
};

export type ConfirmIdentityViewModel = ReturnType<
    typeof useConfirmIdentityViewModel
>;

export default useConfirmIdentityViewModel;
