import React from 'react'
import { GlobalContext } from "config/context/GlobalContext";
import { SmsRounded } from "@mui/icons-material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { OTPRequest } from "domain/entity/OTP";
import { useMutation } from "react-query";
import OTPApi from "data/OTP";
import OTPUseCase from "domain/interactor/OTP";
import { OTPPayload, TimerObj } from "presentation/pages/viewmodel/auth-otp";
import useLanguage from "config/hooks/useLanguage";
import FormOTP, { FormOTPProps } from '../form-otp';

type OTPMethodList = Readonly<{
    sms?: string | null;
    email?: string | null;
    whatsapp?: string | null;
}>

type OTPMethod = keyof OTPMethodList | ""

export type FormSendToProps = {
    onSubmit?: Promise<any> | any;
    otpMethodList: OTPMethodList;
    type: string;
} & FormOTPProps;

const otpUseCase = new OTPUseCase(new OTPApi());

const FormSendTo: React.FC<FormSendToProps> = (props) => {
    const { t } = useLanguage();

    const [, dispatch] = React.useContext(GlobalContext);

    const { otpMethodList, type, ...formOTPProps } = props

    const [selectedMethod, setSelectedMethod] = React.useState<OTPMethod>("")

    const otpDataRef = React.useRef<OTPPayload | null>(null);

    const handleMethodSelect = async (method: OTPMethod) => {
        try {
            setSelectedMethod(method); // Call the provided function with the selected method

            const email = otpMethodList.email ?? '';
            const phone = otpMethodList.sms ?? '';

            const result = await postRegisterOTP({
                userId: "",
                email: email,
                phone: phone.replaceAll(" ", ""),
                method,
                type,
            })

            otpDataRef.current = {
                userId: "",
                email: email,
                phone: phone.replaceAll(" ", ""),
                method,
                type,
                sendWhere: method === "email" ? "email" : "phone",
            };

            /** hacky */
            localStorage.setItem(
                "otp",
                JSON.stringify({
                    register: {
                        blockCount: result?.data?.blockCount || 1,
                        blocked: false,
                        startTime: new Date().getTime(),
                    },
                } as TimerObj)
            );
        } catch (error) {
            const { response } = error as any;

            if (response && response.data) {
                const { message, rc } = response.data;
                if (rc === "0025") {
                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.register_error"),
                            subHeader: t("rc." + rc),
                            type: "warning",
                        },
                    });
                } else {
                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.register_error"),
                            subHeader: t("rc." + rc),
                            type: "warning",
                        },
                    });
                }
            }
        }
    };

    const obfuscatePhoneNumber = (value: any) => {
        if (value) {
            const tempPhone = value.split("");
            return tempPhone
                .map((e: any, idx: number): any => {
                    if (
                        idx === tempPhone.length - 1 ||
                        idx === tempPhone.length - 2
                    ) {
                        return e;
                    }
                    if ((idx + 1) % 3 === 0) return "*-";
                    return "*";
                })
                .join("");
        }
        return null;
    };

    const obfuscateEmailWithFormat = (value: any) => {
        if (value) {
            // Split the email into parts before and after the '@' symbol
            const [beforeAt, afterAt] = value.split('@');

            // Calculate the number of asterisks needed between the first and last characters
            const asterisksCount = beforeAt.length - 2;

            // Create the obfuscated first string with asterisks
            const obfuscatedFirst = beforeAt[0] + '*'.repeat(asterisksCount) + beforeAt.slice(-1);

            // Combine the obfuscated first string with the remaining part of the email
            const obfuscatedEmail = obfuscatedFirst + '@' + afterAt;

            return obfuscatedEmail;
        }
        return null;
    };

    const obfuscatedSMS = obfuscatePhoneNumber(otpMethodList.sms);
    const obfuscatedWhatsApp = obfuscatePhoneNumber(otpMethodList.whatsapp);
    const obfuscatedEmail = obfuscateEmailWithFormat(otpMethodList.email);

    const {
        mutateAsync: postRegisterOTP,
        isLoading: loadingOTP,
        isSuccess: successGenerateOTP,
    } = useMutation("send-otp", (body: OTPRequest) => otpUseCase.postOTP(body));

    return (
        (successGenerateOTP ?
            <FormOTP
                {...{
                    ...formOTPProps,
                    method: selectedMethod,
                    otpRequest: otpDataRef.current
                }}
            /> :
            <Stack alignItems="center" sx={{ textAlign: "center" }} spacing={3}>
                <Typography sx={{ fontWeight: 700 }}>
                    Pilih Metode Verifikasi
                </Typography>
                <Typography sx={{ fontSize: ".75rem" }}>
                    Pilih salah satu metode dibawah ini untuk
                    mendapatkan kode OTP
                </Typography>
                {
                    otpMethodList?.sms &&
                    <IconButton
                        sx={{
                            width: "100%",
                            borderRadius: 0,
                            justifyContent: "flex-start",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                        }}
                        onClick={() => handleMethodSelect("sms")}
                    >
                        <SmsRounded fontSize="medium" sx={{ color: '#EBA440', padding: '1rem' }} />
                        <Box sx={{ width: '100%', textAlign: 'justify' }}>
                            <Box sx={{ fontSize: '16px', fontWeight: '600' }}>SMS ke</Box>
                            <Box sx={{ fontSize: '16px', fontWeight: '400' }}>{obfuscatedSMS}</Box>
                        </Box>
                    </IconButton>
                }

                {
                    otpMethodList?.whatsapp &&
                    <IconButton
                        sx={{
                            width: "100%",
                            borderRadius: 0,
                            justifyContent: "flex-start",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                        }}
                        onClick={() => handleMethodSelect("whatsapp")}
                    >
                        <WhatsAppIcon fontSize="medium" sx={{ color: '#EBA440', padding: '1rem' }} />
                        <Box sx={{ width: '100%', textAlign: 'justify' }}>
                            <Box sx={{ fontSize: '16px', fontWeight: '600' }}>WhatsApp ke</Box>
                            <Box sx={{ fontSize: '16px', fontWeight: '400' }}>{obfuscatedWhatsApp}</Box>
                        </Box>
                    </IconButton>
                }

                {
                    otpMethodList?.email && type !== "register" &&
                    <IconButton
                        sx={{
                            width: "100%",
                            borderRadius: 0,
                            justifyContent: "flex-start",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                        }}
                        onClick={() => handleMethodSelect("email")}
                    >
                        <AlternateEmailIcon fontSize="medium" sx={{ color: '#EBA440', padding: '1rem' }} />
                        <Box sx={{ width: '100%', textAlign: 'justify' }}>
                            <Box sx={{ fontSize: '16px', fontWeight: '600' }}>E-mail ke</Box>
                            <Box sx={{ fontSize: '16px', fontWeight: '400' }}>{obfuscatedEmail}</Box>
                        </Box>
                    </IconButton>
                }
            </Stack>

        )
    );
};

export default FormSendTo;