import React from "react";
import { Stack, CircularProgress, Card, Box } from "@mui/material";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import { WithdrawUangViewModel } from "presentation/pages/viewmodel/withdraw-uang";
import ButtonRemid from "core/components/remid-button/Button";
import FieldDetailTransaksi from "config/components/field-detail-transaksi";
import currencyFormatter from "config/shared/utils/currency-formatter";
import { phoneObfuscate } from "config/shared/utils/obfuscate";
import useLanguage from "config/hooks/useLanguage";

type FormWithdrawUangProps = {
    viewModel: WithdrawUangViewModel;
};

const FormWithdrawUang: React.FC<FormWithdrawUangProps> = ({ viewModel }) => {

    const { t } = useLanguage();

    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
        timeZone: 'Asia/Jakarta'
    };

    const formatter = new Intl.DateTimeFormat('id-ID', options);

    const {
        errors,
        handleSubmit,
        onSubmit,
        onSubmit2,
        register,
        submitButtonStatus,
        isWithdrawInsertLoading,
        withdrawInsertData,
        isWithdrawInsertSuccess,
        isWithdrawMtcnSuccess,
        withdrawMtcnData,
        isWithdrawMtcnLoading,
    } = viewModel;

    const formattedDate = withdrawInsertData?.data?.DateTime ? formatter.format(new Date(withdrawInsertData?.data?.DateTime)) : '';

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <TextFieldRemid
                    {...register("mtcn")}
                    fullWidth
                    shrink
                    label={t("transaction.withdraw_mtcn_code")}
                    error={Boolean(errors.mtcn)}
                    helperText={t(errors.mtcn?.message)}
                    inputProps={{
                        inputMode: "numeric",
                    }}
                />

                {isWithdrawMtcnLoading && <CircularProgress />}

                {!withdrawMtcnData && !withdrawInsertData ? <></> : withdrawInsertData ? (
                    <Card sx={{ minWidth: "100%" }}>
                        <Box sx={{ p: ".5rem" }}>
                            <FieldDetailTransaksi
                                header="Status"
                                detailItems={[
                                    {
                                        label: "Order ID",
                                        value: withdrawInsertData?.data
                                            ?.DepositId,
                                        color: "secondary",
                                        bold: true,
                                    },
                                    {
                                        label: t("transaction.transaction_date"),
                                        value: formattedDate,
                                    },
                                    {
                                        label: t("transaction.money_amount"),
                                        value: currencyFormatter(
                                            withdrawInsertData?.data
                                                ?.WithdrawNominal || ""
                                        ),
                                        color: "primary",
                                    },
                                ]}
                            />
                        </Box>
                        <Box sx={{ p: ".5rem" }}>
                            <FieldDetailTransaksi
                                header="Detail"
                                detailItems={[
                                    {
                                        label: t("transaction.recipient_name"),
                                        value: withdrawInsertData?.data
                                            ?.WithdrawNama,
                                    },
                                    {
                                        label: t("transaction.recipient_no2"),
                                        value: phoneObfuscate(
                                            withdrawInsertData?.data
                                                ?.WithdrawNoHp || ""
                                        ),
                                    },
                                    {
                                        label: t("transaction.recipient_no3"),
                                        value:
                                            withdrawInsertData?.data
                                                ?.WithdrawId || "",
                                    },
                                ]}
                            />
                        </Box>
                        <Box sx={{ p: ".5rem" }}>
                            <FieldDetailTransaksi
                                header={t("transaction.money_deposit_locations")}
                                detailItems={[
                                    {
                                        label: t("confirm_identity.country"),
                                        value: withdrawInsertData?.data
                                            ?.WithdrawNamaNegara,
                                    },
                                    {
                                        label: t("transaction.store_name"),
                                        value: withdrawInsertData?.data
                                            ?.WithdrawNamaToko,
                                    },
                                ]}
                            />
                        </Box>
                    </Card>
                ) : (
                    <Stack sx={{ width: "100%" }} spacing={1}>
                        <TextFieldRemid
                            disabled={true}
                            value={withdrawMtcnData?.data.namaPenerima}
                            fullWidth
                            shrink
                            label={t("general.form_moneytf2")}
                        />
                        <TextFieldRemid
                            disabled={true}
                            value={withdrawMtcnData?.data.idCardPeneriima}
                            fullWidth
                            shrink
                            label={t("general.form_moneytf3")}
                        />
                        <TextFieldRemid
                            disabled={true}
                            value={withdrawMtcnData?.data.noHpPenerima}
                            fullWidth
                            shrink
                            label={t("transaction.recipient_no2")}
                        />
                        <TextFieldRemid
                            disabled={true}
                            value={withdrawMtcnData?.data.nominal}
                            fullWidth
                            shrink
                            label={t("transaction.r_money_amount")}
                        />
                    </Stack>
                )}

                {withdrawMtcnData == undefined ? (<ButtonRemid
                    loading={isWithdrawMtcnLoading}
                    disabled={submitButtonStatus || isWithdrawMtcnLoading}
                    type="submit"
                >
                    {t("auth.next_text")}
                </ButtonRemid>) :
                    withdrawInsertData == undefined ?
                        (<ButtonRemid onClick={viewModel.onSubmit2}
                            loading={isWithdrawInsertLoading}
                            disabled={submitButtonStatus || isWithdrawInsertLoading}
                        >
                            {t("general.withdraw")}
                        </ButtonRemid>) : (<ButtonRemid onClick={viewModel.onGenerateQRCode}
                            loading={isWithdrawInsertLoading}
                            disabled={submitButtonStatus || isWithdrawInsertLoading}
                        >
                            Generate QR Code
                        </ButtonRemid>)
                }

            </Stack>
        </form>
    );
};

export default FormWithdrawUang;
