import React from "react";
import ButtonRemid from "core/components/remid-button";
import { useTheme, Box, Stack, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import useLanguage from "config/hooks/useLanguage";

export type MessageProps = {
    header?: string;
    subHeader?: string | React.ReactNode;
    btnText?: string;
    notes?: string;
    negativeBtnText?: string;
    type?: "success" | "warning" | "failed";
    onClick?: () => void;
    onNegativeClick?: () => void;
};

const Message: React.FC<MessageProps> = (props) => {
    const { palette } = useTheme();

    const { t } = useLanguage();

    const {
        header,
        subHeader,
        btnText = t("auth.back"),
        notes,
        type,
        onClick,
        negativeBtnText,
        onNegativeClick,
    } = props;

    const handleClick = () => {
        onClick?.();
    };

    const handleNegativeClick = () => {
        onNegativeClick?.();
    };

    return (
        <Stack
            sx={{ textAlign: "center", height: "100%" }}
            spacing={2}
            alignItems="center"
            justifyContent="center"
        >
            {type === "success" && (
                <Box
                    sx={{
                        display: "inline-block",
                        color: palette.primary.main,
                    }}
                >
                    <CheckCircleRoundedIcon
                        color="inherit"
                        sx={{ fontSize: "8.5rem" }}
                    />
                </Box>
            )}
            {type === "failed" && (
                <Box
                    sx={{
                        display: "inline-block",
                        color: palette.primary.main,
                    }}
                >
                    <CancelRoundedIcon
                        color="inherit"
                        sx={{ fontSize: "8.5rem" }}
                    />
                </Box>
            )}
            {type === "warning" && (
                <Box
                    sx={{
                        display: "inline-block",
                        color: palette.secondary.main,
                    }}
                >
                    <WarningRoundedIcon
                        color="inherit"
                        sx={{ fontSize: "5.75rem" }}
                    />
                </Box>
            )}

            <Typography sx={{ fontWeight: 700 }}>{header}</Typography>

            {typeof subHeader === "string" ? (
                <Typography
                    sx={{
                        fontSize: ".75rem",
                        color: palette.text.secondary,
                    }}
                >
                    {subHeader}
                </Typography>
            ) : (
                <>{subHeader}</>
            )}

            {typeof notes === "string" ? (
                <Typography
                    sx={{
                        fontSize: ".75rem",
                        color: palette.text.secondary,
                        fontWeight: "600",
                    }}
                >
                    * {notes}
                </Typography>
            ) : (
                <>{notes}</>
            )}

            <ButtonRemid onClick={handleClick}>{btnText}</ButtonRemid>

            {negativeBtnText && (
                <ButtonRemid onClick={handleNegativeClick} variant="outlined">
                    {negativeBtnText}
                </ButtonRemid>
            )}
        </Stack>
    );
};

export default Message;
