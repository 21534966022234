import { Box, Card, CardContent, CardMedia, CircularProgress, Stack, Typography } from "@mui/material";
import { ListBannerViewModel } from "presentation/pages/viewmodel/information";
import LayoutAppBar from "config/components/layout-app-bar";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Margin } from "@mui/icons-material";
import { InformationResponseData } from "domain/entity/Information";

type InformationPageProps = {
    viewModel: ListBannerViewModel;
};

interface BannerItem {
    informationId: number;
    name: string;
    imageType: string;
    imageFile: string;
}

interface QRItem {
    informationId: number;
    name: string;
    imageType: string;
    imageFile: string;
}

const InformationPage: React.FC<InformationPageProps> = ({ viewModel }) => {
    const { bannerList, qrList } = viewModel;

    if (!bannerList) {
        // Data is still loading
        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}>
                <CircularProgress size={64} />
            </Box>
        )
    }

    if (!qrList) {
        // Data is still loading
        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}>
                <CircularProgress size={64} />
            </Box>
        )
    }

    if (!Array.isArray(bannerList.data)) {
        // Error fetching data
        return <Box>Error: Unable to fetch data</Box>;
    }

    if (!Array.isArray(qrList.data)) {
        // Error fetching data
        return <Box>Error: Unable to fetch data</Box>;
    }

    const bannerItems: BannerItem[] = Object.values(bannerList.data);
    const qrItems: QRItem[] = Object.values(qrList.data);

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "50px",
        autoplay: true, // Add this property for auto-play
        autoplaySpeed: 5000, // Set the interval in milliseconds
    };

    return (
        <LayoutAppBar
            title="Update Terbaru"
            primary="red"
        >
            {/* BUAT 2 SECTION */}
            <Stack spacing={3} sx={{}}>
                <Box>
                    <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                        Scan QR untuk mengakses Web Transferlink
                    </Typography>
                    <Box sx={{ textAlign: "center" }}>
                        {qrItems.map((item) => (
                            <img
                                src={`data:${item.imageType};base64,${item.imageFile}`}
                                width="50%"
                                alt="QR Code"
                            />
                        ))}
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                        Lihat yang terbaru dari Remid
                    </Typography>

                    <Slider {...carouselSettings}>
                        {bannerItems.map((item) => (
                            <Box key={item.informationId}>
                                <Card sx={{}}>
                                    <CardMedia
                                        component="img"
                                        image={`data:${item.imageType};base64,${item.imageFile}`}
                                        alt={item.name}
                                        style={{ maxWidth: '100%', height: 'auto', padding: 2 }}
                                    />
                                </Card>
                            </Box>
                        ))}
                    </Slider>
                </Box>
            </Stack>
        </LayoutAppBar>
    )
};

export default InformationPage;