import { GlobalResourceResponse } from "domain/entity";
import { ProductResponse } from "domain/entity/Product";
import { statusRequest, statusResponse } from "domain/entity/Status";
import GlobalResourceRepostiory from "domain/repository/GlobalResources";

export default class GlobalResourceUseCase implements GlobalResourceRepostiory {
    constructor(private repository: GlobalResourceRepostiory) { }

    getDepositTujuanKirim(): Promise<GlobalResourceResponse> {
        return this.repository.getDepositTujuanKirim()
    }

    getDepositSumberDana(): Promise<GlobalResourceResponse> {
        return this.repository.getDepositSumberDana()
    }

    getProduk(): Promise<ProductResponse> {
        return this.repository.getProduk()
    }

    getStatus({...rest}: statusRequest): Promise<statusResponse> {
        return this.repository.getStatus({...rest})
    }

    getProvince(id: string | number): Promise<GlobalResourceResponse> {
        return this.repository.getProvince(id)
    }
    getDistricts(p: { id?: string | number, labelName?: string }): Promise<GlobalResourceResponse> {
        return this.repository.getDistricts(p)
    }
    getSubDistricts(id: string | number): Promise<GlobalResourceResponse> {
        return this.repository.getSubDistricts(id)
    }
    getVillages(id: string | number): Promise<GlobalResourceResponse> {
        return this.repository.getVillages(id)
    }
    getJobs(): Promise<GlobalResourceResponse> {
        return this.repository.getJobs()
    }
    getPosition(): Promise<GlobalResourceResponse> {
        return this.repository.getPosition()
    }
    getBusinessFields(): Promise<GlobalResourceResponse> {
        return this.repository.getBusinessFields()
    }
}