import EmailApi from "data/Email";
import OTPApi from "data/OTP";
import EmailUseCase from "domain/interactor/Email";
import OTPUseCase from "domain/interactor/OTP";
import useChangeEmailViewModel from "presentation/pages/viewmodel/change-email";
import ChangeEmailPage from "./ChangeEmailPage";

// const api = new EmailApi();
// const useCase = new EmailUseCase(api);

const useCase = new EmailUseCase(new EmailApi());
const otpUseCase = new OTPUseCase(new OTPApi());

export default function ChangeEmail() {
    const viewModel = useChangeEmailViewModel({ emailUseCase: useCase, otpUseCase });
    
    return <ChangeEmailPage viewModel={viewModel} />;
}
