import { get } from "config/infra";
import argsToParams from "config/shared/utils/args-to-params";
import { GlobalResourceResponse } from "domain/entity";
import { ProductResponse } from "domain/entity/Product";
import { statusRequest, statusResponse } from "domain/entity/Status";
import GlobalResourceRepository from "domain/repository/GlobalResources";

export default class GlobalResourceApi implements GlobalResourceRepository {
    async getDepositSumberDana(): Promise<GlobalResourceResponse> {
        return get('/sumberdana')
    }

    async getDepositTujuanKirim(): Promise<GlobalResourceResponse> {
        return get('/tujuankirim')
    }

    async getProduk(): Promise<ProductResponse> {
        return get('/product/list')
    }

    async getStatus(p: statusRequest): Promise<statusResponse> {
        const param = argsToParams(p)
        console.log('param', param)
        return get(`/transaction-status/list?${param}`)
    }

    async getProvince(id: string): Promise<GlobalResourceResponse> {
        const param = argsToParams({ id })
        return get(`/provinsi?${param}`)
    }

    async getDistricts(p: { id?: string, labelName?: string }): Promise<GlobalResourceResponse> {
        const param = argsToParams(p)
        return get(`/kota?${param}`)
    }

    async getSubDistricts(id: string): Promise<GlobalResourceResponse> {
        const param = argsToParams({ id })
        return get(`/kecamatan?${param}`)
    }

    async getVillages(id: string): Promise<GlobalResourceResponse> {
        const param = argsToParams({ id })
        return get(`/kelurahan?${param}`)
    }

    async getJobs(): Promise<GlobalResourceResponse> {
        return get('/pekerjaan')
    }

    async getPosition(): Promise<GlobalResourceResponse> {
        return get('/employee-position/list')
    }

    async getBusinessFields(): Promise<GlobalResourceResponse> {
        return get('/business-field/list')
    }
}