import InformationUseCase from "domain/interactor/Information";
import { useQueries } from "react-query";

type ListBannerUseCases = {
    bannerUseCase: InformationUseCase;
};

const useListBannerViewModel = ({
    bannerUseCase,
}: ListBannerUseCases) => {

    const [{ data: bannerList }] = useQueries([
        {
            queryKey: "/banner",
            queryFn: () => bannerUseCase.getInformation({ type: "banner" }),
            staleTime: Infinity,
        },
    ]);

    const [{ data: qrList }] = useQueries([
        {
            queryKey: "/qr",
            queryFn: () => bannerUseCase.getInformation({ type: "qr" }),
            staleTime: Infinity,
        },
    ]);

    return {
        bannerList,
        qrList,
    };
};

export type ListBannerViewModel = ReturnType<
    typeof useListBannerViewModel
>;

export default useListBannerViewModel;