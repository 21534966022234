import React from "react";
import LayoutAppBar from "config/components/layout-app-bar";
import { Box, Stack } from "@mui/material";
import Message from "config/components/message";
import OTPMobileLayout from "config/components/layout-otp/Mobile";
import { VerifyEmailViewModel } from "presentation/pages/viewmodel/verify-email";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import ButtonRemid from "core/components/remid-button";

type ChangeEmailPageProps = {
    viewModel: VerifyEmailViewModel;
};

const VerifyEmailPage: React.FC<ChangeEmailPageProps> = (props) => {
    const { viewModel } = props;

    return viewModel.success ? (
        <Box sx={{ height: "100vh", px: "2rem" }}>
            <Message
                header="Berhasil Diverifikasi!"
                subHeader="Selamat email Anda berhasil diverifikasi!"
                btnText="KEMBALI"
                onClick={viewModel.handleSuccessRedirect}
                type="success"
            />
        </Box>
    ) : viewModel.showOTP ? (
        <OTPMobileLayout
            primary="white"
            otpRequest={viewModel.otpDataRef.current}
            postOTP={viewModel.onVerifySubmit}
            storageKey="verify-email"
            onSuccess={viewModel.handleSuccessVerifyEmail}
            onBack={() => viewModel.setShowOTP(false)}
        />
    ) : (
        <LayoutAppBar title="Verifikasi Email">
            <form onSubmit={viewModel.handleSubmit(viewModel.onRequestOTP)}>
                <Stack spacing={3} justifyContent="center" alignItems="center">
                    <TextFieldRemid
                        {...viewModel.register("email")}
                        fullWidth
                        shrink
                        label="Email"
                        error={Boolean(viewModel.errors.email)}
                        helperText={viewModel.errors.email?.message}
                    />
                    <ButtonRemid
                        type="submit"
                        disabled={
                            !viewModel.submitButtonStatus ||
                            viewModel.loadingOTP
                        }
                        loading={viewModel.loadingOTP}
                    >
                        {" "}
                        Verif
                    </ButtonRemid>
                </Stack>
            </form>
        </LayoutAppBar>
    );
};

export default VerifyEmailPage;
