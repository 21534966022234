import { post } from "config/infra";
import EmailRepository from "domain/repository/Email";
import { ChangeEmailRequest, ChangeEmailResponse, VerifyEmailRequest } from "domain/entity/Email";
import { GenericResponse } from "domain/entity";


export default class EmailApi implements EmailRepository {
    async postChangeEmail(body: ChangeEmailRequest): Promise<ChangeEmailResponse> {
        return post<ChangeEmailResponse, ChangeEmailRequest>('/email/change', body)
    }

    async postVerifyEmail(body: VerifyEmailRequest): Promise<GenericResponse> {
        return post("/email/verified", body)
    }
}