import React from "react";
import Reaptcha from "reaptcha";
import {
    useTheme,
    Checkbox,
    Box,
    FormGroup,
    FormControlLabel,
    SxProps,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextFieldRemid from "core/components/remid-textfield";
import ButtonRemid from "core/components/remid-button";
import useLanguage from "config/hooks/useLanguage";
import { SignUpViewModelType } from "presentation/pages/viewmodel/auth-signup";
import PasswordHelper from "config/components/password-helper";

type RegisterProps = { sx?: SxProps; viewModel: SignUpViewModelType };

const style: SxProps = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.5rem",
    margin: "15px",
};

const Register: React.FC<RegisterProps> = (props) => {
    // Theming
    const { palette } = useTheme();

    const { sx = {}, viewModel } = props;

    const {
        register,
        handleSubmit,
        errors,
        submitButtonStatus,
        showPassword,
        setShowPassword,
        setShowConfirmPassword,
        showConfirmPassword,
        showPasswordHelper,
        passwordHelperData,
        handleOnChangePhoneNumber,
        phoneRegister,
        onCaptchaChange,
        setTOSCheck,
        handleTOSRedirect,
    } = viewModel;

    // lang
    const { t } = useLanguage();

    // logic hooks

    return (
        <form onSubmit={handleSubmit(viewModel.onSubmitOTP)}>
            <Box sx={{ ...style, ...sx }}>
                <TextFieldRemid
                    {...phoneRegister}
                    onChange={handleOnChangePhoneNumber}
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 17,
                    }}
                    fullWidth
                    shrink
                    placeholder="0812 3456 7890"
                    label={t("auth.phone")}
                    error={Boolean(errors.phoneNumber)}
                    helperText={t(errors.phoneNumber?.message)}
                />
                <TextFieldRemid
                    {...register("email")}
                    fullWidth
                    shrink
                    label={`${t("auth.email")} (opsional)`}
                    error={Boolean(errors.email)}
                    helperText={t(errors.email?.message)}
                />
                <TextFieldRemid
                    {...register("password")}
                    fullWidth
                    label={t("auth.password")}
                    type={showPassword ? "text" : "password"}
                    password
                    shrink
                    iconButton={
                        showPassword ? (
                            <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                        )
                    }
                    helperText={
                        !showPasswordHelper && t("auth.helper_text_password")
                    }
                    onIconButtonClick={() => setShowPassword((state) => !state)}
                    error={Boolean(errors.password)}
                />
                <TextFieldRemid
                    {...register("confirmPassword")}
                    fullWidth
                    label={t("auth.confirm_password")}
                    type={showConfirmPassword ? "text" : "password"}
                    password
                    shrink
                    iconButton={
                        showConfirmPassword ? (
                            <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                        )
                    }
                    onIconButtonClick={() =>
                        setShowConfirmPassword((state) => !state)
                    }
                    error={Boolean(errors.confirmPassword)}
                />

                {showPasswordHelper && (
                    <PasswordHelper data={passwordHelperData} />
                )}

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTOSCheck(e.target.checked)
                        }
                    />
                    <Typography sx={{ fontSize: ".75rem" }}>
                        {t("general.statement1")}
                        <Typography
                            component="span"
                            sx={{ color: palette.primary.main }}
                            fontSize="inherit"
                            onClick={handleTOSRedirect}
                        >
                            {/* {" Syarat dan Ketentuan "} */}
                            {t("general.statement2")}
                        </Typography>{" "}
                        {t("general.statement3")}
                    </Typography>
                </Box>
                <Reaptcha
                    sitekey={process.env?.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
                    onVerify={onCaptchaChange}
                />
                <ButtonRemid
                    type="submit"
                    disabled={!submitButtonStatus}
                >
                    {t("auth.register")}
                </ButtonRemid>
            </Box>
        </form>
    );
};
export default Register;
