import LayoutAppBar from "config/components/layout-app-bar";
import TransactionSuccess from "config/components/transaction-success";
import useLanguage from "config/hooks/useLanguage";
import { TransferUangViewModel } from "presentation/pages/viewmodel/transfer-uang-alfa";
import FormLokasiPengirimanUang from "../transfer-uang-alfa/components/form-lokasi-pengiriman-uang";
import FormPengirimanUang from "../transfer-uang-alfa/components/form-pengiriman-uang";

type TransferUangPageProps = {
    viewModel: TransferUangViewModel;
};

const TransferUangAlfaPage: React.FC<TransferUangPageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    return viewModel.successInsert ? (
        <TransactionSuccess
            alert={t("transaction.alert1")}
            items={viewModel.submitResult}
            onClick={viewModel.redirectToHome}
        />
    ) : (
        <LayoutAppBar
            title={t("operations.send_money")}
            onBack={
                viewModel.nextStep ? viewModel.handleBackButtonClick : undefined
            }
        >
            {viewModel.nextStep ? (
                <FormLokasiPengirimanUang {...props} />
            ) : (
                <FormPengirimanUang {...props} />
            )}
        </LayoutAppBar>
    );
};

export default TransferUangAlfaPage;