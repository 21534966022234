import React from "react";
import { GlobalContext } from "config/context/GlobalContext";
import { Box, Modal } from "@mui/material";
import Message from "config/components/message";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 270,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

const OverlayModal = () => {
    const [{ overlay }, dispatch] = React.useContext(GlobalContext);

    const { open = false, onClick, onNegativeClick, ...restOverlay } = overlay;

    const handleClose = () => {
        dispatch({ type: "CLOSE_OVERLAY" });
    };

    const closeHandler = (event: any, reason: string) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
        }
        handleClose();
    };

    const handleClick = () => {
        if (typeof onClick === "function") {
            onClick();
        } else {
            handleClose();
        }
    };

    const handleOnNegativeClick = () => {
        if (typeof onNegativeClick === "function") {
            onNegativeClick();
        } else {
            handleClose();
        }
    };

    return (
        <Modal open={open} onClose={closeHandler}>
            <Box sx={style}>
                <Message
                    onClick={handleClick}
                    onNegativeClick={handleOnNegativeClick}
                    {...restOverlay}
                />
            </Box>
        </Modal>
    );
};

export default OverlayModal;
