import WithdrawApi from "data/Withdraw";
import WithdrawUseCase from "domain/interactor/Withdraw";
import WithDrawUangPage from "presentation/pages/view/withdraw-uang/WithdrawUangPage";
import useWithdrawUangViewModel from "presentation/pages/viewmodel/withdraw-uang";

const useCase = new WithdrawUseCase(new WithdrawApi());

export default function WithDraw () {
    const viewModel = useWithdrawUangViewModel(useCase);

    return <WithDrawUangPage viewModel={viewModel} />;
}
