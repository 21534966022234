import React from "react";
import LayoutAppBar from "config/components/layout-app-bar";
import FormWithdrawUang from "./components/form-withdraw-uang";
import { WithdrawUangViewModel } from "presentation/pages/viewmodel/withdraw-uang";
import DetailMoneyComponent from "config/components/detail-money";
import { DetailTransaksi } from "config/components/field-detail-transaksi";
import QRCodeComponent from "config/components/qr-code";
import PINMobileLayout from "config/components/layout-pin/mobile";
import TransactionSuccess from "config/components/transaction-success";
import log from "config/log";
import useLanguage from "config/hooks/useLanguage";

type WithDrawUangPageProps = {
    viewModel: WithdrawUangViewModel;
};

const dummy = {
    headerLabel: "Kode Pengambilan Uang (MTCN)",
    headerValue: "7VQBU4J3I",
    items: [
        {
            header: "Status",
            detailItems: [
                {
                    label: "Transaction ID",
                    value: "78147SU364Q8GS",
                    color: "secondary",
                    bold: true,
                },
                {
                    label: "Tanggal Pengambilan",
                    value: "07 Sep 2022 10.50 WIB",
                },
                {
                    label: "Jumlah Uang",
                    value: "Rp. 1.000.000",
                    color: "success",
                },
            ],
        },
        {
            header: "Detail",
            detailItems: [
                {
                    label: "Nama Penerima",
                    value: "John Doe",
                    bold: true,
                },
                {
                    label: "No Id/No Hp Penerima",
                    value: "1271188888000002",
                    bold: true,
                },
                {
                    label: "Kode MTCN",
                    value: "7VQBU4J3I",
                    bold: true,
                },
            ],
        },
        {
            header: "Lokasi Pengambilan Uang",
            detailItems: [
                {
                    label: "Negara",
                    value: "Indonesia",
                    bold: true,
                },
                {
                    label: "Nama Toko",
                    value: "Alfamart",
                    bold: true,
                },
            ],
        },
    ] as Array<DetailTransaksi>,
};

const WithDrawUangPage: React.FC<WithDrawUangPageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    const renderChildren = () => {
        // if (viewModel.showQR) {
        //     return (
        //         <QRCodeComponent
        //             value="1 A B 0 9 G 1"
        //             onBack={viewModel.onClose}
        //         />
        //     );
        // } else if (viewModel.showDetail) {
        //     return (
        //         <DetailMoneyComponent
        //             {...dummy}
        //             onClick={viewModel.onGenerateQRCode}
        //         />
        //     );
        // } else {
        //     return <FormWithdrawUang {...props} />;
        // }
        if (viewModel.isWithdrawQRSuccess) {
            return (
                <QRCodeComponent
                    onBack={viewModel.onClose}
                    value={viewModel.withdrawQRData?.data || ""}
                />
            );
        } else {
            return <FormWithdrawUang {...props} />;
        }
    };

    const renderContent = () => {
        if (viewModel.showSuccess) {
            return <TransactionSuccess {...dummy} />;
        } else if (viewModel.showPINValidation) {
            return (
                <PINMobileLayout
                    primary="white"
                    onSubmit={viewModel.onPINValidation}
                    onBack={viewModel.onBack}
                    loading={viewModel.isWithdrawQRLoading}
                />
            );
        } else {
            return (
                <LayoutAppBar
                    title={t("operations.withdraw_money")}
                    // onBack={viewModel.showDetail ? viewModel.onBack : undefined}
                >
                    {renderChildren()}
                </LayoutAppBar>
            );
        }
    };

    return renderContent();
};

export default WithDrawUangPage;
