import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalContext } from "config/context/GlobalContext";
import { useForm } from "react-hook-form";
import { userIdentityValidation } from "presentation/validation/userIden";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useMutation } from "react-query";
import { setUser } from "config/storage";
import { AxiosError } from "axios";
import log from "config/log";
import SignInUseCase from "domain/interactor/Auth/SignInSSOUseCase";
import { SSOLoginRequest } from "domain/entity/Auth/model/SSOLogin";
import useLanguage from "config/hooks/useLanguage";
import Reaptcha from "reaptcha";

const initialValues: SSOLoginRequest = {
    username: "",
    password: "",
    partner: "",
    token: "",
    signature: "",
};

const validationSchema = yup.object({
    username: userIdentityValidation,
    password: yup.string().required("validation.password_required"),
});

const useSignInViewModel = (useCase: SignInUseCase) => {
    const { t } = useLanguage();

    const location = useLocation();

    const [, dispatch] = React.useContext(GlobalContext);

    const navigate = useNavigate();

    const [success, setSuccess] = React.useState(false);

    const [mobilePhone, setMobilePhone] = React.useState("");

    const [partnerId, setPartnerId] = React.useState<string | null>(null);

    const [showPassword, setShowPassword] = React.useState(false);

    const [captchaStatus, setCaptchaStatus] = React.useState(false);

    const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);

    const reaptchaRef = React.useRef<Reaptcha>(null);

    const resetReaptcha = () => {

        if (reaptchaRef.current) {
            reaptchaRef.current.reset();
        }
    };

    const { mutateAsync, isLoading } = useMutation((data: SSOLoginRequest) =>
        useCase.execute(data)
    );

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
    } = useForm<SSOLoginRequest>({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const submitButtonStatus = captchaStatus && isValid;

    const onSubmit = async (data: SSOLoginRequest) => {
        try {
            let CryptoJS = require("crypto-js");
            let md5Hash = CryptoJS.MD5(data.password).toString();

            data.token = captchaToken || '';
            data.partner = partnerId || '';
            data.password = md5Hash;

            let sign = `${data.username}${data.password}${data.partner}${data.token}${"rmxtransfer77777"}`
            let sha1Hash = CryptoJS.SHA1(sign).toString();
            data.signature = sha1Hash;

            log('sent-data', data);

            const result = await mutateAsync(data);

            const { data: logindata } = result;

            setUser(JSON.stringify(logindata));

            // const redirectUrl = "https://dev-solusipay.ids.id/rc=0000&message=Success&token=eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.e30.qOgMYGa6FWn22UfSlCD-MoTdBElww9QEQLZI3vcNzqKis42Oj11rRkr7sumZrvRSD_G4_QTWAZjl742D7_M7Bw" || "default-fallback-url";
            let SLS_URL = process.env?.REACT_APP_BASE_URL_SOLUSIPAYWEB ?? "";
            const redirectUrl = SLS_URL + logindata.redirectUrl || "default-fallback-url";
            console.log('redirect: ', redirectUrl)

            // Redirect to the specified URL
            window.location.href = redirectUrl;

            // redirect to home
            // setSuccess(true);
        } catch (e: unknown) {
            log(e);

            const { response = null } = e as AxiosError;

            // if (!response) {
            //     dispatch({
            //         type: "SHOW_OVERLAY",
            //         payload: {
            //             header: t("general.general_error"),
            //             subHeader: "Terjadi Kesalahan",
            //             type: "warning",
            //         },
            //     });
            // } else {
            //     const { message = "", rc = "" } = response.data as any;

            //     if (rc === "0007") {
            //         dispatch({
            //             type: "SHOW_OVERLAY",
            //             payload: {
            //                 header: "Informasi Akun",
            //                 subHeader: t("rc." + rc),
            //                 type: "warning",
            //             },
            //         });
            //     } else if (rc === "0058") {
            //         dispatch({
            //             type: "SHOW_OVERLAY",
            //             payload: {
            //                 header: "Informasi Akun",
            //                 // subHeader: message,
            //                 subHeader: "Mohon maaf akun Anda belum dapat diakses, silahkan menghubungi customer service kami 021-50127667.",
            //                 type: "warning",
            //             },
            //         });
            //     } else {
            //         dispatch({
            //             type: "SHOW_OVERLAY",
            //             payload: {
            //                 header: t("general.general_error"),
            //                 // subHeader: message,
            //                 subHeader: t("rc." + rc),
            //                 type: "warning",
            //             },
            //         });
            //     }

            //     // Reset Reaptcha on login failure
            resetReaptcha();
            // }
        }
    };

    const onCaptchaChange = (token: string | null) => {
        setCaptchaToken(token);
        setCaptchaStatus(token !== null);
    };

    const handleShowPassword = () => {
        setShowPassword((pass) => !pass);
    };

    const handleSuccessRedirect = () => {
        window.location.reload();
    };

    const handleNavigateToRegister = () => {
        navigate("/register");
    };

    const handleTOSRedirect = () => {
        navigate("/info");
    };

    const handleNavigateToForgotPassword = () => {
        // navigate("/forgot-password");
        navigate("/send-otp-to");
    };

    // React.useEffect(() => {
    //     let CryptoJS = require("crypto-js");
    //     const data = location.state?.data;
    //     const authcode = new URLSearchParams(location.search).get('authcode') ?? "";

    //     const decodeAuth = decodeURIComponent(authcode);
    //     let ciphertextData = CryptoJS.enc.Base64.parse(decodeAuth)
    //     let keyData = CryptoJS.enc.Utf8.parse('rmxtransfer77777');

    //     // let decrypted = CryptoJS.AES.decrypt(authcode, "rmxtransfer77777").toString();
    //     let decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertextData }, keyData, {
    //         mode: CryptoJS.mode.ECB,
    //         padding: CryptoJS.pad.ZeroPadding
    //     });

    //     let plaintext = CryptoJS.enc.Utf8.stringify(decrypted);
    //     console.log('plain', plaintext);

    //     // const hexToString = (decrypted: any) => {
    //     //     let str = '';
    //     //     for (let i = 0; i < decrypted.length; i += 2) {
    //     //         const charCode = parseInt(decrypted.substr(i, 2), 16);
    //     //         str += String.fromCharCode(charCode);
    //     //     }
    //     //     return str;
    //     // };
    //     // console.log('Decrypted Phone', hexToString(decrypted));

    //     // let mobilePhone = hexToString(decrypted);
    //     let mobilePhone = plaintext;

    //     if (data) {
    //         setValue("username", data.phoneNumber);
    //         setValue("password", data.password);
    //     } else {
    //         setValue("username", mobilePhone);
    //     }
    // }, [location]);

    React.useEffect(() => {
        let CryptoJS = require("crypto-js");
        const data = location.state?.data;
        const authcode = new URLSearchParams(location.search).get('authcode') ?? "";
        const urlParams = new URLSearchParams(location.search);

        const decodedString = decodeURIComponent(authcode);
        const secretKey = CryptoJS.enc.Utf8.parse('rmxtransfer77777');
        const iv = CryptoJS.enc.Utf8.parse('1234567890123456');
        const ciphertext = CryptoJS.enc.Base64.parse(decodedString.toString());

        const decrypted = CryptoJS.AES.decrypt(
            {
                ciphertext: ciphertext
            },
            secretKey,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        const plaintext = CryptoJS.enc.Base64.stringify(decrypted);

        // Convert Base64 to WordArray
        const words = CryptoJS.enc.Base64.parse(plaintext);

        // Convert WordArray to plaintext
        const plaintext2 = CryptoJS.enc.Utf8.stringify(words);
        console.log(plaintext2);

        let mobilePhone = plaintext2;
        // console.log('data', data)

        // Extract and set partnerId
        const extractedPartnerId = urlParams.get('partnerId');
        setPartnerId(extractedPartnerId);

        if (data) {
            setValue("username", data.phoneNumber);
            setValue("password", data.password);
        } else {
            setMobilePhone(mobilePhone);
            setValue("username", mobilePhone);
        }
    }, [location]);

    return {
        isLoading,
        onSubmit,
        register,
        handleSubmit,
        errors,
        success,
        showPassword,
        handleShowPassword,
        handleSuccessRedirect,
        handleNavigateToRegister,
        submitButtonStatus,
        handleNavigateToForgotPassword,
        onCaptchaChange,
        handleTOSRedirect,
        reaptchaRef,
        resetReaptcha,
        mobilePhone,
    };
};

export type SignInViewModelType = ReturnType<typeof useSignInViewModel>;

export default useSignInViewModel;
