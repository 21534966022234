import React from "react";
import LayoutAppBar, {
    LayoutAppBarProps,
} from "config/components/layout-app-bar";
import FormPIN, { FormPINProps } from "config/components/form-pin";
import useLanguage from "config/hooks/useLanguage";

type PINMobileLayoutProps = {} & Omit<LayoutAppBarProps, "children"> &
    FormPINProps;
const PINMobileLayout: React.FC<PINMobileLayoutProps> = (props) => {
    const { t } = useLanguage();

    const { onBack, title = t("general.title5"), primary, ...rest } = props;

    return (
        <LayoutAppBar title={title} primary={primary} onBack={onBack}>
            <FormPIN {...rest} />
        </LayoutAppBar>
    );
};

export default PINMobileLayout;
