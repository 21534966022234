import React from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "config/context/GlobalContext";
import { GuardType, OTPData } from "config/shared/types";
import { emailObfuscate, phoneObfuscate } from "config/shared/utils/obfuscate";
import { clearLocalStorage } from "config/storage";
import log from "config/log";
import { OTPRequest } from "domain/entity/OTP";
import OTPUseCase from "domain/interactor/OTP";
import { useMutation } from "react-query";
import { OTPPayload } from "../auth-otp";
import { ALL_DIGIT, EMAIL } from "presentation/validation/constants";
import EmailUseCase from "domain/interactor/Email";
import useLanguage from "config/hooks/useLanguage";
import useCheckVerif from "config/hooks/useCheckVerified";
import HomeUseCase from "domain/interactor/Home";
import HomeApi from "data/Home";
import SendToMobileLayout from "config/components/layout-send-otp-to";

type Options =
    | "password"
    | "email"
    | "pin"
    | "phone"
    | "verified-email"
    | "email-set"
    | "";

type VerifyEmailUseCases = {
    emailUseCase: EmailUseCase;
    otpUseCase: OTPUseCase;
};

const useProfileViewModel = ({
    emailUseCase,
    otpUseCase,
}: VerifyEmailUseCases) => {
    const dataVerif = useCheckVerif(new HomeUseCase(new HomeApi));

    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const { t } = useLanguage();

    const navigate = useNavigate();

    // this will hold type ["PIN" | "OTP"] before user can access anything
    const [showGuard, setShowGuard] = React.useState<GuardType>("");

    const [option, setOption] = React.useState<Options>("");

    const [success, setSuccess] = React.useState(false);

    const otpDataRef = React.useRef<OTPPayload | null>(null);

    const usingOTPGuard = (options: Options) => {
        if (!user || !user.email || !user.phone) return;

        postOTP({
            userId: "",
            email: user.email,
            phone: user.phone,
            method: "",
            type: "verified-email",
        })
            .then(() => {
                otpDataRef.current = {
                    userId: "",
                    email: user.email ?? "",
                    phone: user.phone ?? "",
                    method: "",
                    type: "forgot-password",
                    sendWhere: "email",
                };

                setShowGuard("OTP");
                setOption(options);
            })
            .catch((err) => {
                const { response } = err;

                if (response && response.data) {
                    const { message, rc } = response.data;

                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.general_error"),
                            type: "warning",
                            btnText: "Kembali",
                            subHeader: t("rc." + rc),
                        },
                    });
                }
            });
    };

    const usingPINGuard = (options: Options) => {
        setShowGuard("PIN");
        setOption(options);
    };

    let emailStatus: any = "";

    if (!user?.email && !Boolean(Number(dataVerif.dataVerif?.isEmailVerified))) {
        // jika sakit berlanjut hubungi erik GOD.
        emailStatus = "normal";
    } else if (user?.email && !Boolean(Number(dataVerif.dataVerif?.isEmailVerified))) {
        emailStatus = "unverified";
    } else if (Boolean(Number(dataVerif.dataVerif?.isEmailVerified))) {
        emailStatus = "verified";
    }

    const { mutateAsync: postOTP, isLoading: otpLoading } = useMutation(
        "send-otp",
        (body: OTPRequest) => otpUseCase.postOTP(body)
    );

    const obfuscatedPhone = React.useMemo(() => {
        return phoneObfuscate(user?.phone ?? "");
    }, [user]);

    const obfuscatedEmail = React.useMemo(() => {
        const _email = user?.email;
        if (_email === undefined || _email === null || _email === "")
            return "Belum Atur Email";
        return emailObfuscate(_email);
    }, [user]);

    // const _username = watch("username");

    const otpData: OTPData = {
        type: option === "email" ? "email" : "phone",
        value: option === "email" ? user?.email ?? "" : user?.phone ?? "",
    };

    const onVerifySubmit = (otp: string) => {
        return emailUseCase.postVerifyEmail({
            email: user?.email || "",
            userId: user?.userId || "",
            otp,
        });
    };

    const handleSuccessVerifyEmail = () => {
        dispatch({
            type: "SAVE_USER",
            payload: { ...user, isEmailVerified: "1" },
        });
        setSuccess(true);

        navigate("/dashboard/profile");
    };

    const handleChangePhoneClick = () => {
        navigate("/change-phone", {
            state: { parent: "/dashboard/profile" },
        });
    };

    const handleForgotPinClick = () => {
        // UBAH FLOWNYA
        navigate("/forgot-pin", {
            state: { parent: "/dashboard/profile", phone: user?.phone, email: user?.email },
        });
    };

    const handleChangePasswordClick = () => {
        navigate("/change-password", {
            state: { parent: "/dashboard/profile" },
        });
    };

    const handleChangeEmailClick = () => {
        if (emailStatus === "verified") {
            navigate("/change-email", {
                state: {
                    parent: "/dashboard/profile",
                    data: {
                        header: "Berhasil dirubah!",
                        subHeader: "Selamat email Anda berhasil diubah",
                    },
                },
            });
        } else {
            navigate("/change-email", {
                state: {
                    parent: "/dashboard/profile",
                    data: {
                        header: "Berhasil diatur!",
                        subHeader: "Selamat email Anda berhasil diatur",
                    },
                },
            });
        }
    };

    const handleChangePinClick = () => {
        log("change password coy!");
        navigate("/change-pin", {
            state: { parent: "/dashboard/profile", change: true },
        });
    };

    const handleMessageButton = () => {
        // setSuccess(false);
        // setShowGuard("")
        window.location.reload();
    };

    const onSubmitGuard = () => {
        // handle submit based on choices
        switch (option) {
            case "phone":
                break;
            case "email":
                break;
            case "email-set":
                break;
            case "verified-email":
                setShowGuard("");
                setSuccess(true);
                if (user !== null) {
                    dispatch({
                        type: "SAVE_USER",
                        payload: {
                            ...user,
                            isAccountVerified: "1",
                            isPhoneVerified: "1",
                        },
                    });
                }
                break;
            case "password":
                break;
            case "pin":
                break;
            default:
                return;
        }
    };

    const handleLogout = () => {
        dispatch({
            type: "SHOW_OVERLAY",
            payload: {
                header: t("general.logout_notice"),
                btnText: t("general.logout"),
                negativeBtnText: "Batal",
                onClick: () => {
                    clearLocalStorage();
                    window.location.href = "/";
                },
            },
        });
    };

    const onBack = () => {
        setShowGuard("");
        setSuccess(false);
    };

    return {
        otpData,
        user,
        showGuard,
        usingOTPGuard,
        usingPINGuard,
        onSubmitGuard,
        obfuscatedEmail,
        obfuscatedPhone,
        onBack,
        handleLogout,
        handleChangePhoneClick,
        handleForgotPinClick,
        success,
        handleMessageButton,
        emailStatus,
        handleChangeEmailClick,
        handleChangePasswordClick,
        handleChangePinClick,
        onVerifySubmit,
        otpDataRef,
        handleSuccessVerifyEmail,
        setSuccess,
        otpLoading
    };
};

export type ProfileViewModel = ReturnType<typeof useProfileViewModel>;

export default useProfileViewModel;
