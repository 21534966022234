import { ALL_DIGIT } from 'presentation/validation/constants'

const phoneObfuscate = (phone: string) => {

    if (!ALL_DIGIT.test(phone)) {
        return ""
    }

    const tempPhone = phone.split("");
    return tempPhone
        .map((e, idx) => {
            if (
                idx === tempPhone.length - 1 ||
                idx === tempPhone.length - 2
            ) {
                return e;
            }
            if ((idx + 1) % 3 === 0) return "*-";
            return "*";
        })
        .join("");
}

export default phoneObfuscate