
import { ForgotPinRequest } from "domain/entity/Auth/model/Pin";
import IAuthRepository from "domain/repository/Auth";


class ForgotPinUseCase {
    private service: IAuthRepository;

    constructor(service: IAuthRepository) {
        this.service = service;
    }

    execute(body: ForgotPinRequest) {
        return this.service.doResetPin(body);
    }
}

export default ForgotPinUseCase;