import log from "config/log";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useGenerateQRViewModel = () => {
    const location = useLocation();

    const navigate = useNavigate();

    const [success, setSuccess] = React.useState(false);

    const handleSuccessRedirect = () => {
        window.location.reload();
    };

    React.useEffect(() => {
        if (!Boolean(location.state)) {
            log("you shall not pass");
            navigate("/");
        }
    }, [location.state]);

    const onBack = () => {
        navigate(-1)
    }

    return {
        success,
        handleSuccessRedirect,
        onBack
    };
};

export type GenerateQRViewModel = ReturnType<typeof useGenerateQRViewModel>;

export default useGenerateQRViewModel;