import {
    IconButton,
    useTheme,
    SxProps,
    Chip,
    Box,
    Typography,
} from "@mui/material";
import React from "react";
import useLanguage from "config/hooks/useLanguage";

const TransaksiHeader = () => {
    const { t } = useLanguage();

    const [chipState, setChipState] = React.useState([
        { id: 0, label: "Semua", status: 1 },
        { id: 1, label: "Pengiriman", status: 0 },
        { id: 2, label: "Pengambilan", status: 0 },
        { id: 3, label: "Pembatalan", status: 0 },
    ]);

    const { palette } = useTheme();

    const style: SxProps = {
        borderColor: palette.primary.main,
        color: palette.primary.main,
        "&.MuiChip-filled": {
            color: "#fff",
            backgroundColor: palette.primary.main,
        },
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "64rem",
                background: "url('/svg/TransaksiLine.svg')",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top right",
                backgroundSize: "65%",
                backgroundColor: '#FFF'
            }}
        >
            <Box
                sx={{
                    flexGrow: 2,
                    display: "flex",
                    alignItems: "center",
                    px: "1.5rem",
                }}
            >
                <Typography
                    sx={{ fontSize: "1.25rem", textTransform: "uppercase" }}
                    fontWeight={600}
                >
                    {t("transaction.transaction")}
                </Typography>
            </Box>

            <Box sx={{ px: ".3rem" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                        minWidth: "100%",
                        maxWidth: 0,
                        overflow: "auto",
                        gap: ".3rem",
                    }}
                >
                    {/* {chipState.map(({ id, label, status }) => {
                        return (
                            <IconButton key={id} sx={{ p: 0 }}>
                                <Chip
                                    variant={status ? "filled" : "outlined"}
                                    sx={style}
                                    label={label}
                                />
                            </IconButton>
                        );
                    })} */}
                </Box>
            </Box>
        </Box>
    );
};

export default TransaksiHeader;
