import React from "react";
import ButtonRemid from "core/components/remid-button";
import { useTheme, Box, Stack, Typography } from "@mui/material";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

export type MessageFailedProps = {
    header?: string;
    subHeader?: string;
    btnText?: string;
    negativeBtnText?: string;
    type?: "success" | "warning" | "failed";
    onClick?: () => void;
    onNegativeClick?: () => void;
};

const MessageFailed: React.FC<MessageFailedProps> = (props) => {
    const { palette } = useTheme();

    const {
        header,
        subHeader,
        btnText = "Kembali",
        type,
        onClick,
        negativeBtnText,
        onNegativeClick,
    } = props;

    const handleClick = () => {
        onClick?.();
    };

    const handleNegativeClick = () => {
        onNegativeClick?.();
    };

    return (
        <Stack
            sx={{ textAlign: "center", height: "100%" }}
            spacing={2}
            alignItems="center"
            justifyContent="center"
        >
            {type === "failed" && (
                <Box
                    sx={{
                        display: "inline-block",
                        color: palette.primary.main,
                    }}
                >
                    <CancelRoundedIcon
                        color="inherit"
                        sx={{ fontSize: "8.5rem" }}
                    />
                </Box>
            )}
            {type === "warning" && (
                <Box
                    sx={{
                        display: "inline-block",
                        color: palette.secondary.main,
                    }}
                >
                    <WarningRoundedIcon
                        color="inherit"
                        sx={{ fontSize: "5.75rem" }}
                    />
                </Box>
            )}

            <Typography sx={{ fontWeight: 700 }}>{header}</Typography>
            <Typography
                sx={{
                    fontSize: ".75rem",
                    color: palette.text.secondary,
                }}
            >
                {subHeader}
            </Typography>

            <ButtonRemid onClick={handleClick}>{btnText}</ButtonRemid>

            <ButtonRemid onClick={handleNegativeClick} variant="outlined">
                {negativeBtnText}
            </ButtonRemid>
        </Stack>
    );
};

export default MessageFailed;