import { CheckUserResponse } from "domain/entity/Deposit";
import { WithdrawRequest, WithdrawResponse, WithdrawQRRequest, WithdrawQRResponse, WithdrawMtcnResponse, WithdrawMtcnRequest } from "domain/entity/Withdraw";
import WithdrawRepository from "domain/repository/Withdraw";

export default class WithdrawUseCase implements WithdrawRepository {
    constructor(private repository: WithdrawRepository) { }

    async postWithdraw(body: WithdrawRequest): Promise<WithdrawResponse> {
        return this.repository.postWithdraw(body)
    }

    async postWithdrawQR(body: WithdrawQRRequest): Promise<WithdrawQRResponse> {
        return this.repository.postWithdrawQR(body)
    }

    async postWithdrawMtcn(body: WithdrawMtcnRequest): Promise<WithdrawMtcnResponse> {
        return this.repository.postWithdrawMtcn(body)
    }

    getCheckUser(): Promise<CheckUserResponse> {
        return this.repository.getCheckUser()
    }
}