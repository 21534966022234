import React from "react";
import { Stack, Box, Typography, useTheme } from "@mui/material";

export type DetailItem = {
    label?: string;
    value?: string;
    color?: "primary" | "secondary" | "normal";
    bold?: boolean;
};

export type DetailTransaksi = {
    header?: string;
    detailItems?: Array<DetailItem>;
};

type FieldDetailTransaksiProps = DetailTransaksi;

const Detail: React.FC<DetailItem> = (props) => {
    const { palette } = useTheme();

    const { label, value, bold = false, color = "normal" } = props;

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
                sx={{ fontSize: ".75rem", color: palette.text.secondary }}
            >
                {label}
            </Typography>
            <Typography
                sx={{
                    fontSize: ".875rem",
                    color:
                        color === "primary"
                            ? palette.primary.main
                            : color === "secondary"
                            ? palette.secondary.main
                            : color === "normal"
                            ? "#000"
                            : palette.success.light,
                    fontWeight: bold ? 600 : 400,
                }}
            >
                {value}
            </Typography>
        </Box>
    );
};

const FieldDetailTransaksi: React.FC<FieldDetailTransaksiProps> = (props) => {
    const { header = "Insert Title Here :)", detailItems = [] } = props;

    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Typography sx={{ py: ".5em", fontWeight: 600 }}>
                {header}
            </Typography>
            {detailItems.map((item) => {
                return <Detail key={`${item.value}-${item.label}`} {...item} />;
            })}
        </Stack>
    );
};

export default FieldDetailTransaksi;
