import { get } from "config/infra";
import argsToParams from "config/shared/utils/args-to-params"
import { merchantRequest, merchantResponse } from "domain/entity/Merchant"
import MerchantRepository from "domain/repository/Merchant";

export default class MerchantApi implements MerchantRepository {

    async getMerchant(p: merchantRequest): Promise<merchantResponse> {
        const param = argsToParams(p)
        console.log('param', param)
        return get(`/merchant/list?${param}`)
    }
}