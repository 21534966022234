import React from "react";
import { ConfirmIdentityViewModel } from "presentation/pages/viewmodel/confirm-identity";
import ConfirmIdentityGuide from "./components/confirm-identity-guide";
import ConfirmIdentityLayout from "./components/confirm-identity-layout";
import PreviewImage from "./components/confirm-identity-preview-picture";
import ConfirmIdentityForm from "./components/confirm-identity-form";
import CameraComponent from "./components/camera-component";
import Message from "config/components/message";
import { Box, CircularProgress, Typography } from "@mui/material";
import useLanguage from "config/hooks/useLanguage";
import log from "config/log";
import { useLocation } from "react-router-dom";

type ConfirmIdentityPageProps = {
    viewModel: ConfirmIdentityViewModel;
};

const ConfirmIdentityPage: React.FC<ConfirmIdentityPageProps> = ({
    viewModel,
}) => {
    const { t } = useLanguage();

    const {
        contentItems,
        handleDecrementStep,
        handleActionClick,
        stepIndex,
        btnGuideText,
        openCamera,
        imageData,
        setImageData,
        submitImage,
        showForm,
        facingMode,
        handleCapture,
        success,
        redirectToHome,
        ktpLoading,
    } = viewModel;

    const location = useLocation();

    const levelStatus = location?.state?.levelStatus;

    log('status: ' + levelStatus)

    const renderContent = () => {
        if (openCamera) {
            return (
                <CameraComponent
                    facingMode={facingMode}
                    onCapture={handleCapture}
                    onBack={viewModel.closeCamera}
                />
            );
        } else if (success) {
            return (
                <Box sx={{ height: "100vh", px: "2rem" }}>
                    <Message
                        header="Berhasil dikirim!"
                        subHeader="Aktivasi KTP Anda telah berhasil"
                        btnText="Oke"
                        type="success"
                        onClick={redirectToHome}
                    />
                </Box>
            );
        } else {
            return ktpLoading ? (
                <Box
                    // sx={{
                    //     position: "absolute",
                    //     top: "50%",
                    //     left: "50%",
                    //     transform: "translate(-50%, -50%)"
                    // }}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "100vh",
                    }}
                >
                    <CircularProgress size={64} />
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "1.25rem",
                            textAlign: "center",
                            marginTop: 2, // Adjust the margin as needed
                        }}
                    >
                        Mohon Menunggu
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "1.25rem",
                            textAlign: "center",
                            marginTop: 2, // Adjust the margin as needed
                        }}
                    >
                        Data Anda sedang dalam proses
                    </Typography>
                </Box>
            ) : (
                <ConfirmIdentityLayout
                    onBack={handleDecrementStep}
                    content={
                        showForm ? (
                            <ConfirmIdentityForm viewModel={viewModel} />
                        ) : imageData ? (
                            <PreviewImage
                                image={imageData}
                                nextCB={submitImage}
                                retryCB={() => setImageData(null)}
                            />
                        ) : (
                            <ConfirmIdentityGuide
                                onClick={handleActionClick}
                                step={stepIndex}
                                btnGuideText={btnGuideText}
                                {...contentItems[stepIndex]}
                            />
                        )
                    }
                />
            );
        }
    };

    return renderContent();
};

export default ConfirmIdentityPage;
