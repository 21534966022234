import { DepositQRRequest } from "domain/entity/Deposit";
import DepositUseCase from "domain/interactor/Deposit";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";

const useListTransaksiDetailViewModel = (useCase: DepositUseCase) => {
    const location = useLocation();

    const navigate = useNavigate();

    const [success, setSuccess] = React.useState(false);

    const handleSuccessRedirect = () => {
        window.location.reload();
    };

    const onBack = () => {
        navigate(-1)
    }

    const {
        mutateAsync: mutateAsyncQR,
        data: depositQRData,
        isLoading: isDepositQRLoading,
    } = useMutation("/deposit/generateqr", (body: DepositQRRequest) =>
        useCase.postDepositQR(body)
    );

    return {
        success,
        handleSuccessRedirect,
        onBack,
        depositQRData,
        isDepositQRLoading,
        mutateAsyncQR
    };
};

export type ListTransaksiDetailViewModel = ReturnType<typeof useListTransaksiDetailViewModel>;

export default useListTransaksiDetailViewModel;