import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Divider,
    Alert,
    Typography,
    useTheme,
    Box,
    Stack,
} from "@mui/material";
import FieldDetailTransaksi, {
    DetailTransaksi,
} from "../field-detail-transaksi";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import ButtonRemid from "core/components/remid-button/Button";
import useLanguage from "config/hooks/useLanguage";

type TransactionSuccessProps = {
    items?: Array<DetailTransaksi>;
    alert?: string;
    onClick?: () => void;
};

const TransactionSuccess: React.FC<TransactionSuccessProps> = (props) => {
    const navigate = useNavigate();

    const { t } = useLanguage();

    const { palette } = useTheme();

    const { alert, onClick, items = [] } = props;

    const handleClick = () => {
        if (typeof onClick === "function") {
            onClick();
        } else {
            navigate(-1);
        }
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", p: "1rem" }}>
            <Stack
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", maxWidth: 1024 }}
            >
                <Box sx={{ fontSize: "5rem", color: palette.primary.main }}>
                    <VerifiedRoundedIcon fontSize="inherit" color="inherit" />
                </Box>
                <Typography fontWeight={600}>{t("auth.success")}</Typography>
                {items.map((item, idx) => {
                    return (
                        <React.Fragment key={`${item.header}-${idx}`}>
                            <FieldDetailTransaksi {...item} />
                            {idx !== items.length - 1 && (
                                <Divider sx={{ width: "100%" }} />
                            )}
                        </React.Fragment>
                    );
                })}
                {alert && <Alert severity="error">{alert}</Alert>}
                <ButtonRemid onClick={handleClick}>{t("auth.back")}</ButtonRemid>
            </Stack>
        </Box>
    );
};

export default TransactionSuccess;
