import { PASSWORD_COMBINATION, MINIMUM_PASSWORD_LENGTH } from "presentation/validation/constants";

type PasswordHelperData = {
    status: boolean;
    text: string;
}

export const isPasswordEquals = (p1: string, p2: string) => {
    return p1 !== "" && p2 !== "" && p1 === p2;
};

export const isPasswordFormatValid = (p: string) => {
    return PASSWORD_COMBINATION.test(p);
};

export const isPasswordLengthValid = (p: string) => {
    return p.length >= MINIMUM_PASSWORD_LENGTH;
};

export const passwordHelperData = [
    {
        status: false,
        text: "Minimal 8 karakter",
    },
    {
        status: false,
        text: "Kombinasi huruf dan angka",
    },
    {
        status: false,
        text: "kata sandi dan konfirmasi kata sandi sesuai",
    },
];

export const passwordHelperDataValidate = (data: Array<PasswordHelperData>) => {
    for (const item of data) {
        if (!item.status)
            return false
    }
    return true;
}