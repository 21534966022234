import React from "react";
import log from "config/log";

export type PinViewModelProps = {
    onSubmit?: Promise<any> | any;
};

const usePINViewModel = (props: PinViewModelProps) => {
    const { onSubmit } = props;

    const [pin, setPIN] = React.useState("");

    const [pinError, setPinError] = React.useState(false);

    const handleChange = (e: string) => {
        setPIN(e);
    };

    const handleErr = () => {
        setPinError(true);
        setPIN("");
    };

    const handleSubmit = () => {
        onSubmit?.(pin).catch(handleErr);
    };

    React.useEffect(() => {
        if (pin.length === 6) {
            log(["PIN FILLED submit coy: ", pin]);
            handleSubmit();
        }
    }, [pin]);

    return { pin, handleChange, pinError };
};

export default usePINViewModel;
