import { getUser } from "config/storage"
import User from 'config/shared/types/User'

type Headers = {
    [key: string]: any
}

let headers: Headers = {}

try {
    const user: User = JSON.parse(getUser() || "null")

    if (user?.token) {
        headers['Authorization'] = `Bearer ${user.token}`
    }

    if (user?.userId) {
        headers['X-uSER'] = user.userId
    }
} catch (err) {
    headers = {}
    console.log(err)
}

export default headers