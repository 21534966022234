import React from "react";
import { useNavigate } from "react-router-dom";
import Register from "presentation/pages/view/auth-signup/components/form-register";
import AuthDesktopLayout from "config/components/layout-auth/Desktop";
import AuthMobileLayout from "config/components/layout-auth/Mobile";
import { SignUpViewModelType } from "presentation/pages/viewmodel/auth-signup";
import ResponsiveWrapper from "config/components/responsive-wrapper";
import { Box } from "@mui/material";
import Message from "config/components/message";
import useLanguage from "config/hooks/useLanguage";
import SendToMobileLayout from "config/components/layout-send-otp-to";

type SignUpPageProps = {
    viewModel: SignUpViewModelType;
};

const SignUpPage: React.FC<SignUpPageProps> = (props) => {
    const navigate = useNavigate();

    // lang
    const { t } = useLanguage();

    const { viewModel } = props;

    const handleNavigate = () => {
        navigate("/login");
    };

    return (
        // <>
        //     {viewModel.success ? (
        //         <Box sx={{ height: "100vh", px: "2rem" }}>
        //             <Message
        //                 header={t("general.signup_header")}
        //                 subHeader={t("general.signup_subheader")}
        //                 btnText={t("auth.sign_in")}
        //                 onClick={viewModel.handleSuccessRedirect}
        //                 type="success"
        //             />
        //         </Box>
        //     ) : viewModel.showOTP ? (
        //         <OTPMobileLayout
        //             primary="white"
        //             onBack={viewModel.onVerificationBack}
        //             otpRequest={viewModel.otpDataRef.current}
        //             postOTP={viewModel.onSubmit}
        //             onSuccess={() => viewModel.setSuccess(true)}
        //             storageKey="register"
        //         />
        //         // <SendToMobileLayout
        //         //     primary="white"
        //         //     onBack={viewModel.onVerificationBack}
        //         //     onMethodSelect={viewModel.handleMethodSelect}
        //         // />
        //     ) : (
        //         <ResponsiveWrapper
        //             desktopLayout={
        //                 <AuthDesktopLayout
        //                     authType="register"
        //                     header={t("auth.register")}
        //                     childForm={<Register {...props} />}
        //                     onTextClickNavigate={handleNavigate}
        //                 />
        //             }
        //             mobileLayout={
        //                 <AuthMobileLayout
        //                     header={t("general.form_signup1")}
        //                     subHeader={t("general.form_signup2")}
        //                     bottomText={t("general.form_signup3")}
        //                     navigateText={t("auth.sign_in")}
        //                     onTextClickNavigate={handleNavigate}
        //                     childForm={<Register {...props} />}
        //                 />
        //             }
        //         />
        //     )}
        // </>

        <>
            {viewModel.success ? (
                <Box sx={{ height: "100vh", px: "2rem" }}>
                    <Message
                        header={t("general.signup_header")}
                        subHeader={t("general.signup_subheader")}
                        btnText={t("auth.sign_in")}
                        onClick={viewModel.handleSuccessRedirect}
                        type="success"
                    />
                </Box>
            ) : viewModel.formSubmitted ? (
                <SendToMobileLayout
                    primary="white"
                    onBack={viewModel.onVerificationBack}
                    type={"register"}
                    otpMethodList={{
                        sms: viewModel.getValues('phoneNumber'),
                        whatsapp: viewModel.getValues('phoneNumber'),
                        email: viewModel.getValues('email')
                    }}
                    postOTP={viewModel.onSubmit}
                    onSuccess={() => viewModel.setSuccess(true)}
                    storageKey="register"
                />
            ) : (
                <ResponsiveWrapper
                    desktopLayout={
                        <AuthDesktopLayout
                            authType="register"
                            header={t("auth.register")}
                            childForm={<Register {...props} />}
                            onTextClickNavigate={handleNavigate}
                        />
                    }
                    mobileLayout={
                        <AuthMobileLayout
                            header={t("general.form_signup1")}
                            subHeader={t("general.form_signup2")}
                            bottomText={t("general.form_signup3")}
                            navigateText={t("auth.sign_in")}
                            onTextClickNavigate={handleNavigate}
                            childForm={<Register {...props} />}
                        />
                    }
                />
            )}
        </>
    );
};

export default SignUpPage;
