import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import phoneValidation from "presentation/validation/phone";
import { useLocation, useNavigate } from "react-router-dom";
import log from "config/log";
import phoneFormatter from "config/shared/utils/phone-formatter";
import PhoneUseCase from "domain/interactor/Phone";
import OTPUseCase from "domain/interactor/OTP";
import { OTPPayload } from "../auth-otp";
import { useMutation } from "react-query";
import { OTPRequest } from "domain/entity/OTP";
import { ChangePhoneRequest } from "domain/entity/Phone";
import { ALL_DIGIT } from "presentation/validation/constants";
import { GlobalContext } from "config/context/GlobalContext";
import useLanguage from "config/hooks/useLanguage";

const initialValues = {
    phoneNumber: "",
};

const validationSchema = yup.object({
    phoneNumber: phoneValidation,
});

type ChangePhoneUseCases = {
    phoneUseCase: PhoneUseCase;
    otpUseCase: OTPUseCase
}

const useChangePhoneViewModel = ({ phoneUseCase, otpUseCase }: ChangePhoneUseCases) => {
    const { t } = useLanguage();

    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const location = useLocation();

    const navigate = useNavigate();

    // const [showOTP, setShowOTP] = React.useState(false);'

    const [formSubmitted, setFormSubmitted] = React.useState(false);

    const [success, setSuccess] = React.useState(false);

    const {
        watch,
        register,
        formState: { errors },
        handleSubmit,
        getValues,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const _phone = watch("phoneNumber");

    const { onChange: OnChangePhone, ...phoneRegister } = register("phoneNumber")

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.target.value = phoneFormatter(e.target.value)
        OnChangePhone(e)
    }

    const _phoneNumber = watch("phoneNumber");

    const submitButtonStatus = _phoneNumber !== "";

    const handleSuccessRedirect = () => {
        navigate(location.state.parent);
    };

    const otpDataRef = React.useRef<OTPPayload | null>(null);

    const otpData: OTPPayload = {
        userId: "",
        email: "",
        phone: getValues("phoneNumber") || "",
        method: "",
        type: "change-phone",
        sendWhere: "email",
    };

    const { mutateAsync: postOTP, isLoading: otpLoading } = useMutation(
        "send-otp",
        (body: OTPRequest) => otpUseCase.postOTP(body)
    );

    const { mutateAsync: changePhone } = useMutation(
        "/phone/change",
        (body: ChangePhoneRequest) => phoneUseCase.postChangePhone(body)
    );

    const onSubmit = async (data: typeof initialValues) => {
        setFormSubmitted(true)
        // try {
        //     let email = '';
        //     let phone = '';
        //     let userId = '';

        //     phone = getValues('phoneNumber');

        //     postOTP({
        //         userId,
        //         email,
        //         phone: phone.replaceAll(" ", ""),
        //         method: "",
        //         type: "change-phone",
        //     }).then(() => {
        //         otpDataRef.current = {
        //             userId,
        //             email,
        //             phone,
        //             method: "",
        //             type: "change-phone",
        //             sendWhere: email ? 'email' : 'phone'
        //         }

        //         // setShowOTP(true);
        //     }).catch((err) => {
        //         const { response } = err;

        //         if (response && response.data) {
        //             const { message, rc } = response.data;

        //             dispatch({
        //                 type: "SHOW_OVERLAY",
        //                 payload: {
        //                     header: t("general.general_error"),
        //                     type: "warning",
        //                     btnText: "Kembali",
        //                     subHeader: t("rc." + rc),
        //                 },
        //             });
        //         }
        //     });
        // } catch (error) {
        //     log(error)
        // }
    };

    const onVerificationSubmit = () => {
        setSuccess(true);
        if (user !== null) {
            dispatch({
                type: "SAVE_USER",
                payload: {
                    ...user,
                    phone: _phone,
                },
            });
        }
    };

    const onVerificationBack = () => {
        setFormSubmitted(false);
    };

    const onVerifySubmit = (otp: string, method: string) => {
        return phoneUseCase.postChangePhone({
            phone: getValues("phoneNumber").replaceAll(" ", ""),
            userId: user?.userId || "",
            otp,
            method
        }).then(() => { setFormSubmitted(true) })
    }

    const handleSuccessVerifyPhone = () => {
        dispatch({ type: "SAVE_USER", payload: { ...user, phone: getValues("phoneNumber").replaceAll(" ", "") } })
        setSuccess(true)
    }

    React.useEffect(() => {
        if (!Boolean(location.state)) {
            log("you shall not pass");
            navigate("/");
        }
    }, [location.state]);

    return {
        register,
        onSubmit,
        errors,
        handleSubmit,
        submitButtonStatus,
        // showOTP,
        success,
        handleSuccessRedirect,
        onVerificationSubmit,
        handlePhoneChange,
        phoneRegister,
        otpData,
        otpDataRef,
        onVerifySubmit,
        handleSuccessVerifyPhone,
        // setShowOTP
        formSubmitted,
        onVerificationBack,
        getValues,
        setSuccess,
    };
};

export type ChangePhoneViewModel = ReturnType<typeof useChangePhoneViewModel>;

export default useChangePhoneViewModel;
