import { ChangeEmailViewModel } from "presentation/pages/viewmodel/change-email";
import React from "react";
import FormChangeEmail from "./components/form-change-email";
import LayoutAppBar from "config/components/layout-app-bar";
import { Box } from "@mui/material";
import Message from "config/components/message";
import OTPMobileLayout from "config/components/layout-otp/Mobile";
import useLanguage from "config/hooks/useLanguage";

type ChangeEmailPageProps = {
    viewModel: ChangeEmailViewModel;
};

const ChangeEmailPage: React.FC<ChangeEmailPageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    return viewModel.success ? (
        <Box sx={{ height: "100vh", px: "2rem" }}>
            <Message
                header={viewModel.messageData.header}
                subHeader={viewModel.messageData.subHeader}
                btnText="KEMBALI"
                onClick={viewModel.handleSuccessRedirect}
                type="success"
            />
        </Box>
    ) : viewModel.showOTP ? (
        <OTPMobileLayout
            // primary="white"
            // onSubmit={viewModel.onVerificationSubmit}
            // data={viewModel.otpData}
            primary="white"
            otpRequest={viewModel.otpData}
            postOTP={viewModel.onVerifySubmit}
            storageKey="change-email"
            onSuccess={viewModel.handleSuccessVerifyEmail}
            onBack={() => viewModel.setShowOTP(false)}
        />
    ) : (
        <LayoutAppBar title={t("general.title1")}>
            <FormChangeEmail {...props} />
        </LayoutAppBar>
    );
};

export default ChangeEmailPage;
