import React from "react";
import { Box } from "@mui/material";

const LayoutDesktop = ({ children }: React.PropsWithChildren) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Box sx={{ flex: 1, maxWidth: 1025 }}>{children}</Box>
        </Box>
    );
};

export default LayoutDesktop;
