import React from "react";
import { Box } from "@mui/material";
import ProfileContentMobile from "presentation/pages/view/profile/components/profile-content-mobile";
import { ProfileViewModel } from "presentation/pages/viewmodel/profile";
import OTPMobileLayout from "config/components/layout-otp/Mobile";
import PINMobileLayout from "config/components/layout-pin/mobile";
import HomeLayout from "config/components/layout-home";
import Message from "config/components/message";

type ProfilePageProps = {
    viewModel: ProfileViewModel;
};

const ProfilePage: React.FC<ProfilePageProps> = (props) => {
    const { viewModel } = props;

    const { success, handleMessageButton, showGuard } = viewModel;

    return success ? (
        <Box sx={{ height: "100vh", px: "2rem" }}>
            <Message
                type="success"
                header="Berhasil diatur"
                subHeader="Selamat email Anda berhasil diatur"
                btnText="Kembali"
                onClick={handleMessageButton}
            />
        </Box>
    ) : showGuard === "OTP" ? (
        <OTPMobileLayout
            primary="white"
            otpRequest={viewModel.otpDataRef.current}
            postOTP={viewModel.onVerifySubmit}
            storageKey="verify-email"
            onSuccess={viewModel.handleSuccessVerifyEmail}
            onBack={viewModel.onBack}
            method=""
        />
    ) : showGuard === "PIN" ? (
        <PINMobileLayout
            primary="white"
            onBack={viewModel.onBack}
            // onSubmit={viewModel.onSubmitGuard}
        />
    ) : (
        <HomeLayout>
            <ProfileContentMobile {...props} />
        </HomeLayout>
    );
};

export default ProfilePage;
