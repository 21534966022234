import TransferUangAlfaPage from "presentation/pages/view/transfer-uang-alfa/TransferUangAlfaPage";
import useTransferUangAlfaViewModel from "presentation/pages/viewmodel/transfer-uang-alfa";
import DepositApi from "data/Deposit";
import DepositUseCase from "domain/interactor/Deposit";
import GlobalResourceUseCase from "domain/interactor/GlobalResource";
import GlobalResourceApi from "data/GlobalResource";
import BankUseCase from "domain/interactor/Bank";
import BankApi from "data/Bank";
import MerchantApi from "data/Merchant";
import MerchantUseCase from "domain/interactor/Merchant";

const useCase = new DepositUseCase(new DepositApi());
const bankUseCase = new BankUseCase(new BankApi());
const merchantUseCase = new MerchantUseCase(new MerchantApi());
const globalUseCase = new GlobalResourceUseCase(new GlobalResourceApi());

export default function () {
    const viewModel = useTransferUangAlfaViewModel({ useCase, bankUseCase, merchantUseCase, globalUseCase });

    return <TransferUangAlfaPage viewModel={viewModel} />;
}
