import GlobalResourceApi from "data/GlobalResource";
import TransactionAPI from "data/transaction";
import GlobalResourceUseCase from "domain/interactor/GlobalResource";
import TransactionUseCase from "domain/interactor/Transaction/TransactionUseCase";
import ListTransaksiPage from "presentation/pages/view/list-transaksi/ListTransaksiPage";
import useListTransaksiViewModel from "presentation/pages/viewmodel/list-transaksi";

const api = new TransactionAPI();
const useCase = new TransactionUseCase(api);
const globalUseCase = new GlobalResourceUseCase(new GlobalResourceApi());

export default function ListTransaksi() {
    const viewModel = useListTransaksiViewModel({ useCase, globalUseCase });

    return <ListTransaksiPage viewModel={viewModel} />;
}
