import React from "react";
import { useTheme, Stack, Typography, CircularProgress } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import usePINViewModel from "presentation/pages/viewmodel/auth-pin";
import { ALL_DIGIT } from "presentation/validation/constants";
import useLanguage from "config/hooks/useLanguage";

export type FormPINProps = {
    onSubmit?: Promise<any> | any;
    loading?: boolean;
};

const FormPIN: React.FC<FormPINProps> = (props) => {
    const { t } = useLanguage();

    const { palette } = useTheme();

    const { loading } = props;

    const viewModel = usePINViewModel(props);

    return (
        <Stack alignItems="center" sx={{ textAlign: "center" }} spacing={3}>
            <Typography sx={{ fontWeight: 700 }}>
                {t("transaction.input_pin_trx")}
            </Typography>
            <Typography sx={{ fontSize: ".75rem" }}>
                {t("transaction.input_pin")}
            </Typography>
            <MuiOtpInput
                TextFieldsProps={{
                    type: "number",
                    inputProps: {
                        inputMode: "numeric",
                    },
                    autoComplete: "off",
                    sx: {
                        "& input[type=number]::-webkit-outer-spin-button": {
                            appearance: "none",
                            margin: 0,
                        },
                        "& input[type=number]::-webkit-inner-spin-button": {
                            appearance: "none",
                            margin: 0,
                        },
                        "& input[type=number]": {
                            appearance: "textfield",
                        },
                    },
                    disabled: loading,
                }}
                length={6}
                value={viewModel.pin}
                onChange={viewModel.handleChange}
                validateChar={(value) => {
                    return ALL_DIGIT.test(value) || value === "";
                }}
                gap={1}
            />
            {loading && <CircularProgress size={64} />}
            {viewModel.pinError && (
                <Typography
                    sx={{ fontSize: ".625rem", color: palette.error.main }}
                >
                    {t("transaction.w_input_pin")}
                </Typography>
            )}
        </Stack>
    );
};

export default FormPIN;
