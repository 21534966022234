const getFileFromBase64 = (string64: string | null, fileName: string) => {
    const type = "image/jpeg";

    if (string64 !== null) {
        const buf = window.atob(string64.split(",")[1]);
        const buffer = new Uint8Array(buf.length);

        for (let i = 0; i < buffer.length; i++) {
            buffer[i] = buf.charCodeAt(i);
        }

        const blob = new Blob([buffer], { type });

        return new File([blob], fileName, {
            lastModified: new Date().getTime(),
            type,
        });
    }

    return null;
};


export default getFileFromBase64