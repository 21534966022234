import React from "react";
import useLanguage from "config/hooks/useLanguage";
import { emailObfuscate, phoneObfuscate } from "config/shared/utils/obfuscate";
import { OTPRequest } from "domain/entity/OTP";
import log from "config/log";
import OTPUseCase from "domain/interactor/OTP";
import OTPApi from "data/OTP";
import { useMutation } from "react-query";
import { GlobalContext } from "config/context/GlobalContext";
import Typography from "@mui/material/Typography";
import { Box, Stack, useTheme } from "@mui/material";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";

export type OTPPayload = {
    sendWhere?: "email" | "phone";
} & OTPRequest;

export type OTPViewModelProps<T = any> = {
    otpRequest?: OTPPayload | null;
    postOTP?: (...args: any[]) => Promise<T>;
    onSuccess?: () => void;
    storageKey?: string;
    method?: string;
};

const useCase = new OTPUseCase(new OTPApi());

const SECOND = 1000; // second in ms

const cooldDownMap = new Map([
    [0, 15 * SECOND],
    [1, 30 * SECOND],
    [2, 45 * SECOND],
    [3, 60 * 60 * SECOND],
]);

const RESEND_LIMIT = 5;

export type TimerData = {
    startTime?: number; // start time otp dikirim
    blockCount?: number; // jumlah berapa kali /otp di invoke
    blocked?: boolean;
};

/**
 * data OTP akan di clear dari localStorage
 */

export type TimerObj = {
    [key: string]: TimerData | null | undefined;
};

/**
 *  untuk timer otp didapat dari hasil selisih waktu yang tercatat di local storage dengan current time.
 *  object timer dalam local storage harus memiliki key karena request otp yang berbeda maka timer juga berbeda di DB
 *
 *  note:
 *  seharusnya nanti waktu mulai didapat dari response OTP dari BE ?
 */

const useOTPViewModel = <T,>(props: OTPViewModelProps<T>) => {
    const [_, dispatch] = React.useContext(GlobalContext);

    const { palette } = useTheme();

    const { t } = useLanguage();

    const { otpRequest, postOTP, onSuccess, storageKey = "test-timer" } = props;

    const OTP_LENGTH = 5;

    const headerValue = React.useMemo(() => {
        let formatedData = "";
        let sendType = "";

        if (!otpRequest) return { formatedData, sendType };

        const { email, phone, sendWhere, method } = otpRequest;

        if (sendWhere === "email" && email) {
            formatedData = emailObfuscate(email);
            sendType = t("otp.send_via_email") ?? "";
        }

        if (sendWhere === "phone" && phone && method === "sms") {
            formatedData = phoneObfuscate(phone);
            sendType = t("otp.send_via_phone") ?? "";
        }

        if (sendWhere === "phone" && phone && method === "whatsapp") {
            formatedData = phoneObfuscate(phone);
            sendType = t("otp.send_via_whatsapp") ?? "";
        }

        return { formatedData, sendType };
    }, [otpRequest?.email, otpRequest?.phone, otpRequest?.type]);

    const [otpInput, setOtpInput] = React.useState("");

    const [otpError, setOtpError] = React.useState("");

    const [otpBlock, setOtpBlock] = React.useState(false);

    const [timerRunning, setTimerRunning] = React.useState(false);

    const otpCounter = React.useRef(0);

    const [otpTimerLeft, setOtpTimerLeft] = React.useState(
        cooldDownMap.get(otpCounter.current) || 0
    );

    const otpTimerText = React.useMemo(() => {
        const _time = Math.floor(otpTimerLeft / 1000);

        const _minute = Math.floor(_time / 60);
        const _second = Math.floor(_time % 60);

        let res = "";

        res += `${_minute.toString().padStart(2, "0")}:${_second
            .toString()
            .padStart(2, "0")}`;
        return res;
    }, [otpTimerLeft]);

    const timerRef = React.useRef<NodeJS.Timer | null>(null);

    const handleChange = (e: string) => setOtpInput(e);

    const {
        mutateAsync: submitOTP,
        isLoading,
        isError,
        isSuccess,
    } = useMutation("/submit-otp", ({ otpPayload, method, username }: { otpPayload: string, method: string, username: string }) => {
        if (postOTP !== undefined) {
            return postOTP(otpPayload, method, username);
        }
        return new Promise((res) => res("default")); // ini digunain buat jaga optional postOTP, buang optional kalo udah jalan
    });

    const { mutateAsync: retryOTP, isLoading: retryLoading } = useMutation(
        "send-otp",
        (body: OTPRequest) => useCase.postOTP(body)
    );

    const startOtpTimer = () => {
        if (timerRef.current) return;

        if (otpCounter.current < RESEND_LIMIT) {
            otpCounter.current += 1;
        } else {
            setOtpBlock(true);
        }

        timerRef.current = setInterval(() => {
            setOtpTimerLeft((value) => value - SECOND);
        }, SECOND);

        setTimerRunning(true);

        /** register Timer data into localstorage */

        const timerData: TimerData = {
            blockCount: otpCounter.current,
            startTime: new Date().getTime(),
        };

        localStorage.setItem(
            "otp",
            JSON.stringify({ [storageKey]: timerData })
        );
    };

    const resumeOTPTimer = () => {
        if (timerRef.current) return;

        timerRef.current = setInterval(() => {
            setOtpTimerLeft((value) => value - SECOND);
        }, SECOND);

        setTimerRunning(true);
    };

    const onSubmit = async () => {
        try {
            if (!otpRequest) return;
            /** should add start timer here */

            /** */
            await submitOTP({ otpPayload: otpInput, method: props?.method ?? "sms", username: (props.method == "email" ? otpRequest?.email : otpRequest?.phone) ?? "" });

            onSuccess?.();
        } catch (err) {
            const { response } = err as any; // should only handle axios Error

            if (response && response.data) {
                const { message, rc } = response.data;
                log("err msg: ", message);
                setOtpError(message);
                if (rc === "0053") {
                    setOtpBlock(true);
                }
            }

            // clear OTP Field
            setOtpInput("");
        }
    };

    const onRetry = async () => {
        try {
            if (!otpRequest) return;

            const result = await retryOTP({ ...otpRequest });

            const {
                data: { blockCount },
            } = result;

            otpCounter.current = blockCount - 1;

            startOtpTimer();
        } catch (err) {
            const { response } = err as any; // should only handle axios Error

            if (response && response.data) {
                const { rc, message } = response.data;
                log("err msg: ", message);
                setOtpError(message);
                if (rc === "0053") {
                    setOtpBlock(true);
                }
            }
            // clear OTP Field
            setOtpInput("");
        }
    };

    const onHubungiCS = () => {
        dispatch({
            type: "SHOW_OVERLAY",
            payload: {
                header: "Informasi Customer Service",
                subHeader: (
                    <Stack gap=".5rem">
                        <Typography
                            sx={{
                                fontSize: ".75rem",
                                color: palette.text.secondary,
                            }}
                        >
                            Butuh bantuan? Silahkan hubungi kontak Customer
                            Service kami dibawah ini.
                        </Typography>
                        <Box display="flex">
                            <PhoneRoundedIcon sx={{ fontSize: "1rem" }} />
                            <Typography
                                sx={{
                                    fontSize: ".75rem",
                                    color: palette.text.secondary,
                                    flex: 1,
                                }}
                            >
                                021 - 50127667
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <MailRoundedIcon sx={{ fontSize: "1rem" }} />
                            <Typography
                                sx={{
                                    fontSize: ".75rem",
                                    color: palette.text.secondary,
                                    flex: 1,
                                }}
                            >
                                cs@rem.id
                            </Typography>
                        </Box>
                    </Stack>
                ),
            },
        });
    };

    React.useEffect(() => {
        // clear error when user retyping otp
        if (otpInput.length && otpError !== "") setOtpError("");
    }, [otpInput]);

    React.useEffect(() => {
        if (otpTimerLeft <= 0 && timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
            setOtpTimerLeft(cooldDownMap.get(otpCounter.current) || 0);
            setTimerRunning(false);
        }
    }, [otpTimerLeft]);

    /**
     *  check apakah ada sisa OTP saat component pertama kali di mount
     */
    React.useEffect(() => {
        const timerObjLocalStorage = JSON.parse(
            localStorage.getItem("otp") || "null"
        ) as TimerObj | null;

        if (!timerObjLocalStorage) return;

        const timerData = timerObjLocalStorage[storageKey];

        if (!timerData) return;

        const { startTime, blockCount } = timerData;

        const blockCountNumber = Number(blockCount);

        if (blockCountNumber) {
            /** should check startTimer < 1hour of current time */
            const time = new Date().getTime();

            const diff = time - (startTime || 0);

            /** blockCountNumber - 1 digunakan untuk mengambil previous value dari coolDownMap */

            const currentCoolDown = cooldDownMap.get(blockCountNumber - 1) || 0;

            const shouldResumeOTP = diff <= currentCoolDown;

            if (shouldResumeOTP) {
                setOtpTimerLeft(currentCoolDown - diff);
                log("timer resumed");
                /** resume time left */
                resumeOTPTimer();
            } else {
                setOtpTimerLeft(cooldDownMap.get(blockCountNumber) || 0);
            }
            otpCounter.current = blockCountNumber;
        }
    }, []);

    return {
        OTP_LENGTH,
        headerValue,
        otpInput,
        handleChange,
        isLoading,
        isError,
        isSuccess,
        onSubmit,
        otpError,
        onRetry,
        otpBlock,
        otpTimerLeft,
        otpTimerText,
        timerRunning,
        retryLoading,
        onHubungiCS,
        palette,
    };
};

export default useOTPViewModel;
