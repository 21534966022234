import InformationAPI from "data/Information";
import InformationUseCase from "domain/interactor/Information";
import InformationPage from "presentation/pages/view/information/InformationPage";
import useListBannerViewModel from "presentation/pages/viewmodel/information";

const api = new InformationAPI();
const bannerUseCase = new InformationUseCase(api);

export default function Information() {
    const viewModel = useListBannerViewModel({ bannerUseCase });

    return <InformationPage viewModel={viewModel} />;
}