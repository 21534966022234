import IAuthRepository from "domain/repository/Auth";
import {LoginRequest} from 'domain/entity/Auth/model/Login'

export default class AuthUseCase {
    private service: IAuthRepository;

    constructor(service: IAuthRepository) {
        this.service = service;
    }

    execute(body: LoginRequest) {
        // const { username, password } = body;

        // if (username === "081228057969") {
        //     throw {
        //         name: "phone",
        //         message: "Nomor handphone Anda belum terdaftar",
        //     };
        // }

        // if (username === "aditya.ryo@ids.id") {
        //     throw {
        //         name: "email",
        //         message: "Email Anda belum terdaftar",
        //     };
        // }

        // if (username === "test@test.com" && password === "test") {
        //     throw {
        //         name: "password",
        //         message: "Kata sandi yang Anda Masukan salah!",
        //     };
        // }

        return this.service.doLogin(body);
    }
}
