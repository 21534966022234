import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import ButtonRemid from "core/components/remid-button/Button";
import QRCode, { QRCodeProps } from "react-qr-code";
import log from "config/log";
import useLanguage from "config/hooks/useLanguage";

type QRCodeComponentProps = {
    value: string;
    onBack?: () => void;
};

const QRCodeComponent: React.FC<QRCodeComponentProps> = (props) => {
    const { t } = useLanguage();

    const { value = "", onBack } = props;

    const [level, setLevel] = React.useState<QRCodeProps["level"]>("L");

    const [qrCodeData, setQRCodeData] = React.useState(props.value);

    const navigate = useNavigate();

    const handleBack = () => {
        if (typeof onBack === "function") {
            onBack();
        } else {
            navigate(-1);
        }
    };

    React.useEffect(() => {
        log("Setting up interval with value:", props.value);
        const interval = setInterval(() => {
            log("Updating QR code with value:", props.value);
            setLevel((prevLevel) =>
                prevLevel === "L"
                    ? "M"
                    : prevLevel === "M"
                        ? "Q"
                        : prevLevel === "Q"
                            ? "H"
                            : "L"
            );
        }, 10000);

        return () => {
            log("Clearing interval");
            clearInterval(interval);
        };
    }, [props?.value]);

    log("Rendering QR code with value:", qrCodeData);
    return (
        <Stack spacing={3} justifyContent="center" alignItems="center">
            <Typography sx={{ fontSize: ".875rem" }}>
                {t("general.showqr")}
            </Typography>

            <QRCode
                size={256}
                style={{
                    height: "auto",
                    maxWidth: "256px",
                    width: "100%",
                    maxHeight: "256px",
                    marginTop: "1rem",
                }}
                value={value}
                level={level}
                viewBox={`0 0 256 256`}
            />

            <Typography
                sx={{
                    fontWeight: 600,
                    p: "1rem",
                    maxWidth: "32ch",
                    wordWrap: "break-word",
                    textAlign: "center",
                }}
            >
                {value}
            </Typography>

            <ButtonRemid onClick={handleBack}>{t("general.close")}</ButtonRemid>
        </Stack>
    );
};

export default QRCodeComponent;
