import React from "react";
import LayoutAppBar, {
    LayoutAppBarProps,
} from "config/components/layout-app-bar";
import FormOTP, { FormOTPProps } from "config/components/form-otp";

type OTPMobileLayoutProps = {} & FormOTPProps &
    Omit<LayoutAppBarProps, "children">;

const OTPMobileLayout: React.FC<OTPMobileLayoutProps> = (props) => {
    const { onBack, title = "Verifikasi", primary, ...rest } = props;

    return (
        <LayoutAppBar title={title} primary={primary} onBack={onBack}>
            <FormOTP {...rest} />
        </LayoutAppBar>
    );
};

export default OTPMobileLayout;
