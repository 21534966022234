import { ChangeEmailRequest, ChangeEmailResponse, VerifyEmailRequest, VerifyEmailResponse } from "domain/entity/Email";
import EmailRepository from "domain/repository/Email";

export default class EmailUseCase {
    constructor(private repo: EmailRepository) { }

    postChangeEmail(body: ChangeEmailRequest): Promise<ChangeEmailResponse> {
        return this.repo.postChangeEmail(body)
    }

    postVerifyEmail(body: VerifyEmailRequest): Promise<VerifyEmailResponse> {
        return this.repo.postVerifyEmail(body)
    }
}