import ForgotPasswordRequest from "domain/entity/Auth/model/FormForgotPassword";
import IAuthRepository from "domain/repository/Auth";

class ForgotPasswordUseCase {
    private service: IAuthRepository;

    constructor(service: IAuthRepository) {
        this.service = service;
    }

    execute(body: ForgotPasswordRequest) {
        return this.service.doResetPassword(body);
    }
}

export default ForgotPasswordUseCase;
