import { ChangePhoneRequest, ChangePhoneResponse } from "domain/entity/Phone";
import PhoneRepository from "domain/repository/Phone";


export default class PhoneUseCase {
    constructor(private repo: PhoneRepository) { }

    postChangePhone(body: ChangePhoneRequest): Promise<ChangePhoneResponse> {
        return this.repo.postChangePhone(body)
    }
}