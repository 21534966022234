import { Box } from "@mui/material";
import { ChangePhoneViewModel } from "presentation/pages/viewmodel/change-phone";
import FormChangePhone from "presentation/pages/view/change-phone/components/form-change-phone";
import React from "react";
import OTPMobileLayout from "config/components/layout-otp/Mobile";
import LayoutAppBar from "config/components/layout-app-bar";
import Message from "config/components/message";
import useLanguage from "config/hooks/useLanguage";
import SendToMobileLayout from "config/components/layout-send-otp-to";

type ChangePhonePageProps = {
    viewModel: ChangePhoneViewModel;
};

const ChangePhonePage: React.FC<ChangePhonePageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    // return viewModel.success ? (
    //     <Box sx={{ height: "100vh", px: "2rem" }}>
    //         <Message
    //             header={t("general.header1")}
    //             subHeader={t("general.subheader1")}
    //             btnText={t("auth.back")}
    //             onClick={viewModel.handleSuccessRedirect}
    //             type="success"
    //         />
    //     </Box>
    // ) : viewModel.showOTP ? (
    //     <OTPMobileLayout
    //         primary="white"
    //         otpRequest={viewModel.otpData}
    //         postOTP={viewModel.onVerifySubmit}
    //         storageKey="change-phone"
    //         onSuccess={viewModel.handleSuccessVerifyPhone}
    //         onBack={() => viewModel.setShowOTP(false)}
    //     />
    // ) : (
    //     <LayoutAppBar title={t("general.title2")} primary="red">
    //         <FormChangePhone {...props} />
    //     </LayoutAppBar>
    // );

    return viewModel.success ? (
        <Box sx={{ height: "100vh", px: "2rem" }}>
            <Message
                header={t("general.header1")}
                subHeader={t("general.subheader1")}
                btnText={t("auth.back")}
                onClick={viewModel.handleSuccessRedirect}
                type="success"
            />
        </Box>
    ) : viewModel.formSubmitted ? (
        <SendToMobileLayout
            primary="white"
            onBack={viewModel.onVerificationBack}
            type={"change-phone"}
            otpMethodList={{
                sms: viewModel.getValues('phoneNumber'),
                whatsapp: viewModel.getValues('phoneNumber'),
            }}
            postOTP={viewModel.onVerifySubmit}
            onSuccess={() => viewModel.setSuccess(true)}
            storageKey="change-phone"
        />
    ) : (
        <LayoutAppBar title={t("general.title2")} primary="red">
            <FormChangePhone {...props} />
        </LayoutAppBar>
    );
};

export default ChangePhonePage;
