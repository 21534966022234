import React from "react";
import { useForm } from "react-hook-form";
import { userIdentityValidation } from "presentation/validation/userIden";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OTPData } from "config/shared/types";
import { ALL_DIGIT, EMAIL } from "presentation/validation/constants";
import { useNavigate } from "react-router-dom";
import OTPUseCase from "domain/interactor/OTP";
import ForgotPinUseCase from "domain/interactor/Auth/ForgotPinUseCase";
import { OTPRequest } from "domain/entity/OTP";
import { useMutation } from "react-query";
import { GlobalContext } from "config/context/GlobalContext";
import log from "config/log";
import { OTPPayload } from "../auth-otp";
import useLanguage from "config/hooks/useLanguage";

const initialValues = {
    username: "",
};

const validationSchema = yup.object({
    username: userIdentityValidation,
});

type ForgotPinUseCases = {
    useCase: ForgotPinUseCase;
    otpUseCase: OTPUseCase;
};

const useForgotPinViewModel = ({
    otpUseCase,
    useCase,
}: ForgotPinUseCases) => {
    const { t } = useLanguage();

    const [_, dispatch] = React.useContext(GlobalContext);

    const [{ user }] = React.useContext(GlobalContext)

    const navigate = useNavigate();

    const [showOTP, setShowOTP] = React.useState(false);

    const [success, setSuccess] = React.useState(false);

    const [formSubmitted, setFormSubmitted] = React.useState(false);

    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
    });

    const { mutateAsync: postOTP, isLoading: otpLoading } = useMutation(
        "send-otp",
        (body: OTPRequest) => otpUseCase.postOTP(body)
    );

    const _username = watch("username");

    // const otpData: OTPData = {
    //     type: ALL_DIGIT.test(_username) ? "phone" : "email",
    //     value: _username,
    // };

    // const otpData: OTPPayload = {
    //     userId: user?.userId ? user?.userId.toString() : "",
    //     phone: ALL_DIGIT.test(_username) ? _username : "",
    //     email: EMAIL.test(_username) ? _username : "",
    //     method: "",
    //     type: "forgot-pin",
    //     sendWhere: ALL_DIGIT.test(_username) ? "phone" : "email",
    // };

    const submitButtonStatus = _username !== "";

    const onSubmit = () => {
        setFormSubmitted(true)
        // try {
        //     const { userId, email, phone, method, type } = otpData;

        //     postOTP({
        //         userId,
        //         email,
        //         phone,
        //         method,
        //         type,
        //     })
        //         .then(() => {
        //             setShowOTP(true);
        //         })
        //         .catch((err) => {
        //             const { response } = err;

        //             if (response && response.data) {
        //                 const { message, rc } = response.data;
        //                 console.log('log: ', rc, message)

        //                 dispatch({
        //                     type: "SHOW_OVERLAY",
        //                     payload: {
        //                         header: t("general.general_error"),
        //                         type: "warning",
        //                         btnText: "Kembali",
        //                         subHeader: t("rc." + rc),
        //                     },
        //                 });
        //             }
        //         });
        // } catch (error) {
        //     log(error);
        // }
    };

    const otpStringRef = React.useRef<string | null>(null);

    const onPostOTP = (otp: string, method: string, username: string) => {
        otpStringRef.current = otp;
        return useCase.execute({ otp, pin: "", username, method })
            .then(() => navigate("/change-pin", {
                state: {
                    parent: "/dashboard/profile"
                    , datas: {
                        otp: otpStringRef.current,
                        username,
                        method
                    },
                },
            }));
    };

    const onVerificationSubmit = () => {
        console.log('submit')

        // setSuccess(true);
    };

    const onVerificationBack = () => {

    };

    // const onVerificationSuccess = () => {
    //     // redirect to login karena memang belum login
    //     navigate("/change-pin", {
    //         state: {
    //             parent: "/login",
    //             datas: {
    //                 otp: otpStringRef.current,
    //                 username: _username,
    //             },
    //         },
    //     });
    // };

    return {
        register,
        handleSubmit,
        onSubmit,
        errors,
        submitButtonStatus,
        showOTP,
        onVerificationBack,
        // onVerificationSuccess,
        // otpData,
        onVerificationSubmit,
        onPostOTP,
        setSuccess,
        getValues,
        formSubmitted,
        user,
        success
    };
};

export type ForgotPinViewModel = ReturnType<typeof useForgotPinViewModel>;

export default useForgotPinViewModel;
