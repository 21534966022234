import React from "react";
import { Box } from "@mui/material";
import Message from "config/components/message";
import { ChangePinViewModel } from "presentation/pages/viewmodel/change-pin";
import FormChangePin from "presentation/pages/view/change-pin/components/form-change-pin";
import LayoutAppBar from "config/components/layout-app-bar";
import useLanguage from "config/hooks/useLanguage";

type ChangePinPageProps = {
    viewModel: ChangePinViewModel;
};

const ChangePinPage: React.FC<ChangePinPageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    return viewModel.success ? (
        <Box sx={{ height: "100vh", px: "2rem" }}>
            <Message
                header={
                    viewModel.usingOldPin
                        ? t("general.header1")
                        : t("general.header2")
                }
                subHeader={
                    viewModel.usingOldPin
                        ? t("general.subheader3")
                        : t("general.subheader4")
                }
                type="success"
                onClick={viewModel.handleSuccessRedirect}
            />
        </Box>
    ) : (
        <LayoutAppBar
            title={viewModel.usingOldPin ? t("general.title3") : t("general.title4")}
            primary="red"
        >
            <FormChangePin {...props} />
        </LayoutAppBar>
    );
};

export default ChangePinPage;
