import React from "react";
import Router from "presentation/router";
import { GlobalContext } from "config/context/GlobalContext";
import { muiTheme } from "config/theme";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import OverlayModal from "config/components/overlay-modal";
import SplashScreen from "./SplashScreen";
import { Http } from "config/infra";
import { clearLocalStorage } from "config/storage";
import useLanguage from "config/hooks/useLanguage";
import log from "config/log";
import SnackbarProvider from "config/context/Snackbar";

const reactQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false, // default: true
        },
    },
});

const App = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const isAndroid = /Android/.test(navigator.userAgent);

    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const [showSplashScreen, setShowSplashScreen] = React.useState(true);

    const { t } = useLanguage();

    const navigate = useNavigate();

    //TODO: ubah menjadi fungsi pengecekan
    const isLoggedIn = React.useMemo(
        () => Boolean(user?.email) || Boolean(user?.phone),
        [user]
    );

    // ane comment dulu gan popup jadi ndak keluar itu
    /** http interceptor untuk mendeteksi token expired */
    Http.interceptors.response.use(
        (response) => response,
        (err) => {
            const { response } = err;
            if (response) {
                const { status } = response;
                if (status === 401) {
                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.overlay3"),
                            subHeader: t("general.overlay4"),
                            type: "warning",
                            onClick: () => {
                                clearLocalStorage();
                                window.location.href = "/";
                            },
                        },
                    });
                }
            }
            // return err
            return Promise.reject(err);
        }
    );

    React.useEffect(() => {
        // Hide the splash screen after a few seconds
        const timer = setTimeout(() => {
            setShowSplashScreen(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        // <BrowserRouter>
        <>
            {showSplashScreen && isIOS ? (
                <SplashScreen />
            ) : (
                <QueryClientProvider client={reactQueryClient}>
                    <ThemeProvider theme={muiTheme}>
                        <SnackbarProvider>
                            <Router isLoggedIn={isLoggedIn} />
                            <OverlayModal />
                        </SnackbarProvider>
                    </ThemeProvider>
                </QueryClientProvider>
            )}
        </>
        // </BrowserRouter>
    );
};

export default App;
