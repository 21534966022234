import React from "react";
import { Alert, Typography, Stack, Box, MobileStepper } from "@mui/material";
import ButtonRemid from "core/components/remid-button/Button";

type ConfirmIdentityGuideProps = {
    icon?: React.ReactNode;
    header?: string;
    subHeader?: string;
    alert?: string;
    step?: number;
    btnGuideText?: string;
    onClick?: () => void;
};

const ConfirmIdentityGuide: React.FC<ConfirmIdentityGuideProps> = (props) => {
    const { btnGuideText, onClick, step, icon, alert, header, subHeader } =
        props;

    const handleClick = () => {
        onClick?.();
    };

    return (
        <Stack
            sx={{ flex: 1 }}
            spacing={3}
            justifyContent="center"
            alignItems="center"
        >
            <Box sx={{ maxWidth: 300 }}>{icon}</Box>
            <Box sx={{ flex: 1 }}>
                <Typography
                    sx={{ fontWeight: 600, fontSize: "1.25rem" }}
                    textAlign="center"
                >
                    {header}
                </Typography>
                <Typography sx={{ fontSize: ".75rem" }} textAlign="center">
                    {subHeader}
                </Typography>
            </Box>
            {alert && (
                <Alert severity="warning" sx={{ fontSize: ".75rem" }}>
                    {alert}
                </Alert>
            )}

            <Stack
                direction="row"
                justifyContent="space-around"
                sx={{ width: "100%" }}
            >
                <MobileStepper
                    variant="dots"
                    position="static"
                    steps={3}
                    activeStep={step}
                    sx={{
                        maxWidth: 400,
                        "& .MuiMobileStepper-dot.MuiMobileStepper-dotActive": {
                            background: "#f00",
                        },
                    }}
                    backButton={null}
                    nextButton={null}
                />
                <ButtonRemid onClick={handleClick}>{btnGuideText}</ButtonRemid>
            </Stack>
        </Stack>
    );
};

export default ConfirmIdentityGuide;
