import IAuthRepository from "domain/repository/Auth";
import { LoginRequest, LoginResponse } from 'domain/entity/Auth/model/Login'
import ForgotPasswordRequest from "domain/entity/Auth/model/FormForgotPassword";
import { post } from 'config/infra';
import { PraRegisterRequest, RegisterRequest, RegisterResponse } from "domain/entity/Auth/model/Register";
import { GenericResponse } from "domain/entity";
import { ForgotPinRequest } from "domain/entity/Auth/model/Pin";
import { SSOLoginRequest, SSOLoginResponse } from "domain/entity/Auth/model/SSOLogin";

export default class AuthApi implements IAuthRepository {
    async doLogin(body: LoginRequest): Promise<LoginResponse> {
        return post<LoginResponse, LoginRequest>('/login', body)
    }

    async doSSOLogin(body: SSOLoginRequest): Promise<SSOLoginResponse> {
        return post<SSOLoginResponse, SSOLoginRequest>('/ssologin', body)
    }

    async doRegister(body: any): Promise<any> {
        return post<RegisterResponse, RegisterRequest>('/register', body)
    }

    async doPraRegister(body: PraRegisterRequest): Promise<any> {
        return post<RegisterResponse, PraRegisterRequest>('/pra-register', body)
    }

    async doResetPassword(body: ForgotPasswordRequest): Promise<GenericResponse> {
        return post('/forgot_password', body)
    }

    async doResetPin(body: ForgotPinRequest): Promise<GenericResponse> {
        return post('/pin/forgot', body)
    }
}