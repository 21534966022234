import { ChangePinRequest, ChangePinResponse, ForgotPinRequest, ForgotPinResponse, SetupPinRequest, SetupPinResponse } from "domain/entity/Auth/model/Pin";
import PinRepository from "domain/repository/Pin";
import { post } from "config/infra";


export default class PinApi implements PinRepository {
    async postSetPin(body: SetupPinRequest): Promise<SetupPinResponse> {
        return post<SetupPinResponse, SetupPinRequest>('/pin/set', body)
    }

    async postChangePin(body: ChangePinRequest): Promise<ChangePinResponse> {
        return post<ChangePinResponse, ChangePinRequest>('/pin/change', body)
    }

    async postForgotPin(body: ForgotPinRequest): Promise<ForgotPinResponse> {
        return post<ForgotPinResponse, ForgotPinRequest>('/pin/forgot', body)
    }
}