import OTPApi from "data/OTP";
import PhoneApi from "data/Phone";
import OTPUseCase from "domain/interactor/OTP";
import PhoneUseCase from "domain/interactor/Phone";
import ChangePhonePage from "presentation/pages/view/change-phone/ChangePhonePage";
import useChangePhoneViewModel from "presentation/pages/viewmodel/change-phone";

const useCase = new PhoneUseCase(new PhoneApi());
const otpUseCase = new OTPUseCase(new OTPApi());

export default function () {
    const viewModel = useChangePhoneViewModel({ phoneUseCase: useCase, otpUseCase });

    return <ChangePhonePage viewModel={viewModel} />;
}
