import IAuthRepository from "domain/repository/Auth";
import { SSOLoginRequest } from 'domain/entity/Auth/model/SSOLogin'

export default class AuthUseCase {
    private service: IAuthRepository;

    constructor(service: IAuthRepository) {
        this.service = service;
    }

    execute(body: SSOLoginRequest) {
        return this.service.doSSOLogin(body);
    }
}
