import EmailApi from "data/Email";
import OTPApi from "data/OTP";
import EmailUseCase from "domain/interactor/Email";
import OTPUseCase from "domain/interactor/OTP";
import useVerifyEmail from "presentation/pages/viewmodel/verify-email";
import VerifyEmailPage from "./VerifyEmailComponent";

const useCase = new EmailUseCase(new EmailApi());
const otpUseCase = new OTPUseCase(new OTPApi());

export default function () {
    const viewModel = useVerifyEmail({ emailUseCase: useCase, otpUseCase });

    return <VerifyEmailPage viewModel={viewModel} />;
}
