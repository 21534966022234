import ReversalApi from "data/Reversal";
import ReversalUseCase from "domain/interactor/Reversal";
import CancelUangPage from "presentation/pages/view/cancel-uang/CancelPage";
import useCancelUangViewModel from "presentation/pages/viewmodel/cancel-uang";

const useCase = new ReversalUseCase(new ReversalApi());

export default function CancelUang() {
    const viewModel = useCancelUangViewModel(useCase);

    return <CancelUangPage viewModel={viewModel} />;
}
