import TransferUangPage from "presentation/pages/view/transfer-uang/TransferUangPage";
import useTransferUangViewModel from "presentation/pages/viewmodel/transfer-uang";
import DepositApi from "data/Deposit";
import DepositUseCase from "domain/interactor/Deposit";
import GlobalResourceUseCase from "domain/interactor/GlobalResource";
import GlobalResourceApi from "data/GlobalResource";
import MerchantUseCase from "domain/interactor/Merchant";
import MerchantApi from "data/Merchant";

const useCase = new DepositUseCase(new DepositApi());
const globalUseCase = new GlobalResourceUseCase(new GlobalResourceApi());
const merchantUseCase = new MerchantUseCase(new MerchantApi());

export default function () {
    const viewModel = useTransferUangViewModel({ useCase, globalUseCase, merchantUseCase });

    return <TransferUangPage viewModel={viewModel} />;
}
