import React from "react";
import { useTheme, Box, SxProps, Typography } from "@mui/material";
import ButtonRemid from "core/components/remid-button";
import LanguageSelector from "config/components/lang-selector";
import AuthLayoutInterface from "config/components/layout-auth/AuthLayoutInterface";

type AuthMobileLayoutProps = {} & AuthLayoutInterface;

const AuthMobileLayout: React.FC<AuthMobileLayoutProps> = (props) => {
    const { palette } = useTheme();

    const style: SxProps = { height: "100vh" };

    const {
        header,
        subHeader,
        childForm,
        bottomText,
        bottomTextNews,
        navigateText,
        navigateTextNews,
        onTextClickNavigate,
        onTextClickNavigateNews,
    } = props;

    return (
        <Box sx={style}>
            {/* buka dulu */}
            {/* <LanguageSelector sx={{ position: "absolute", top: 4, right: 0 }} /> */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    backgroundColor: palette.primary.main,
                    backgroundImage: "url('/svg/SignInHeaderLine.svg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "top right",
                    height: "37.5%",
                    maxHeight: 300,
                    color: "#fff",
                }}
            >
                <Box sx={{ ml: "1rem" }}>
                    <Typography variant="h5" sx={{ fontWeight: 700 }}>
                        {header}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {subHeader}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ p: "1rem" }}>{childForm}</Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography sx={{ fontSize: ".75rem" }}>
                    {bottomText}
                </Typography>
                <ButtonRemid variant="text" onClick={onTextClickNavigate}>
                    {navigateText}
                </ButtonRemid>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography sx={{ fontSize: ".75rem" }}>
                    {bottomTextNews}
                </Typography>
                <ButtonRemid variant="text" onClick={onTextClickNavigateNews}>
                    {navigateTextNews}
                </ButtonRemid>
            </Box>
        </Box>
    );
};

export default AuthMobileLayout;
