import { Stack, Box, Button } from "@mui/material";
import React from "react";
import ButtonRemid from "core/components/remid-button/Button";

type PreviewImageProps = {
    image?: string;
    nextCB?: () => void;
    retryCB?: () => void;
};

const PreviewImage: React.FC<PreviewImageProps> = (props) => {
    const { image, nextCB, retryCB } = props;

    return (
        <Stack sx={{ flex: 1 }}>
            <img
                src={image}
                style={{
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 3,
                    width: "80%",
                    height: "auto",
                    maxWidth: "768px",
                }}
            />
            <Stack>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        mt: ".5rem",
                    }}
                >
                    <ButtonRemid onClick={nextCB}>Gunakan</ButtonRemid>
                </Box>
                <Button variant="text" onClick={retryCB}>
                    ULANGI
                </Button>
            </Stack>
        </Stack>
    );
};

export default PreviewImage;
