import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'
import EmailUseCase from 'domain/interactor/Email'
import { OTPRequest } from 'domain/entity/OTP'
import OTPUseCase from 'domain/interactor/OTP'
import { GlobalContext } from 'config/context/GlobalContext'
import { OTPPayload, TimerObj } from '../auth-otp'
import useLanguage from 'config/hooks/useLanguage'

const initialValues = {
    email: "",
};

const validationSchema = yup.object({
    email: yup.string().required("validation.email_required").email("Format email salah, contoh: rem@remid.com"),
});

type VerifyEmailUseCases = {
    emailUseCase: EmailUseCase;
    otpUseCase: OTPUseCase
}

const useVerifyEmail = ({ emailUseCase, otpUseCase }: VerifyEmailUseCases) => {
    const { t } = useLanguage();

    const [{ user }, dispatch] = React.useContext(GlobalContext)

    const navigate = useNavigate()

    const [success, setSuccess] = React.useState(false)

    const [showOTP, setShowOTP] = React.useState(false)

    const { handleSubmit,
        register,
        formState: { errors, isValid }, getValues, setValue } = useForm({ defaultValues: initialValues, resolver: yupResolver(validationSchema) })

    const submitButtonStatus = isValid

    const {
        mutateAsync: postVerifyEmailOTP,
        isLoading: loadingOTP,
    } = useMutation("send-otp", (body: OTPRequest) => otpUseCase.postOTP(body));

    const otpDataRef = React.useRef<OTPPayload | null>(null)

    const onRequestOTP = () => {
        const userId = user?.userId ? user?.userId.toString() : "";

        postVerifyEmailOTP({
            userId: userId,
            phone: "",
            email: getValues("email") || "",
            method: "email",
            type: "verified-email"
        }).then((result) => {

            otpDataRef.current = {
                userId: userId,
                email: getValues("email") || "",
                phone: "",
                method: "email",
                type: "verified-email",
                sendWhere: "email",
            };
            setShowOTP(true)
            localStorage.setItem(
                "otp",
                JSON.stringify({
                    "verify-email": {
                        blockCount: result?.data?.blockCount || 1,
                        blocked: false,
                        startTime: new Date().getTime(),
                    },
                } as TimerObj)
            );
        }).catch((err) => {
            const { response } = err
            if (response && response.data) {
                const { message, rc } = response.data

                dispatch({
                    type: "SHOW_OVERLAY",
                    payload: {
                        type: "warning",
                        header: "Terjadi Kesalahan",
                        subHeader: t("rc." + rc)
                    }
                })
            }
        })
    }

    const onVerifySubmit = async (otp: string) => {
        return emailUseCase.postVerifyEmail({
            email: getValues("email"),
            userId: user?.userId || "",
            otp
        })
    }

    const handleSuccessVerifyEmail = () => {
        dispatch({ type: "SAVE_USER", payload: { ...user, email: getValues("email"), isEmailVerified: "1" } })
        setSuccess(true)
    }

    const handleSuccessRedirect = () => {
        navigate("/dashboard/home")
    }

    React.useEffect(() => {
        if (user?.email) {
            setValue("email", user.email)
        }
    }, [user?.email])

    return {
        success,
        handleSuccessRedirect,
        showOTP,
        handleSubmit,
        register,
        onRequestOTP,
        errors,
        submitButtonStatus,
        loadingOTP,
        onVerifySubmit,
        otpDataRef,
        setSuccess,
        handleSuccessVerifyEmail,
        setShowOTP
    }
}

export type VerifyEmailViewModel = ReturnType<typeof useVerifyEmail>

export default useVerifyEmail