import SignUpPage from "./SignUpPage";
import SignUpUseCase from "domain/interactor/Auth/SignUpUseCase";
import useAuthSignUpViewModel from "presentation/pages/viewmodel/auth-signup";
import AuthApi from "data/Auth";
import OTPApi from "data/OTP";
import OTPUseCase from "domain/interactor/OTP";

export default function () {
    const api = new AuthApi();
    const useCase = new SignUpUseCase(api);

    const otpUseCase = new OTPUseCase(new OTPApi());
    const viewModel = useAuthSignUpViewModel({
        registerUseCase: useCase,
        otpUseCase,
    });

    return <SignUpPage viewModel={viewModel} />;
}
