import React from "react";
import Reaptcha from "reaptcha";
import { useTheme, Box, SxProps, Typography, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import ButtonRemid from "core/components/remid-button";
import useLanguage from "config/hooks/useLanguage";
import { SignInViewModelType } from "presentation/pages/viewmodel/auth-signin-sso";

const style: SxProps = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.5rem",
    margin: "15px",
};

type SignInProps = {
    viewModel: SignInViewModelType;
    mobilePhone?: string; // Add mobilePhone as an optional prop
    sx?: SxProps;
};

const SignIn: React.FC<SignInProps> = (props) => {
    const { palette } = useTheme();

    const { sx = {}, viewModel, mobilePhone } = props;

    const { t } = useLanguage();

    const {
        handleSubmit,
        errors,
        register,
        handleShowPassword,
        showPassword,
        onCaptchaChange,
        reaptchaRef,
    } = viewModel;

    return (
        <form onSubmit={handleSubmit(viewModel.onSubmit)}>
            <Box sx={{ ...style, ...sx }}>
                <TextFieldRemid
                    {...register("username")}
                    value={mobilePhone ?? ""}
                    fullWidth
                    shrink
                    label={t("auth.username")}
                    error={Boolean(errors.username)}
                    helperText={t(errors.username?.message)}
                    disabled={!!mobilePhone}
                />
                <Box sx={{ width: "100%", position: "relative" }}>
                    <TextFieldRemid
                        {...register("password")}
                        fullWidth
                        shrink
                        label={t("auth.password")}
                        type={showPassword ? "text" : "password"}
                        password
                        iconButton={
                            showPassword ? (
                                <VisibilityOffIcon />
                            ) : (
                                <VisibilityIcon />
                            )
                        }
                        onIconButtonClick={handleShowPassword}
                        error={Boolean(errors.password)}
                        helperText={t(errors.password?.message)}
                    />
                    <IconButton
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            transform: "translateY(100%)",
                        }}
                        onClick={viewModel.handleNavigateToForgotPassword}
                    >
                        <Typography
                            variant="caption"
                            sx={{
                                color: palette.primary.main,
                            }}
                        >
                            {t("auth.forgot_password")}
                        </Typography>
                    </IconButton>
                </Box>
                <Box sx={{ mt: "1rem" }}>
                    <Reaptcha
                        ref={reaptchaRef}
                        sitekey={
                            process.env?.REACT_APP_RECAPTCHA_SITE_KEY ?? ""
                        }
                        onVerify={onCaptchaChange}
                    />
                </Box>
                <ButtonRemid
                    type="submit"
                    loading={viewModel.isLoading}
                    disabled={!viewModel.submitButtonStatus}
                >
                    {t("auth.sign_in")}
                </ButtonRemid>
            </Box>
        </form>
    );
};

export default SignIn;
