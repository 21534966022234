import { useState, createContext, ReactNode } from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type Severity = "error" | "warning" | "info" | "success";

type SnackbarContextType = {
    showMessage: (message: string, severity?: Severity) => void;
};

export const SnackbarContext = createContext<SnackbarContextType | null>(null);

const SnackbarProvider = ({ children }: { children: ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState<Severity>("info");

    const handleClose = () => {
        setIsOpen(false);
    };

    const showMessage = (message: string, severity: Severity = "info") => {
        setMessage(message);
        setSeverity(severity);
        setIsOpen(true);
    };

    return (
        <SnackbarContext.Provider value={{ showMessage }}>
            {children}
            <Snackbar
                open={isOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    icon={false}
                    variant="filled"
                    severity={severity}
                    sx={{ minWidth: 300 }}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    {message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider
