import React from "react";
import { Chip, Box, Stack, useTheme, Typography } from "@mui/material";
import FieldProfile from "config/components/field-profile";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ButtonRemid from "core/components/remid-button/Button";
import { ProfileViewModel } from "presentation/pages/viewmodel/profile";
import useLanguage from "config/hooks/useLanguage";
import useCheckVerif from "config/hooks/useCheckVerified";
import HomeUseCase from "domain/interactor/Home";
import HomeApi from "data/Home";

type ProfileContentMobileProps = {
    viewModel: ProfileViewModel;
};

const ProfileContentMobile: React.FC<ProfileContentMobileProps> = ({
    viewModel,
}) => {
    const dataVerif = useCheckVerif(new HomeUseCase(new HomeApi));

    const { palette } = useTheme();

    const { t } = useLanguage();

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                background: "white",
            }}
        >
            <Box
                sx={{
                    height: "20vh",
                    minHeight: 160,
                    background: palette.primary.main,
                    backgroundImage: "url('/svg/HomePageLine.svg')",
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left top",
                }}
            >
                <Typography
                    sx={{
                        color: "#fff",
                        fontSize: "1.25rem",
                        fontWeight: 600,
                        p: "1.25rem",
                    }}
                >
                    {t("bottom_navigation.profile")}
                </Typography>
            </Box>

            <Box
                sx={{
                    position: "relative",
                    bottom: 36,
                    background: "white",
                    borderRadius: "16px 16px 0px 0px",
                    height: "70%",
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        position: "absolute",
                        fontSize: "6rem",
                        color: palette.secondary.main,
                        borderRadius: "50%",
                        height: 108,
                        top: -50,
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}
                >
                    <AccountCircleRoundedIcon fontSize="inherit" />
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        top: 50,
                        width: "100%",
                        pb: "2.75rem",
                    }}
                >
                    <Stack sx={{ px: "1.5rem" }} spacing={2}>
                        <Box sx={{ display: "flex", placeContent: "center" }}>
                            <Chip
                                // label={t("profile.verified")}
                                label={dataVerif.dataVerif?.levelStatus == 'Verified' ? 'Terverifikasi' : 'Belum Terverifikasi'}
                                color="success"
                                sx={{
                                    width: "fit-content",
                                    fontSize: ".75rem",
                                }}
                            />
                        </Box>
                        <Typography fontWeight={600}>
                            {t("profile.change_data")}
                        </Typography>
                        <FieldProfile
                            label={t("profile.email")}
                            value={viewModel.obfuscatedEmail}
                            status={viewModel.emailStatus}
                            onClickNegative={() =>
                                viewModel.usingOTPGuard("verified-email")
                            }
                            onClickPositive={viewModel.handleChangeEmailClick}
                            loading={viewModel.otpLoading}
                        />
                        <FieldProfile
                            label={t("profile.phone")}
                            value={viewModel.obfuscatedPhone}
                            onClickPositive={viewModel.handleChangePhoneClick}
                        />
                        <FieldProfile
                            label={t("profile.password")}
                            value="********"
                            onClickPositive={
                                viewModel.handleChangePasswordClick
                            }
                        />
                        <Box>
                            <FieldProfile
                                label="PIN"
                                value="******"
                                onClickPositive={viewModel.handleChangePinClick}
                            />
                            <Typography
                                sx={{
                                    color: palette.primary.main,
                                    fontSize: ".625rem",
                                    textAlign: "end",
                                    p: ".3rem",
                                }}
                                onClick={viewModel.handleForgotPinClick}
                            >
                                {t("profile.forgot_pin")}
                            </Typography>
                        </Box>
                        <Stack>
                            <ButtonRemid
                                variant="text"
                                sx={{ color: palette.primary.main }}
                                onClick={viewModel.handleLogout}
                            >
                                {/* LOGOUT */}
                                {t("general.logout")?.toUpperCase()}
                            </ButtonRemid>

                            <Typography
                                sx={{
                                    fontSize: ".625rem",
                                    color: palette.text.secondary,
                                    textAlign: "center",
                                }}
                            >
                                {t("bottom_navigation.version")}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileContentMobile;
