import { AktivasiKTPRequest, AktivasiKTPResponse } from "domain/entity/AktivasiKTP";
import AktivasiKTPRepository from "domain/repository/AktivasiKTP";


export default class AktivasiKTPUseCase {
    constructor(private repository: AktivasiKTPRepository) { }

    postKTP(body: AktivasiKTPRequest) {
        const form = new FormData()

        const { account, ktp, selfie } = body

        form.append("account", JSON.stringify(account));
        form.append("ktp", ktp);
        form.append("selfie", selfie)

        return this.repository.postKTP(form)
    }

    postKTPRefill(): Promise<AktivasiKTPResponse> {
        console.log("postKTPRefill")
        return this.repository.postKTPRefill()
    }
}