import { GlobalContext } from "config/context/GlobalContext";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { WithdrawMtcnRequest, WithdrawQRRequest, WithdrawRequest } from "domain/entity/Withdraw";
import WithdrawUseCase from "domain/interactor/Withdraw";
import { useMutation } from "react-query";
import log from "config/log";
import useLanguage from "config/hooks/useLanguage";

const initialValues = {
    mtcn: "",
};

const useWithdrawUangViewModel = (useCase: WithdrawUseCase) => {
    const { t } = useLanguage();

    const navigate = useNavigate();

    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const [showDetail, setShowDetail] = React.useState(false);

    const [showPINValidation, setShowPINValidation] = React.useState(false);

    const [showQR, setShowQR] = React.useState(false);

    const [showSuccess, setShowSuccess] = React.useState(false);

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: initialValues,
    });

    const loadingTracker = React.useRef(false);

    const [loadingData, setLoadingData] = React.useState<any>(null);

    const [loading, setLoading] = React.useState(false);

    const _withdrawCode = watch("mtcn");

    const _accountId = React.useRef("");

    const submitButtonStatus = _withdrawCode === "";

    const { mutateAsync: getCheck, isLoading: pinloading } = useMutation(() =>
        useCase.getCheckUser()
    );

    const {
        mutateAsync: withdrawMTCN,
        data: withdrawMtcnData,
        isLoading: isWithdrawMtcnLoading,
        isError: isWithdrawMtcnError,
        isSuccess: isWithdrawMtcnSuccess,
    } = useMutation("/withdraw/mtcn", (body: WithdrawMtcnRequest) =>
        useCase.postWithdrawMtcn(body)
    );

    const {
        mutateAsync: withdrawInsert,
        data: withdrawInsertData,
        isLoading: isWithdrawInsertLoading,
        isError: isWithdrawInsertError,
        isSuccess: isWithdrawInsertSuccess,
    } = useMutation("/withdraw/insert", (body: WithdrawRequest) =>
        useCase.postWithdraw(body)
    );

    const {
        mutateAsync: mutateAsyncQR,
        data: withdrawQRData,
        isLoading: isWithdrawQRLoading,
        isSuccess: isWithdrawQRSuccess,
    } = useMutation("/withdraw/generate-qr", (body: WithdrawQRRequest) =>
        useCase.postWithdrawQR(body)
    );

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getCheck(); // Await the asynchronous function call
                // Handle the result as needed
            } catch (err) {
                const { response } = err as any; // should only handle axios Error

                if (response && response.data) {
                    const { message, rc } = response.data;

                    dispatch({
                        type: "SHOW_OVERLAY",
                        payload: {
                            header: t("general.failed1"),
                            type: "warning",
                            btnText: "Kembali",
                            subHeader: t("rc." + rc),
                            onClick: () => {
                                navigate("/dashboard/home");

                                dispatch({ type: "CLOSE_OVERLAY" });
                            }
                        },
                    });
                }
            }
        };

        fetchData(); // Call the async function
    }, []);

    const onSubmit = async () => {
        try {
            const _userid = user?.userId ? user?.userId.toString() : "";

            const result = await withdrawMTCN({
                mtcn: _withdrawCode,
                userId: _userid,
                productId: "4",
            });

            // const {
            //     data: { accountId },
            // } = result;

            _accountId.current = result.data.accountId;

            log('accountId: ' + _accountId);

            setShowDetail(true);

        } catch (err) {
            const { response = null } = err as any;

            if (response && response.data) {
                // const { message = "asd" } = response.data;
                const { rc } = response.data;
                const message = t("rc." + rc);

                setError("mtcn", { message });
            }
        }
    };

    const onSubmit2 = async () => {
        try {
            await withdrawInsert({
                mtcn: _withdrawCode,
                accountId: _accountId.current,
                productId: "4",
            });
        } catch (err) {
            const { response = null } = err as any;

            if (response && response.data) {
                // const { message = "asd" } = response.data;
                const { rc } = response.data;
                const message = t("rc." + rc);
                
                setError("mtcn", { message });
            }
        }
    };

    const onGenerateQRCode = () => {
        setShowPINValidation(true);
    };

    const onPINValidation = async (pin: string) => {
        // setShowPINValidation(false);
        // setShowQR(true);
        try {
            const userId = user?.userId ? user?.userId.toString() : "";

            await mutateAsyncQR({
                userId,
                withdrawId: withdrawInsertData?.data?.WithdrawId || "",
                pin,
            });

            setShowPINValidation(false);
        } catch (err) {
            throw err;
        }
    };

    const onClose = () => {
        // setShowSuccess(true);
        navigate("/dashboard/transaction");
    };

    const onBack = () => {
        return showPINValidation
            ? setShowPINValidation(false)
            : setShowDetail(false);
    };

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        onSubmit2,
        submitButtonStatus,
        showDetail,
        showPINValidation,
        showQR,
        showSuccess,
        onGenerateQRCode,
        onPINValidation,
        onBack,
        onClose,
        isWithdrawInsertError,
        isWithdrawInsertLoading,
        isWithdrawInsertSuccess,
        withdrawInsertData,
        isWithdrawQRSuccess,
        withdrawQRData,
        isWithdrawQRLoading,
        withdrawMtcnData,
        isWithdrawMtcnLoading,
        isWithdrawMtcnError,
        isWithdrawMtcnSuccess,
    };
};

export type WithdrawUangViewModel = ReturnType<typeof useWithdrawUangViewModel>;

export default useWithdrawUangViewModel;
