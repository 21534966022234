import { ChangePinRequest, ChangePinResponse, ForgotPinRequest, ForgotPinResponse, SetupPinRequest, SetupPinResponse } from "domain/entity/Auth/model/Pin";
import PinRepository from "domain/repository/Pin";

export default class PinUseCase {
    constructor(private repo: PinRepository) { }

    postSetPin(body: SetupPinRequest): Promise<SetupPinResponse> {
        return this.repo.postSetPin(body)
    }

    postChangePin(body: ChangePinRequest): Promise<ChangePinResponse> {
        return this.repo.postChangePin(body)
    }

    postForgotPin(body: ForgotPinRequest): Promise<ForgotPinResponse> {
        return this.repo.postForgotPin(body)
    }
}