import React from "react";
import { MenuItem, Stack, Typography, Box } from "@mui/material";
import LayoutAppBar from "config/components/layout-app-bar";
import useLanguage from "config/hooks/useLanguage";
import log from "config/log";
import QRCodeComponent from "config/components/qr-code";
import Message from "config/components/message-failed";
import { GenerateQRViewModel } from "presentation/pages/viewmodel/generate-qr";
import { useLocation } from "react-router-dom";

type GenerateQRPageProps = {
    viewModel: GenerateQRViewModel;
};

const GenerateQRPage: React.FC<GenerateQRPageProps> = ({ viewModel }) => {

    const location = useLocation();

    const { data } = location.state;

    log('QR: ' + data)

    const { success, handleSuccessRedirect } =
        viewModel;

    const renderChildren = () => {
        return (
            <QRCodeComponent
                value={data}
            // onBack={viewModel.onClose}
            />
        );
    };

    return (
        <>
            {success ? (
                <Box sx={{ height: "100vh", px: "2rem" }} >
                    <Message
                        header="Transaksi Gagal!"
                        subHeader="Transaksi Anda belum berhasil. Silahkan mencoba kembali."
                        btnText="Coba Lagi"
                        negativeBtnText="Nanti Saja"
                        onClick={handleSuccessRedirect}
                        type="failed"
                    />
                </Box >
            ) : (
                <LayoutAppBar
                    title="QR Code"
                    onBack={
                        viewModel.onBack
                        // viewModel.nextStep ? viewModel.handleBackButtonClick : undefined
                    }
                >
                    {renderChildren()}
                </LayoutAppBar>
            )}
        </>
    );
};

export default GenerateQRPage;