import React from "react";
import {
    Autocomplete,
    Checkbox,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    TextField,
    useTheme,
    Box,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import TextFieldRemid from "core/components/remid-textfield/TextField";
import ButtonRemid from "core/components/remid-button/Button";
import { ConfirmIdentityViewModel } from "presentation/pages/viewmodel/confirm-identity";
import useLanguage from "config/hooks/useLanguage";
import FormGroup from "@mui/material/FormGroup";
import { NumericFormat } from "react-number-format";

type ConfirmIdentityFormProps = {
    viewModel: ConfirmIdentityViewModel;
};

const ConfirmIdentityForm: React.FC<ConfirmIdentityFormProps> = ({
    viewModel,
}) => {
    const { t } = useLanguage();

    const { palette } = useTheme();

    const { handleSubmit, onFormSubmit, register, errors, showExtraField } =
        viewModel;

    const [openTanggal, setOpenTanggal] = React.useState(false);

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            <Stack
                spacing={1}
                sx={{ p: "1em" }}
                justifyContent="center"
                alignItems="center"
            >
                <Typography fontWeight={600} sx={{ fontSize: "1.25rem" }}>
                    Isi Data Diri Anda
                </Typography>
                <Typography sx={{ fontSize: ".75rem" }}>
                    Mohon pastikan bahwa data diri yang Anda input
                    benar.
                </Typography>
                <br />

                <Box position="relative" mb={3} sx={{ width: '100%' }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                            position: "absolute",
                            top: -13,
                            left: 16,
                            backgroundColor: "white",
                            padding: "0 8px",
                            fontSize: "1rem"
                        }}
                    >
                        Data Diri
                    </Typography>
                    <Box border={1} borderRadius={2} p={2} sx={{ borderColor: "grey" }}>
                        <Controller
                            control={viewModel.control}
                            name="kewarganegaraan"
                            render={({ field: { onChange, name, value } }) => (
                                <TextFieldRemid
                                    select
                                    fullWidth
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    disabled={true}
                                    shrink
                                    label={t("confirm_identity.citizenship")}
                                    error={Boolean(errors.kewarganegaraan)}
                                    helperText={t(errors.kewarganegaraan?.message)}
                                    sx={{ marginBottom: '10px' }}
                                >
                                    <MenuItem value="WNI">WNI</MenuItem>
                                    <MenuItem value="2">WNA</MenuItem>
                                </TextFieldRemid>
                            )}
                        />
                        <Controller
                            control={viewModel.control}
                            name="negara"
                            render={({ field: { onChange, value, ...props } }) => (
                                <Autocomplete
                                    fullWidth
                                    onChange={(e, data) => {
                                        const id = data?.id ?? "";
                                        onChange(id);
                                    }}
                                    disabled={viewModel.disableCountry}
                                    options={viewModel.countryList}
                                    getOptionLabel={(option: any) => option.label}
                                    isOptionEqualToValue={(option, value) =>
                                        option?.id === value?.id
                                    }
                                    value={
                                        viewModel.countryList.find(
                                            (item) => item.id === value
                                        ) ?? null
                                    }
                                    {...props}
                                    renderInput={(params) => (
                                        <TextFieldRemid
                                            {...params}
                                            fullWidth
                                            label={t("confirm_identity.country")}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                            }}
                                            error={Boolean(errors.negara)}
                                            helperText={t(errors.negara?.message)}
                                            sx={{ marginBottom: '10px' }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <TextFieldRemid
                            {...register("idCard")}
                            number
                            fullWidth
                            shrink
                            inputProps={{ inputMode: "numeric" }}
                            label={t("confirm_identity.identity_number")}
                            error={Boolean(errors.idCard)}
                            helperText={t(errors.idCard?.message)}
                            sx={{ marginBottom: '10px' }}
                        />
                        <TextFieldRemid
                            {...register("nama")}
                            fullWidth
                            shrink
                            label={t("confirm_identity.full_name")}
                            error={Boolean(errors.nama)}
                            helperText={t(errors.nama?.message)}
                            sx={{ marginBottom: '10px' }}
                        />
                        <TextFieldRemid
                            {...register("tempatLahir")}
                            fullWidth
                            shrink
                            inputProps={{ inputMode: "text" }}
                            label={t("confirm_identity.place_of_birth")}
                            error={Boolean(errors.tempatLahir)}
                            helperText={t(errors.tempatLahir?.message)}
                            sx={{ marginBottom: '10px' }}
                        />
                        <Controller
                            name="tanggalLahir"
                            control={viewModel.control}
                            render={({ field, fieldState: { error } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        {...field}
                                        open={openTanggal}
                                        onOpen={() => setOpenTanggal(true)}
                                        onClose={() => setOpenTanggal(false)}
                                        disableFuture
                                        slotProps={{
                                            textField: {
                                                onClick: () => setOpenTanggal(true),
                                                fullWidth: true,
                                                error: Boolean(error?.message),
                                                helperText: t(error?.message),
                                                sx: {
                                                    "& input": {
                                                        textAlign: "inherit",
                                                    },
                                                    "& input[type=number]": {
                                                        appearance: "textfield",
                                                    },
                                                    filter: "drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))",
                                                    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused":
                                                    {
                                                        color: palette.text.primary,
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        borderRadius: "4px",
                                                        border: "1px solid #ccc",
                                                        backgroundColor: "white",
                                                        "&:hover": {
                                                            backgroundColor: "white",
                                                        },
                                                        "&.Mui-focused": {
                                                            backgroundColor: "white",
                                                        },
                                                        "&.Mui-disabled": {
                                                            color: "rgba(0, 0, 0, 0.38)",
                                                            backgroundColor: "white",
                                                        },
                                                    },
                                                    marginBottom: '10px'
                                                },
                                                variant: "outlined",
                                                label: "Tanggal Lahir",
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <FormControl
                            error={Boolean(errors.jenisKelamin)}
                            sx={{ width: "100%" }}
                        >
                            <FormLabel
                                sx={{ pl: "1rem" }}
                                id="demo-controlled-radio-buttons-group"
                            >
                                {t("confirm_identity.gender")}
                            </FormLabel>
                            <Controller
                                control={viewModel.control}
                                name="jenisKelamin"
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        sx={{ pl: "1rem" }}
                                    >
                                        <FormControlLabel
                                            value="laki-laki"
                                            control={<Radio />}
                                            label="Laki - laki"
                                        />
                                        <FormControlLabel
                                            value="perempuan"
                                            control={<Radio />}
                                            label="Perempuan"
                                        />
                                    </RadioGroup>
                                )}
                            />
                            {Boolean(errors.jenisKelamin) && (
                                <FormHelperText>
                                    {t(errors.jenisKelamin?.message)}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                </Box><br />


                {/* <AutoCompleteComponent
                    form={{
                        control: viewModel.control,
                        name: "tempatLahir",
                    }}
                    options={viewModel.listTempatLahir?.data ?? []}
                    label={t("confirm_identity.place_of_birth")}
                    cb={viewModel.fetchTempatLahir}
                    loading={viewModel.loadingTempatLahir}
                /> */}

                <Box position="relative" mb={3} sx={{ width: '100%' }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                            position: "absolute",
                            top: -13,
                            left: 16,
                            backgroundColor: "white",
                            padding: "0 8px",
                            fontSize: "1rem"
                        }}
                    >
                        Alamat
                    </Typography>
                    <Box border={1} borderRadius={2} p={2} sx={{ borderColor: "grey" }}>
                        <TextFieldRemid
                            {...register("alamat")}
                            fullWidth
                            shrink
                            label="Alamat Sesuai KTP"
                            error={Boolean(errors.alamat)}
                            helperText={t(errors.alamat?.message)}
                            sx={{ marginBottom: '10px' }}
                        />
                        <Controller
                            control={viewModel.control}
                            name="provinceId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                        viewModel.clearFields(1)
                                    }}
                                    select
                                    fullWidth
                                    shrink
                                    label={t("confirm_identity.province")}
                                    error={Boolean(errors.provinceId)}
                                    helperText={t(errors.provinceId?.message)}
                                    sx={{ marginBottom: '10px' }}
                                >
                                    {Array.isArray(viewModel.listProvinsi?.data)
                                        ? viewModel.listProvinsi?.data.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={`${item.id}-${item.labelName}`}
                                                    value={item.id}
                                                >
                                                    {item.labelName}
                                                </MenuItem>
                                            );
                                        })
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <Controller
                            control={viewModel.control}
                            name="cityId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                        viewModel.clearFields(2)
                                    }}
                                    select
                                    fullWidth
                                    disabled={!viewModel._province}
                                    shrink
                                    label={t("confirm_identity.district")}
                                    error={Boolean(errors.cityId)}
                                    helperText={t(errors.cityId?.message)}
                                    sx={{ marginBottom: '10px' }}
                                >
                                    {Array.isArray(viewModel.listKabupaten?.data)
                                        ? viewModel.listKabupaten?.data.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={`${item.id}-${item.labelName}`}
                                                    value={item.id}
                                                >
                                                    {item.labelName}
                                                </MenuItem>
                                            );
                                        })
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <Controller
                            control={viewModel.control}
                            name="subDistrictId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                        viewModel.clearFields(3)
                                    }}
                                    select
                                    fullWidth
                                    disabled={!viewModel._district}
                                    shrink
                                    label={t("confirm_identity.sub_district")}
                                    error={Boolean(errors.subDistrictId)}
                                    helperText={t(errors.subDistrictId?.message)}
                                    sx={{ marginBottom: '10px' }}
                                >
                                    {Array.isArray(viewModel.listKecamatan?.data)
                                        ? viewModel.listKecamatan?.data.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={`${item.id}-${item.labelName}`}
                                                    value={item.id}
                                                >
                                                    {item.labelName}
                                                </MenuItem>
                                            );
                                        })
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <Controller
                            control={viewModel.control}
                            name="villageId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                        viewModel.clearFields(4)
                                    }}
                                    select
                                    fullWidth
                                    disabled={!viewModel._subDistrict}
                                    shrink
                                    label={t("confirm_identity.village")}
                                    error={Boolean(errors.villageId)}
                                    helperText={t(errors.villageId?.message)}
                                    sx={{ marginBottom: '10px' }}
                                >
                                    {Array.isArray(viewModel.listDesa?.data)
                                        ? viewModel.listDesa?.data.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={`${item.id}-${item.labelName}`}
                                                    value={item.id}
                                                >
                                                    {item.labelName}
                                                </MenuItem>
                                            );
                                        })
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <TextFieldRemid
                            {...viewModel.neighborhoodRegister}
                            fullWidth
                            shrink
                            inputProps={{ inputMode: "numeric", maxLength: 7 }}
                            onChange={viewModel.handleNeighborhoodChange}
                            disabled={!viewModel._village}
                            label={t("confirm_identity.neighborhood")}
                            error={Boolean(errors.neighborhood)}
                            helperText={t(errors.neighborhood?.message)}
                            sx={{ marginBottom: '10px' }}
                        />
                        <FormGroup sx={{ width: "100%" }}>
                            <FormControlLabel
                                control={
                                    <Controller
                                        control={viewModel.control}
                                        name="sameDomisiliCheckBox"
                                        render={({ field }) => (
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) =>
                                                    field.onChange(e.target.checked)
                                                }
                                            />
                                        )}
                                    />
                                }
                                label={
                                    <Typography sx={{ fontSize: ".75rem" }}>
                                        Alamat domisili sama dengan alamat KTP
                                    </Typography>
                                }
                            />
                        </FormGroup>

                        {/* START TAMBAHAN BARU */}
                        <Controller
                            control={viewModel.control}
                            name="domisiliProvinceId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    sx={{
                                        display: viewModel._sameDomisiliCheckBox
                                            ? "none"
                                            : "block",
                                        marginBottom: '10px'
                                    }}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                        viewModel.clearFields(1)
                                    }}
                                    select
                                    fullWidth
                                    shrink
                                    label={t("confirm_identity.province")}
                                    error={Boolean(errors.domisiliProvinceId)}
                                    helperText={t(errors.domisiliProvinceId?.message)}
                                >
                                    {Array.isArray(viewModel.listProvinsi?.data)
                                        ? viewModel.listProvinsi?.data.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={`${item.id}-${item.labelName}`}
                                                    value={item.id}
                                                >
                                                    {item.labelName}
                                                </MenuItem>
                                            );
                                        })
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <Controller
                            control={viewModel.control}
                            name="domisiliCityId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    sx={{
                                        display: viewModel._sameDomisiliCheckBox
                                            ? "none"
                                            : "block",
                                        marginBottom: '10px'
                                    }}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                        viewModel.clearFields(2)
                                    }}
                                    select
                                    fullWidth
                                    disabled={!viewModel._domisiliProvinceId}
                                    shrink
                                    label={t("confirm_identity.district")}
                                    error={Boolean(errors.domisiliCityId)}
                                    helperText={t(errors.domisiliCityId?.message)}
                                >
                                    {Array.isArray(
                                        viewModel.listDomisiliKabupaten?.data
                                    )
                                        ? viewModel.listDomisiliKabupaten?.data.map(
                                            (item) => {
                                                return (
                                                    <MenuItem
                                                        key={`${item.id}-${item.labelName}`}
                                                        value={item.id}
                                                    >
                                                        {item.labelName}
                                                    </MenuItem>
                                                );
                                            }
                                        )
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <Controller
                            control={viewModel.control}
                            name="domisiliSubDistrictId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    sx={{
                                        display: viewModel._sameDomisiliCheckBox
                                            ? "none"
                                            : "block",
                                        marginBottom: '10px'
                                    }}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                        viewModel.clearFields(3)
                                    }}
                                    select
                                    fullWidth
                                    disabled={!viewModel._domisiliCityId}
                                    shrink
                                    label={t("confirm_identity.sub_district")}
                                    error={Boolean(errors.domisiliSubDistrictId)}
                                    helperText={t(
                                        errors.domisiliSubDistrictId?.message
                                    )}
                                >
                                    {Array.isArray(
                                        viewModel.listDomisiliKecamatan?.data
                                    )
                                        ? viewModel.listDomisiliKecamatan?.data.map(
                                            (item) => {
                                                return (
                                                    <MenuItem
                                                        key={`${item.id}-${item.labelName}`}
                                                        value={item.id}
                                                    >
                                                        {item.labelName}
                                                    </MenuItem>
                                                );
                                            }
                                        )
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <Controller
                            control={viewModel.control}
                            name="domisiliVillageId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                        viewModel.clearFields(4)
                                    }}
                                    sx={{
                                        display: viewModel._sameDomisiliCheckBox
                                            ? "none"
                                            : "block",
                                        marginBottom: '10px'
                                    }}
                                    select
                                    fullWidth
                                    disabled={!viewModel._domisiliSubDistrictId}
                                    shrink
                                    label={t("confirm_identity.village")}
                                    error={Boolean(errors.domisiliVillageId)}
                                    helperText={t(errors.domisiliVillageId?.message)}
                                >
                                    {Array.isArray(viewModel.listDomisiliDesa?.data)
                                        ? viewModel.listDomisiliDesa?.data.map(
                                            (item) => {
                                                return (
                                                    <MenuItem
                                                        key={`${item.id}-${item.labelName}`}
                                                        value={item.id}
                                                    >
                                                        {item.labelName}
                                                    </MenuItem>
                                                );
                                            }
                                        )
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <TextFieldRemid
                            sx={{
                                display: viewModel._sameDomisiliCheckBox
                                    ? "none"
                                    : "block",
                                marginBottom: '10px'
                            }}
                            {...viewModel.neighborhoodRegister2}
                            fullWidth
                            shrink
                            inputProps={{ inputMode: "numeric", maxLength: 7 }}
                            onChange={viewModel.handleNeighborhoodChange2}
                            disabled={!viewModel._domisiliVillageId}
                            label={t("confirm_identity.neighborhood")}
                            error={Boolean(errors.domisiliNeighborhood)}
                            helperText={t(errors.domisiliNeighborhood?.message)}
                        />
                        <TextFieldRemid
                            sx={{
                                display: viewModel._sameDomisiliCheckBox
                                    ? "none"
                                    : "block",
                                marginBottom: '10px'
                            }}
                            {...register("alamatDomisili")}
                            fullWidth
                            shrink
                            label={t("confirm_identity.address_domisili")}
                            error={Boolean(errors.alamatDomisili)}
                            helperText={t(errors.alamatDomisili?.message)}
                        />
                        {/* END TAMBAHAN BARU */}
                    </Box>
                </Box><br />

                <Box position="relative" mb={3} sx={{ width: '100%' }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                            position: "absolute",
                            top: -13,
                            left: 16,
                            backgroundColor: "white",
                            padding: "0 8px",
                            fontSize: "1rem"
                        }}
                    >
                        Pekerjaan
                    </Typography>
                    <Box border={1} borderRadius={2} p={2} sx={{ borderColor: "grey" }}>
                        <Controller
                            control={viewModel.control}
                            name="professionId"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                    }}
                                    select
                                    fullWidth
                                    shrink
                                    label={t("confirm_identity.occupation")}
                                    error={Boolean(errors.professionId)}
                                    helperText={t(errors.professionId?.message)}
                                    sx={{ marginBottom: '10px' }}
                                >
                                    {Array.isArray(viewModel.listPekerjaan?.data)
                                        ? viewModel.listPekerjaan?.data.map(
                                            (item: any) => {
                                                return (
                                                    <MenuItem
                                                        key={`${item.id}-${item.labelName}`}
                                                        value={item.id}
                                                    >
                                                        {item.labelName}
                                                    </MenuItem>
                                                );
                                            }
                                        )
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <TextFieldRemid
                            {...register("namaPerusahaan")}
                            style={{ display: showExtraField ? "block" : "none" }}
                            fullWidth
                            shrink
                            label="Nama Instansi / Perusahaan"
                            error={Boolean(errors.namaPerusahaan)}
                            helperText={t(errors.namaPerusahaan?.message)}
                            sx={{ marginBottom: '10px' }}
                        />
                        <Controller
                            control={viewModel.control}
                            name="bidangPerusahaan"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                    }}
                                    style={{
                                        display: showExtraField ? "block" : "none",
                                    }}
                                    select
                                    fullWidth
                                    shrink
                                    label="Bidang Usaha"
                                    error={Boolean(errors.bidangPerusahaan)}
                                    helperText={t(errors.bidangPerusahaan?.message)}
                                    sx={{ marginBottom: '10px' }}
                                >
                                    {Array.isArray(viewModel.listBidangUsaha?.data)
                                        ? viewModel.listBidangUsaha?.data.map(
                                            (item: any) => {
                                                return (
                                                    <MenuItem
                                                        key={`${item.id}-${item.labelName}`}
                                                        value={item.id}
                                                    >
                                                        {item.labelName}
                                                    </MenuItem>
                                                );
                                            }
                                        )
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <Controller
                            control={viewModel.control}
                            name="jabatan"
                            render={({ field: { onChange, value, ...field } }) => (
                                <TextFieldRemid
                                    {...field}
                                    value={value ? value : ""}
                                    onChange={event => {
                                        onChange(parseInt(event.target.value))
                                    }}
                                    style={{
                                        display: showExtraField ? "block" : "none",
                                    }}
                                    select
                                    fullWidth
                                    shrink
                                    label="Jabatan"
                                    error={Boolean(errors.jabatan)}
                                    helperText={t(errors.jabatan?.message)}
                                    sx={{ marginBottom: '10px' }}
                                >
                                    {Array.isArray(viewModel.listJabatan?.data)
                                        ? viewModel.listJabatan?.data.map(
                                            (item: any) => {
                                                return (
                                                    <MenuItem
                                                        key={`${item.id}-${item.labelName}`}
                                                        value={item.id}
                                                    >
                                                        {item.labelName}
                                                    </MenuItem>
                                                );
                                            }
                                        )
                                        : []}
                                </TextFieldRemid>
                            )}
                        />
                        <TextFieldRemid
                            {...register("alamatKantor")}
                            style={{ display: showExtraField ? "block" : "none" }}
                            fullWidth
                            shrink
                            label="alamat Kantor"
                            error={Boolean(errors.alamatKantor)}
                            helperText={t(errors.alamatKantor?.message)}
                            sx={{ marginBottom: '10px' }}
                        />
                        <TextFieldRemid
                            {...register("noTeleponKantor")}
                            style={{ display: showExtraField ? "block" : "none" }}
                            number
                            fullWidth
                            shrink
                            inputProps={{ inputMode: "numeric", maxLength: 11 }}
                            placeholder="021 2902 1993"
                            label="Nomor Telp. Kantor"
                            error={Boolean(errors.noTeleponKantor)}
                            helperText={t(errors.noTeleponKantor?.message)}
                            sx={{ marginBottom: '10px' }}
                        />
                        {showExtraField && (
                            <Controller
                                control={viewModel.control}
                                name="pendapatanBulanan"
                                render={({
                                    field: { onChange, name, value, onBlur },
                                }) => (
                                    <NumericFormat
                                        onBlur={onBlur}
                                        name={name}
                                        value={value}
                                        onKeyDown={(e) => {
                                            const { key } = e;
                                            if (
                                                key === "." ||
                                                key === "-" ||
                                                key === ","
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onValueChange={(e) => {
                                            onChange(e.formattedValue);
                                        }}
                                        customInput={TextField}
                                        allowLeadingZeros={false}
                                        variant="filled"
                                        fullWidth
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        InputProps={{
                                            startAdornment: (
                                                <span
                                                    style={{
                                                        padding: "25px 12px 8px 0px",
                                                    }}
                                                >
                                                    Rp.
                                                </span>
                                            ),
                                            disableUnderline: true,
                                        }}
                                        label="Pendapatan Bulanan"
                                        autoComplete="off"
                                        error={Boolean(errors.pendapatanBulanan)}
                                        helperText={t(
                                            errors.pendapatanBulanan?.message
                                        )}
                                        sx={{
                                            "& input": {
                                                textAlign: "inherit",
                                            },
                                            filter: "drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))",
                                            "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused":
                                            {
                                                color: palette.text.primary,
                                            },
                                            "& .MuiFilledInput-root": {
                                                borderRadius: "4px",
                                                border: "1px solid #ccc",
                                                backgroundColor: "white",
                                                "&:hover": {
                                                    backgroundColor: "white",
                                                },
                                                "&.Mui-focused": {
                                                    backgroundColor: "white",
                                                },
                                                "&.Mui-disabled": {
                                                    color: "rgba(0, 0, 0, 0.38)",
                                                    backgroundColor: "white",
                                                },
                                            },
                                            marginBottom: '10px',
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Box>
                </Box>


                <ButtonRemid type="submit">Kirim</ButtonRemid>
            </Stack>
        </form>
    );
};

export default ConfirmIdentityForm;
