import React from "react";
import State from "./State";
import Action from "./Action";
import reducer from "./Reducer";
import User from "config/shared/types/User";
import { getUser } from "config/storage";

const _user = JSON.parse(getUser() || "null") as User;

const initialState: State = {
    bottomNavigation: "home",
    language: "id",
    user: _user,
    overlay: { open: false },
};

export const GlobalContext = React.createContext<
    [State, React.Dispatch<Action>]
>([initialState, () => {}]);

const GlobalProvider = ({ children }: React.PropsWithChildren) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <GlobalContext.Provider value={[state, dispatch]}>
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;
