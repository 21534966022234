import LayoutAppBar from "config/components/layout-app-bar";
import { Box, Stack, Typography, List, ListItem } from "@mui/material";
import React from "react";

const TermOfServicePage = () => {
    const getContentByItem = (item: any) => {
        switch (item) {
            case "Definisi":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>"Anda”</strong> adalah sebagaimana didefinisikan pada bagian Pendahuluan Syarat dan Ketentuan ini.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Akun”</strong> adalahi setiap akun atas nama Konsumen Remid yang terdaftar pada Remid Transfer Link.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Akseptasi”</strong> adalah pengaksepan atau persetujuan untuk melaksanakan atau memenuhi Perintah Transfer Dana yang wajib terlebih dahulu diadakan berdasarkan Verifikasi.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Biaya Layanan”</strong> adalah biaya transaksi Pengiriman Dana yang dibebankan kepada Pengirim yang melakukan Pengiriman Dana.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Dana”</strong> adalah uang tunai yang diserahkan oleh Pengirim pada Mitra atas Perintah Transfer Dana yang diadakan melalui fitur Remid Transfer Link untuk dapat dicairkan oleh Penerima.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Dana Yang Tidak Diambil”</strong> adalah sejumlah Dana yang dikirim oleh Pengirim dan tidak dilakukan pengambilan oleh Penerima.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Data Pribadi”</strong> adalah setiap data yang bersifat umum maupun spesifik tentang seseorang sebagaimana diatur dalam Peraturan Perlindungan Data Pribadi yang berlaku di Indonesia berikut segala perubahan dan/atau turunannya dari waktu ke waktu.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Formulir Penerimaan”</strong> adalah sebagaimana didefinisikan pada ketentuan nomor 4 Syarat dan Ketentuan ini.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Formulir Pengiriman”</strong> adalah sebagaimana didefinisikan pada ketentuan nomor 4 Syarat dan Ketentuan ini.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Hari Kalender”</strong> adalah hari dalam tahun kalender dari hari Senin sampai dengan Minggu, termasuk hari libur nasional dan cuti bersama yang ditetapkan oleh pemerintah Republik Indonesia.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Hari Kerja”</strong> adalah hari Senin sampai dengan hari Jumat, di luar akhir pekan dan hari libur nasional sebagaimana yang ditetapkan oleh pemerintah Republik Indonesia dan/atau Hukum yang berlaku.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Hukum”</strong> adalah setiap undang-undang, peraturan, keputusan, ordonansi, surat
                            edaran, petunjuk (sepanjang memiliki kekuatan hukum), surat perintah, traktat, kitab
                            undang-undang yang berlaku di negara Republik Indonesia atau setiap interpretasi
                            dari hal-hal yang telah disebutkan, sebagaimana ditetapkan, diterbitkan atau
                            diundangkan oleh lembaga pemerintahan, otoritas, bursa efek, putusan lembaga
                            peradilan yang berkekuatan hukum tetap, termasuk setiap perubahan, modifikasi,
                            pengecualian, penggantian, atau penetapan ulang hal-hal tersebut. Untuk
                            menghindari keragu-raguan, Hukum dalam Syarat dan Ketentuan ini mencakup
                            diantaranya Peraturan Pelindungan Data Pribadi, peraturan yang ditetapkan Bank
                            Indonesia dan/atau peraturan lain yang berlaku di negara Republik Indonesia serta
                            berlaku bagi kegiatan usaha yang dijalankan oleh Remid.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Informasi Data Transfer Dana”</strong> adalah informasi yang wajib disediakan dengan
                            sebenar-benarnya oleh (i) Pengirim pada saat hendak melakukan Perintah Transfer
                            Dana sebagaimana wajib disediakan oleh Pengirim pada Formulir Pengiriman dan
                            (ii) Penerima pada saat hendak melakukan pengambilan Dana sebagaimana wajib
                            disediakan oleh Penerima pada Formulir Penerimaan, yang sekurang-kurangnya
                            memuat :
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>yang wajib disediakan oleh Pengirim pada Formulir Pengiriman:
                                    <ul style={{ listStyleType: 'none' }}>
                                        <li style={{ listStyleType: 'decimal' }}>identitas Pengirim (antara lain: nama lengkap, nomor identitas, nomor telepon);</li>
                                        <li style={{ listStyleType: 'decimal' }}>identitas Penerima (antara lain, nama lengkap dan nomor telepon);</li>
                                        <li style={{ listStyleType: 'decimal' }}>jumlah Dana dan jenis mata uang yang ditransfer;</li>
                                        <li style={{ listStyleType: 'decimal' }}>keterangan sumber Dana dan tujuan pengiriman;</li>
                                        <li style={{ listStyleType: 'decimal' }}>tanggal Perintah Transfer Dana; dan/atau</li>
                                        <li style={{ listStyleType: 'decimal' }}>informasi lainnya yang dipersyaratkan dalam Formulir Pengiriman dan/atau berdasarkan Hukum yang berlaku.</li>
                                    </ul>
                                </li>
                                <li style={{ listStyleType: 'lower-roman' }}>yang wajib disediakan oleh Penerima pada Formulir Penerimaan:
                                    <ul style={{ listStyleType: 'none' }}>
                                        <li style={{ listStyleType: 'decimal' }}>Identitas Penerima (antara lain: nama lengkap, nomor identitas, nomor telepon);</li>
                                        <li style={{ listStyleType: 'decimal' }}>Kode MTCN;</li>
                                        <li style={{ listStyleType: 'decimal' }}>jumlah Dana dan jenis mata uang yang ditransfer;</li>
                                        <li style={{ listStyleType: 'decimal' }}>tanggal penerimaan Dana; dan/atau</li>
                                        <li style={{ listStyleType: 'decimal' }}>informasi lainnya yang dipersyaratkan dalam Formulir Penerimaan dan/atau berdasarkan Hukum yang berlaku.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Kami”</strong> adalah sebagaimana didefinisikan pada bagian Pendahuluan Syarat dan Ketentuan ini.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Keadaan Kahar”</strong> adalah sebagaimana didefinisikan pada ketentuan nomor 14 Syarat dan Ketentuan ini.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Kebijakan Privasi”</strong> adalah kebijakan privasi yang berlaku atas layanan Remid, yang merupakan satu kesatuan dengan Syarat dan Ketentuan ini.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Kode MTCN”</strong> atau Kode Money Transfer Control Number adalah kode dan/atau nomor unik yang:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>diterbitkan oleh Remid yang diperuntukan untuk Pengirim dalam melakukan
                                    Transfer Dana; dan</li>
                                <li style={{ listStyleType: 'lower-roman' }}>wajib diinfokan oleh Pengirim kepada Penerima guna proses Verifikasi pada
                                    Mitra sebagai syarat untuk mencairkan Dana atas hasil Perintah Transfer
                                    Dana yang diterbitkan oleh Pengirim.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Kode Setor”</strong> adalah kode yang diberikan pada Remid Transfer Link kepada
                            Pengirim sebagai acuan informasi uang tunai yang akan diserahkan kepada Mitra
                            yang akan digunakan sebagai acuan informasi Dana yang akan disetorkan dan/atau
                            diserahkan kepada Mitra.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Kode Tarik”</strong> adalah kode yang diberikan kepada Penerima setelah memasukkan
                            Kode MTCN yang diperoleh dari Pengirim pada Remid Transfer Link, yang akan
                            digunakan sebagai acuan informasi Dana yang akan dicairkan dan/atau diterima dari
                            Mitra.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>"KTP”</strong> adalah Kartu Tanda Penduduk.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Layanan”</strong> adalah segala layanan yang dapat diakses oleh Konsumen Remid melalui
                            Remid Transfer Link termasuk di dalamnya adalah Layanan atau layanan-layanan
                            lain yang mungkin dioperasikan, disediakan, ditawarkan dan dibuat tersedia, diubah
                            dan/atau ditambahkan dari waktu ke waktu oleh Remid sendiri atau bersama-sama
                            dengan Mitra.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Mitra”</strong> adalah pihak ketiga yang telah mengadakan kerja sama dengan Remid yang
                            mana menurut fungsinya Pengirim dan/atau Penerima dapat melakukan kegiatan
                            Transfer Dana. Dalam konteks ini, Mitra akan bertindak untuk dan atas nama Remid
                            sebagai tempat dilakukannya: (i) penerimaan Dana dari Pengirim atau (ii)
                            penyerahan Dana kepada Penerima atas transaksi Transfer Dana yang terjadi
                            menggunakan fitur Remid Transfer Link.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Pencucian Uang”</strong> adalah segala perbuatan yang memenuhi unsur-unsur tindak
                            pidana sesuai dengan ketentuan dalam Undang-Undang Nomor 8 Tahun 2010
                            tentang Pencegahan dan Pemberantasan Tindak Pidana Pencucian Uang dan/atau
                            peraturan pelaksananya, sebagaimana diubah dari waktu ke waktu.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Penerima”</strong> adalah pihak yang berhak menggunakan Kode MTCN yang diterima dari
                            Pengirim dan memilih untuk melakukan pencairan Dana pada Mitra melalui fitur
                            Remid Transfer Link.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Pengirim”</strong> adalah pihak yang melakukan Transfer Dana melalui fitur Remid Transfer
                            Link yang disediakan oleh Remid atau pihak yang menerbitkan Perintah Transfer
                            Dana kepada Remid untuk melakukan pemindahan Dana agar dapat diterima oleh
                            Penerima.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Perbuatan Curang”</strong> adalah adanya indikasi dan/atau terbukti terjadinya salah satu
                            dari hal-hal berikut ini:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>penyalahgunaan dan/atau transaksi fiktif; dan/atau</li>
                                <li style={{ listStyleType: 'lower-roman' }}>penipuan dan/atau kebohongan.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Peraturan Pelindungan Data Pribadi”</strong> adalah semua undang-undang, peraturan,
                            perintah, persyaratan peraturan, oleh undang-undang, tata cara, aturan,
                            pemberitahuan, interpretasi yudisial, standar nasional, undang-undang bawahan,
                            dan undang-undang lain, atau instrumen hukum serupa lainnya, termasuk namun
                            tidak terbatas pada Undang-Undang No. 27 Tahun 2022 tentang Pelindungan Data
                            Pribadi yang berkaitan dengan pemrosesan Data Pribadi, yang berlaku untuk suatu
                            Pihak, atau yang berlaku sehubungan dengan Data Pribadi dan termasuk setiap
                            amandemennya serta peraturan turunannya.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Perintah Transfer Dana”</strong> adalah perintah dari Pengirim untuk memindahkan
                            sejumlah Dana kepada Penerima yang dapat dicairkan oleh Penerima pada channel
                            Mitra dalam bentuk termasuk namun tidak terbatas pada outlet atau gerai fisik yang
                            dimiliki oleh Mitra.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Remid”</strong> adalah sebagaimana didefinisikan pada bagian Pendahuluan Syarat dan
                            Ketentuan ini.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Syarat dan Ketentuan”</strong> adalah syarat dan ketentuan ini.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Transfer Dana”</strong> adalah serangkaian kegiatan yang dimulai dengan Perintah
                            Transfer Dana dari Pengirim yang bertujuan memindahkan sejumlah Dana kepada
                            Penerima yang disebutkan dalam Perintah Transfer Dana tersebut sampai dengan
                            diterimanya Dana oleh Penerima.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Verifikasi”</strong> adalah proses identifikasi dan verifikasi terhadap Pengirim dan/atau
                            Penerima.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Remid Transfer Link”</strong> adalah aplikasi berbasis website, android ataupun ios untuk
                            membantu Konsumen Remid melakukan aktivitas Transfer Dana baik tunai ke tunai,
                            tunai ke akun , akun ke tunai maupun akun ke akun</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>“Konsumen Remid”</strong> adalah Pengirim, Penerima atauapun pihak lainnya yang sudah
                            melakukan registrasi di Remid melalui Remid Transfer Link.</li>
                    </ul>
                );
            case "Pendaftaran Akun dan Proses Verifikasi":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Akun hanya diperuntukkan untuk orang-perorangan.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Untuk menggunakan layanan Remid, Anda harus melakukan registrasi Akun. Proses registrasi Akun antara lain dapat dilakukan dengan mengakses website: https://transferlink.rem.id/register dengan cara memasukkan nomor kontak, email, dan membuat kata sandi (password).</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda diwajibkan secara mandiri untuk membuat kata sandi Akun (<strong>“Passcode”</strong>) yang terdiri dari 8 (delapan) digit kombinasi angka dan huruf.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Remid akan melakukan verifikasi akun nomor kontak dan email Anda dengan mengirimkan kode sandi sekali pakai (kode OTP ke nomor kontak yang Anda daftarkan melalui layanan pesan singkat (short message service/SMS).</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Setelah Anda login/masuk ke halaman Remid Transfer Link maka Anda akan diminta untuk mengatur pin transaksi.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Untuk dapat melakukan aktivitas Transfer Dana, akan dilakukan prosedur KYC (Know Your Customer) terhadap Anda. Atas hal ini, Anda perlu melakukan aktivasi E-KTP dengan cara mengisi data yang diminta pada halaman registrasi dalam Remid Transfer Link, melakukan foto identitas, dan swafoto.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Remid akan melakukan Verifikasi atas informasi dan dokumen yang Anda sampaikan dan menentukan apakah Anda telah memenuhi persyaratan. Remid akan memberikan informasi terkait status pendaftaran, dimana jika sudah Verifikasi maka Akun Anda akan menjadi Terverifikasi sedangkan untuk Akun yang belum Verifikasi akan dikategorikan sebagai Belum Terverifikasi.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda menyetujui jika seluruh informasi dan dokumen yang Anda sampaikan kepada Kami dapat Kami teruskan ke pihak ketiga sehubungan dengan proses KYC Anda.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Persetujuan atau penolakan atas permohonan KYC Anda merupakan hak dan kewenangan Kami sepenuhnya. Kami dapat menolak permohonan Layanan yang diminta oleh Anda atau sewaktu-waktu dengan atau tanpa memberitahukan alasannya, apabila terdapat keraguan atas kebenaran dan/atau keaslian atas informasi dan/atau dokumen yang Anda sampaikan dalam proses KYC, tidak sesuai dengan ketentuan yang Kami tetapkan serta ketentuan hukum dan peraturan perundang-undangan yang berlaku di wilayah Republik Indonesia.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>
                            Anda memahami bahwa terdapat 2 (dua) jenis Akun yang berlaku di Remid:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Akun Belum Terverifikasi (<strong>“Belum Terverifikasi”</strong>): merupakan Akun yang belum melakukan melalui tahap KYC. Anda selaku Konsumen Remid dapat menikmati fitur terbatas yang disediakan oleh Remid. Untuk dapat menikmati seluruh fitur layanan yang disediakan oleh Remid, seperti melakukan pengiriman, pengambilan, pembatalan uang, Anda harus berhasil melewati prosedur KYC.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Akun Terverifikasi (<strong>“Terverifikasi”</strong>): merupakan Akun yang berhasil melalui prosedur KYC.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda dapat menikmati seluruh fitur yang disediakan oleh Remid setelah Akun dinyatakan Terverifikasi, termasuk fitur pengiriman, pengambilan, dan pembatalan Dana.</li>
                    </ul>
                );
            case "Ketentuan Umum":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Dengan menggunakan Layanan, Anda menyatakan dan menjamin bahwa Anda telah
                            cakap hukum, serta tidak di bawah pengampuan.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Atas penggunaan Anda terhadap Layanan, maka Anda setuju untuk menerima
                            pesan dan/atau pemberitahuan dalam bentuk apa pun dari Kami dan/atau pihak lain
                            yang dapat menawarkan layanan yang mungkin dibutuhkan sesuai dengan
                            kebutuhan Anda, termasuk namun tidak terbatas yang akan diadakan melalui short
                            message service (sms), dan Anda berjanji untuk memberikan tanggapan atau respon
                            (apabila relevan) sebagaimana secara wajar diminta oleh Kami.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Dalam hal dipersyaratkan oleh ketentuan Hukum yang berlaku, dan/atau menurut
                            diskresi Kami perlu diadakan untuk mencegah transaksi Pencucian Uang dan/atau
                            Perbuatan Curang, dengan menggunakan Layanan, Anda setuju bahwa Kami atau
                            Mitra atau pihak lain yang ditunjuk oleh Kami, Mitra ataupun institusi regulator terkait
                            untuk melaksanakan hal ini, untuk dari waktu ke waktu melakukan Verifikasi
                            sehubungan dengan penggunaan Anda terhadap layanan Kami.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Dengan menyetujui Syarat dan Ketentuan ini, Anda memahami, mengerti,
                            menyetujui dan mengizinkan bahwa Kami dapat dan berhak meminta, menyimpan,
                            mengumpulkan, memproses, mengungkapkan, melakukan verifikasi, pemeriksaan,
                            penelaahan, penilaian dan pengkinian atas setiap informasi termasuk namun tidak
                            terbatas pada Data Pribadi yang Anda berikan atau yang diperoleh dari pihak lain,
                            termasuk untuk bekerja sama dengan pihak ketiga untuk melakukan pemenuhan
                            prosedur KYC, pemeriksaan latar belakang, riwayat dan status keuangan Anda,
                            pemeriksaan penipuan, serta pemeriksaan lainnya yang diperlukan sesuai kebijakan
                            Kami.
                            Dalam hal ini, Anda memahami, mengerti, menyetujui dan mengizinkan Remid untuk
                            dapat bekerja sama dengan pihak lain sebagaimana diperlukan untuk akses dan
                            penggunaan Layanan, yang antara lain termasuk namun tidak terbatas pada
                            pihak-pihak sebagaimana tercantum di bawah ini:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Institusi atau lembaga pemerintahan yang memiliki pusat data kependudukan
                                    untuk tujuan verifikasi keabsahan identitas Anda atau pihak lain yang memiliki
                                    akses atas pusat data kependudukan untuk tujuan verifikasi keabsahan
                                    identitas Anda;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Pihak penyelenggara tanda tangan elektronik untuk kebutuhan pembuatan
                                    tanda tangan elektronik;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Pihak penyelenggara E-KYC sebagaimana dijelaskan lebih lanjut pada Syarat
                                    dan Ketentuan ini;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>otoritas pengawas yang berwenang untuk keperluan investigasi
                                    permasalahan atas penggunaan layanan yang diselenggarakan oleh Remid
                                    atau untuk hal-hal lain sesuai dengan permintaan dari otoritas pengawas
                                    yang berwenang secara tertulis kepada Remid;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Pihak asosiasi yang ditunjuk secara resmi oleh otoritas pemerintahan yang
                                    berwenang ;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Pusat Pelaporan dan Analisis Transaksi Keuangan apabila Remid dan/atau
                                    Mitra mengidentifikasi adanya transaksi keuangan mencurigakan
                                    sebagaimana diatur pada ketentuan Hukum yang berlaku terkait
                                    pemberantasan dan pencegahan tindak pidana pencucian uang dan
                                    pendanaan terorisme;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Otoritas penegak hukum di Republik Indonesia antara lain Kepolisian Negara
                                    Republik Indonesia, Komisi Pemberantasan Korupsi, pengadilan, dan lain
                                    sebagainya untuk keperluan apapun terkait penegakan hukum (antara lain,
                                    namun tidak terbatas pada kasus pelanggaran hukum, kejahatan, pencucian
                                    uang, pendanaan terorisme, dan korupsi) yang dalam hal ini wajib diminta
                                    secara resmi dan tertulis oleh otoritas yang terkait tersebut; dan</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Direktorat Jenderal Pajak Republik Indonesia untuk pemenuhan kewajiban
                                    pelaporan sesuai ketentuan regulasi perpajakan yang berlaku antara lain
                                    peraturan mengenai pertukaran otomatis informasi perpajakan (automatic
                                    exchange of information).</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Remid dapat menggunakan atau mengungkapkan akses, aktivitas atau penggunaan
                            Akun dan/Layanan oleh Anda serta Data Pribadi Anda dalam rangka memenuhi
                            ketentuan Hukum termasuk Peraturan Pelindungan Data Pribadi guna memastikan
                            kepatuhan Anda, proses penegakan hukum atau pengambilan tindakan pencegahan
                            lebih lanjut sehubungan dengan kegiatan yang tidak berwenang, dugaan tindak
                            pidana atau pelanggaran hukum atau peraturan perundang-undangan serta untuk
                            tujuan lain yang diatur pada Syarat dan Ketentuan ini dan Kebijakan Privasi.
                            Sehubungan dengan penggunaan atau pengungkapan tersebut, Anda melepaskan
                            dan membebaskan Kami dari segala klaim, tuntutan, ganti kerugian yang mungkin
                            timbul sehubungan dengan penggunaan atau pengungkapan tersebut.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Sebagai tambahan dari kewajiban Anda yang diatur dalam bagian lain dari Syarat
                            dan Ketentuan ini, Anda memiliki kewajiban sebagai berikut:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda wajib menggunakan Data Pribadi (diantaranya data diri dan nomor
                                    telepon) yang valid saat menyediakan Informasi Data Transfer Dana. Anda
                                    bertanggung jawab secara penuh apabila muncul klaim yang berasal dari
                                    Data Pribadi yang disediakan oleh Anda;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda wajib untuk selalu berhati-hati saat melakukan transaksi dengan
                                    menggunakan Layanan; dan</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda wajib bertanggung jawab sepenuhnya atas keamanan kerahasiaan
                                    Akun beserta data yang terdapat di dalamnya termasuk namun tidak terbatas
                                    pada Data Pribadi, Akun, e-mail, dan kata sandi (password) Konsumen
                                    Remid serta setiap kode verifikasi (baik Kode MTCN, Kode Setor maupun
                                    Kode Tarik) yang dihasilkan dan dikirimkan oleh sistem Remid atau Mitra.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda selaku Konsumen Remid membebaskan Remid dari segala tanggung jawab,
                            tuntutan, permintaan, perintah, ganti kerugian, klaim, pengeluaran, kerusakan,
                            kewajiban pembayaran biaya, dan/atau gugatan apapun yang mungkin timbul atas
                            penyalahgunaan Akun yang diakibatkan oleh kelalaian, kesalahan dan/atau
                            penyalahgunaan Akun oleh Anda.
                            Apabila terdapat indikasi penyalahgunaan atas Akun, Anda selaku Konsumen Remid
                            diharuskan untuk sesegera mungkin menghubungi Remid guna meminta bantuan
                            Kami (melalui contact center <strong>021 - 50127667</strong> atau email resmi Remid <strong>cs@rem.id</strong> atau
                            sarana komunikasi lainnya yang Remid dapat sediakan dari waktu ke waktu) untuk
                            memeriksa, menangguhkan, atau menghentikan penyalahgunaan akses terhadap
                            Akun yang relevan.</li>
                    </ul>
                );
            case "Pelaksanaan Transfer Dana":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>
                            Anda hanya dapat menggunakan Layanan Transfer Dana yang disediakan oleh
                            Remid melalui Remid Transfer Link setelah Anda melakukan pendaftaran Akun dan
                            terverifikasi oleh Kami melalui prosedur KYC.
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>
                            Untuk menggunakan layanan Transfer Dana berupa pengiriman Dana, Anda selaku
                            Pengirim akan:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Memberikan Perintah Transfer Dana dengan memilih menu ‘Pengiriman
                                    Uang’ pada Remid Transfer Link;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Melengkapi Formulir Pengiriman yang tercantum pada Remid Transfer Link
                                    sebagai prasyarat untuk melakukan pengiriman uang yang memuat Informasi
                                    Data Transfer Dana (<strong>“Formulir Pengiriman”</strong>) dan lokasi Mitra untuk
                                    melakukan penyetoran;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Memperoleh Kode Setor pada Akun Transfer Link untuk kemudian ditunjukan
                                    dan diverifikasi pada channel Mitra dan menyerahkan Dana yang akan
                                    dikirimkan;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Setelah dinyatakan berhasil, Mitra akan menerbitkan struk yang berisi Kode
                                    MTCN untuk diteruskan oleh Anda kepada Penerima.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Untuk menggunakan layanan Transfer Dana berupa penerimaan Dana, Anda selaku
                            Penerima akan:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Setelah diinformasikan oleh Pengirim bahwa terdapat pengiriman Dana
                                    kepada Anda selaku Penerima, Anda setelah memiliki Akun pada Remid
                                    Transfer Link memilih menu ‘Pengambilan Uang’ pada Remid Transfer Link;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Melengkapi Formulir Penerimaan yang tercantum pada Remid Transfer Link
                                    yang memuat Informasi Data Transfer Dana (<strong>“Formulir Penerimaan”</strong>) dan
                                    Kode MTCN;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Memperoleh Kode Tarik pada Akun Transfer Link untuk kemudian ditunjukan
                                    dan diverifikasi pada channel Mitra.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda akan menerima Dana yang dikirimkan oleh Pengirim dari Mitra setelah
                                    proses Verifikasi berhasil dan diadakan Akseptasi.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Kami hanya akan melaksanakan Perintah Transfer Dana yang telah berhasil
                            diadakan Verifikasi dan Akseptasi.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda wajib mengikuti serangkaian proses Verifikasi yang disyaratkan sebelum
                            Perintah Transfer Dana dapat diadakan Akseptasi.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda memahami dan menyetujui bahwa satu-satunya bukti pelaksanaan Transfer
                            Dana yang sah dan dapat dipergunakan adalah data-data yang tercatat dalam
                            sistem Layanan.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda (selaku Penerima) wajib memeriksa dengan benar jumlah Dana yang diterima
                            sebelum meninggalkan channel Mitra yang bekerja sama dengan Remid. Remid
                            tidak bertanggung jawab apabila terjadi ketidaksesuaian jumlah Dana setelah Anda
                            (selaku Penerima) meninggalkan channel Mitra.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Kami tidak bertanggung jawab atas akibat yang terjadi apabila terdapat Informasi
                            Data Transfer Dana yang tidak benar, tidak akurat, maupun menyesatkan
                            sebagaimana Anda sediakan dalam Formulir Pengiriman dan Formulir Penerimaan
                            yang menyebabkan kerugian pada Anda maupun pihak ketiga mana pun.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Informasi Data Transfer Dana dan Kode MTCN adalah rahasia dan karenanya Anda
                            wajib menjaga informasi tersebut serta tidak akan mengungkapkan informasi
                            tersebut kepada pihak yang tidak berhak.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda setuju untuk menanggung seluruh risiko terkait pengungkapan informasi di
                            dalam Formulir Pengiriman dan Formulir Penerimaan kepada pihak ketiga mana pun
                            dan bertanggung jawab penuh atas setiap konsekuensi yang berkaitan dengan itu
                            serta membebaskan Kami dari tanggung jawab apapun sehubungan dengan
                            pengungkapan informasi yang dimaksud.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Kami berhak untuk menolak Perintah Transfer Dana dan menghentikan Layanan
                            Transfer Dana apabila:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Pengirim membatalkan Perintah Transfer Dana sesuai ketentuan yang diatur
                                    pada Syarat dan Ketentuan ini;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Terdapat indikasi Perbuatan Curang atas hasil Verifikasi terhadap Pengirim
                                    dan/atau Penerima.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Terdapat penetapan atau putusan pengadilan dan/atau perintah oleh pihak
                                    yang berwenang dan/atau diwajibkan berdasarkan Hukum yang berlaku.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Dalam hal terjadi kesalahan pengiriman Dana pada pelaksanaan Transfer Dana,
                            Anda bersedia mengembalikan Dana tersebut ke Mitra di mana pengambilan Dana
                            diadakan.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Sesuai dengan ketentuan Hukum yang berlaku, dalam hal Transfer Dana untuk
                            diambil secara tunai dan terdapat Dana Yang Tidak Diambil, maka Dana Yang Tidak
                            Diambil tersebut oleh Mitra akan dikembalikan kepada Pengirim dengan merujuk
                            pada Hukum yang berlaku sehubungan dengan transfer dana serta kebijakan
                            internal Remid dan Mitra. Dalam hal terdapat Dana Yang Tidak Diambil oleh
                            Pengirim, maka dalam waktu 180 (seratus delapan puluh) Hari Kalender terhitung
                            sejak tanggal pengiriman Dana, Dana akan diserahkan kepada Balai Harta
                            Peninggalan oleh Remid. Remid akan memberikan informasi kepada Konsumen
                            Remid sesuai periode yang dipersyaratkan berdasarkan ketentuan internal Remid
                            dan Hukum yang berlaku, melalui short message service (SMS) bahwa terdapat
                            Dana Yang Tidak Diambil (apabila relevan).</li>
                    </ul>
                );
            case "Biaya Layanan (Service fee)":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Dengan menggunakan Layanan, Anda memahami dan setuju bahwa Kami berhak
                            mengenakan dan memperoleh bagian atas Biaya Layanan yang dikenakan terhadap
                            pelaksanaan Transfer Dana yang diselenggarakan melalui Layanan.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}><strong>KAMI TIDAK MENGENAKAN BIAYA LAYANAN SELAIN JUMLAH BIAYA LAYANAN
                            YANG DIKENAKAN KEPADA PENGIRIM SEBAGAIMANA TERTERA PADA BUKTI
                            TRANSAKSI PENGIRIMAN DANA YANG TELAH BERHASIL DILAKUKAN.</strong></li>
                    </ul>
                );
            case "Kode MTCN dan Masa Berlaku Kode MTCN":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda memahami Kode MTCN wajib dijaga kerahasiaannya dan Anda dan wajib
                            memastikan Kode MTCN hanya akan digunakan oleh Penerima yang berhak atas
                            Kode MTCN tersebut. <strong>KAMI TIDAK AKAN PERNAH MEMINTA ANDA UNTUK
                                MEMBERITAHUKAN KODE MTCN KEPADA KAMI.</strong></li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda memahami bahwa Remid tidak berwenang untuk memberitahukan Informasi
                            Data Transfer Dana dan Kode MTCN kepada Penerima.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Remid tidak bertanggung jawab apabila IKode MTCN diterima oleh orang yang tidak
                            berhak dan atas penerimaan tersebut, kemudian dilakukan pengambilan Dana oleh
                            pihak bersangkutan.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Masa berlaku Kode MTCN adalah 30 (tiga puluh) Hari Kalender sejak diterbitkannya
                            Kode MTCN tersebut. Apabila kode MTCN telah habis masa berlakunya (expired),
                            Pengirim dapat memperpanjang Kode MTCN dengan cara menghubungi call center
                            Remid di nomor <strong>021 - 50127667</strong> atau melalui email ke <strong>cs@rem.id</strong>.</li>
                    </ul>
                );
            case "Pembatalan Perintah Transfer Dana":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda dalam hal selaku Pengirim dapat melakukan pembatalan Perintah Transfer
                            Dana sepanjang belum dilakukan pengambilan Dana oleh Penerima. Pembatalan
                            Perintah Transfer Dana hanya dapat dilakukan oleh Anda selaku Pengirim dengan
                            mengakses menu ‘Pembatalan Pengiriman Uang’ pada Remid Transfer Link, mengisi
                            kode pembatalan dan mendatangi channel Mitra untuk pengambilan dana tunai.
                            Segala keluhan dan/atau gugatan dari Penerima atas pembatalan Perintah Transfer
                            Dana yang diajukan oleh Pengirim, sepenuhnya merupakan tanggung jawab
                            Pengirim dan oleh karenanya Pengirim akan membebaskan Remid dan/atau mitra
                            yang bekerja sama dengan Remid atas segala keluhan, gugatan dan/atau tuntutan
                            sebagaimana dimaksud.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Atas pembatalan Perintah Transfer Dana, Remid akan mengembalikan Dana
                            sesuai jumlah nominal yang tertera pada Perintah Transfer Dana. Anda memahami
                            dan setuju dengan diadakannya pengembalian Dana tersebut, Biaya Layanan yang
                            telah diterima oleh Remid tetap akan berhak dimiliki oleh Remid dan tidak dapat
                            diklaim kembali oleh Pengirim.</li>
                    </ul>
                );
            case "Kewajiban Konsumen Remid dan Larangan Konsumen Remid":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Kewajiban Anda selaku Konsumen Remid sebagai tambahan dari kewajiban Anda
                            yang diatur dalam bagian lain dari Syarat dan Ketentuan ini, Anda memiliki kewajiban
                            sebagai berikut:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda wajib menggunakan data diri dan nomor ponsel yang valid saat
                                    melakukan registrasi Akun.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Bertanggung jawab secara penuh apabila muncul klaim yang berasal dari
                                    data diri ataupun nomor ponsel yang didaftarkan oleh Anda, sehingga Akun
                                    dikuasai pihak lain dan menjadi tidak aktif atau diblokir berdasarkan kebijakan
                                    perusahaan telekomunikasi.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda memiliki tanggung jawab terhadap kepemilikan ponsel atau perangkat
                                    lainnya yang Anda gunakan untuk mengakses Remid Transfer Link dan/atau
                                    Layanan.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Wajib menjaga nomor ponsel dari antara lain kehilangan/kerusakan dan Anda
                                    wajib mencegah agar nomor ponsel miliknya tidak disalahgunakan oleh pihak
                                    lain/dipalsukan.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Memiliki kewajiban untuk selalu berhati-hati saat melakukan transaksi dengan
                                    menggunakan seluruh Layanan.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Perangkat Konsumen Remid dilarang untuk:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>menggunakan perangkat yang tidak resmi, yang telah dimodifikasi dan/atau
                                    tidak sesuai dengan peruntukan dan fungsi sistem operasi aslinya, termasuk
                                    namun tidak terbatas pada perangkat yang telah di rooting atau jail-broken;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>menggunakan software/hardware yang tidak resmi untuk dapat menampilkan,
                                    mendapatkan kontrol, meniru, memanipulasi dan/atau memindahkan aplikasi
                                    Remid ke perangkat lain yang tidak resmi, mengumpulkan dan/atau mencuri
                                    data Remid dan Konsumen Remid, melakukan aktivitas crawling/scraping,
                                    dan/atau aktivitas lainnya yang dapat merugikan Remid dan/atau Anda
                                    dan/atau bertentangan dengan Hukum yang berlaku, termasuk namun tidak
                                    terbatas pada penggunaan emulator dan software/hardware sejenis; dan
                                    Kami berhak untuk memblokir Akun dan/atau perangkat yang Anda gunakan
                                    atas pelanggaran ketentuan ini dan tidak bertanggung jawab atas
                                    pelanggaran, penyalahgunaan, kerusakan dan/atau kerugian yang
                                    ditimbulkan. Selain itu, Kami berhak untuk mengajukan tuntutan, gugatan
                                    dan/atau meminta ganti kerugian kepada Anda sehubungan dengan
                                    pelanggaran atas ketentuan ini.</li>
                            </ul>
                        </li>
                    </ul>
                );
            case "Batasan Tanggung Jawab Kami dan Ganti Rugi":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Untuk mencegah terjadinya penyalahgunaan Informasi Data Transfer Dana, Kode
                            MTCN, Kode Tarik dan Kode Setor, Anda wajib mengingat dan menjaga kerahasian
                            Informasi Data Transfer Dana maupun segala aktivitas/transaksi yang dilakukan oleh
                            Anda dan tidak memberitahukannya kepada pihak manapun kecuali kepada
                            Penerima. Anda bertanggung jawab sepenuhnya atas segala risiko yang timbul
                            sehubungan dengan kelalaian Anda dalam menjaga kerahasiaan Informasi Data
                            Transfer Dana, Kode MTCN, Kode Tarik dan Kode Setor.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Remid tidak bertanggung jawab atas kerugian apa pun yang disebabkan oleh Hukum
                            yang berlaku maupun tindakan pemerintah atau sebab-sebab lainnya di luar
                            kemampuan Remid.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda bersedia apabila sewaktu-waktu Mitra mengarahkan Anda untuk menuju ke
                            channel Mitra terdekat lainnya agar Anda dapat mengadakan pengambilan Dana.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Remid hanya berkewajiban untuk menanggung kerugian langsung yang diderita
                            Pengirim sepanjang kerugian tersebut dapat dibuktikan karena kesalahan Remid,
                            dengan jumlah maksimum sebesar nominal transaksi Perintah Transfer Dana telah
                            dibayarkan. Remid tidak akan bertanggung jawab atas kerugian tidak langsung,
                            kerugian immateriil, insidentil, khusus atau konsekuensial, yang timbul dari atau
                            sehubungan dengan penggunaan atau ketidakmampuan untuk melakukan Perintah
                            Transfer Dana.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Remid senantiasa berupaya untuk menjalankan Perintah Transfer Dana dengan
                            sebaik-baiknya. Namun, <strong>Kami tidak dapat menjamin bahwa Layanan yang
                                disediakan akan beroperasi secara terus-menerus atau akses Layanan yang
                                disediakan dapat selalu sempurna.</strong> Kami tidak dapat memungkiri kemungkinan
                            Layanan yang Kami sediakan dapat sewaktu-waktu terganggu akibat permasalahan
                            yang tidak dapat diprediksi sebelumnya atau akibat hal-hal di luar kontrol Remid,
                            yang atas hal ini akan Kami perbaiki secepatnya. Dalam hal terjadi kegagalan
                            Transfer Dana karena adanya permasalahan dimaksud, Remid akan melakukan
                            Transfer Dana ulang sesegera mungkin setelah permasalahan terselesaikan. Dalam
                            hal terjadi gangguan demikian Pengirim akan membebaskan Remid dari segala
                            tuntutan atau gugatan karenanya.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Sepanjang diperbolehkan oleh Hukum, segala kerusakan yang terjadi pada jaringan
                            komputer, telepon seluler, aplikasi, atau perangkat lainnya milik Konsumen Remid
                            akibat penggunaan Layanan merupakan tanggung jawab Konsumen Remid
                            sepenuhnya.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Sepanjang diizinkan Hukum yang berlaku, Kami dengan ini tidak bertanggung jawab
                            dan Anda dengan ini membebaskan Remid dari segala akibat, kerugian, dan/atau
                            kerusakan yang terjadi akibat termasuk namun tidak terbatas pada:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>kerugian yang timbul dari gangguan pada koneksi internet atau jaringan
                                    komunikasi sehingga menyebabkan gagalnya Transfer Dana;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>transaksi yang mendasari dilakukannya Transfer Dana antara Pengirim
                                    dengan Penerima;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>(a) hilangnya akses kepada Layanan bukan karena kesalahan Remid, (ii)
                                    hilangnya keuntungan atau pendapatan yang diperkirakan; (ii) hilangnya data
                                    bukan karena kesalahan Remid untuk setiap kasus baik secara langsung
                                    maupun tidak langsung;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>setiap dan segala kelalaian dari Anda dalam menjaga keamanan dan
                                    kerahasiaan nama Akun, kata sandi (password), pin transaksi, Kode MTCN
                                    dan/atau kode sistem keamanan lainnya yang berfungsi untuk
                                    mengotentifikasi suatu tindakan yang dilakukan oleh Konsumen Remid;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>tuntutan hukum, gugatan, dan/atau klaim dari Penerima dan/atau pihak ketiga
                                    lainnya terkait dengan kelalaian atau kesalahan Pengirim dalam
                                    melaksanakan Perintah Transfer Dana;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>tuntutan hukum, gugatan, dan/atau klaim dari pihak manapun yang timbul
                                    akibat penggunaan atau pengungkapan atas Informasi Data Transfer Dana;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>risiko yang timbul akibat dari kesalahan informasi di dalam Formulir
                                    Pengiriman, termasuk namun tidak terbatas pada kesalahan data Penerima,
                                    kesalahan jumlah Dana, dan Perintah Transfer Dana ganda karena
                                    kesalahan Anda yang masuk ke sistem Kami sehingga menyebabkan
                                    transaksi Transfer Dana ganda;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>adanya perintah dari lembaga pemerintah yang berwenang atau berdasarkan
                                    suatu ketentuan Hukum yang berlaku, mewajibkan Remid untuk
                                    menghentikan pemrosesan Perintah Transfer Dana terkait;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>adanya permintaan atau kebijakan Mitra untuk tidak dapat meneruskan suatu
                                    Perintah Transfer Dana yang disebabkan oleh analisa resiko kemungkinan
                                    terjadinya suatu pelanggaran Hukum;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>gangguan, bug, ketidakakuratan serta kecacatan yang ada pada Remid
                                    Transfer Link sepanjang Remid telah melaksanakan upaya wajar yang
                                    diperlukan untuk memperbaiki Remid Transfer Link;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>virus ataupun hal berbahaya lainnya yang diperoleh dengan mengakses
                                    Remid Transfer Link;</li>
                                <li style={{ listStyleType: 'lower-roman' }}>peretasan yang dilakukan pihak ketiga terhadap Remid Transfer Link
                                    dan/atau Akun milik Konsumen Remid; dan/atau</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Keadaan Kahar, sebagaimana dijelaskan lebih lanjut pada Syarat dan
                                    Ketentuan ini.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda dengan ini setuju bahwa akan melepaskan Kami dari tuntutan ganti rugi dan
                            menjaga Kami (termasuk afiliasi, direktur, komisaris, pemegang saham, karyawan,
                            dan/atau wakil sah Kami) dari setiap klaim atau tuntutan, termasuk biaya hukum
                            yang wajar, yang dilakukan oleh pihak ketiga yang timbul akibat pelanggaran Syarat
                            dan Ketentuan ini oleh Anda, pelaksanaan Perintah Transfer Dana yang tidak
                            semestinya dan/atau karena pelanggaran yang Anda lakukan terhadap Hukum yang
                            berlaku atau hak-hak pihak ketiga.</li>
                    </ul>
                );
            case "Penangguhan Layanan, Penutupan Akun dan Pemblokiran Akun":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Pembatasan Hak Akses Akun
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Remid dengan diskresinya sendiri dapat melakukan pembatasan hak akses
                                    atas Konsumen Remid terhadap Remid Transfer Link serta Akun dan/atau
                                    penangguhan Layanan untuk sementara waktu atau untuk seterusnya <strong>tanpa
                                        pemberitahuan terlebih dahulu</strong> oleh karena sebab-sebab sebagai berikut:
                                    <ul style={{ listStyleType: 'none' }}>
                                        <li style={{ listStyleType: 'decimal' }}>Pelanggaran terhadap Syarat dan Ketentuan ini yang dilakukan dari
                                            Akun Konsumen Remid;</li>
                                        <li style={{ listStyleType: 'decimal' }}>Pelanggaran terhadap Hukum yang berlaku yang dilakukan dari Akun
                                            Konsumen Remid;</li>
                                        <li style={{ listStyleType: 'decimal' }}>Adanya dugaan transaksi keuangan mencurigakan baik yang
                                            diketahui oleh Remid, Mitra, dan/atau regulator yang berwenang;
                                            dan/atau</li>
                                        <li style={{ listStyleType: 'decimal' }}>Alasan lain yang berpotensi merugikan Remid dan/atau Mitra baik
                                            secara langsung maupun tidak langsung.</li>
                                    </ul>
                                </li>
                                <li style={{ listStyleType: 'lower-roman' }}>Selama dalam pembatasan akses dan/atau penangguhan Layanan,
                                    Konsumen Remid tidak dapat menggunakan Layanan dan setiap serta
                                    semua Perintah Transfer Dana yang telah diberikan kepada Remid
                                    sebelumnya tidak akan dijalankan selama Konsumen Remid berada dalam
                                    masa pembatasan akses dan/atau penangguhan Layanan.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Selain pembatasan akses dan/atau penangguhan Layanan sebagaimana
                                    yang disebutkan di atas, Remid dapat sewaktu-waktu melakukan
                                    pembatasan akses dan/atau penangguhan Layanan berdasarkan perintah
                                    dari regulator yang berwenang atau untuk mematuhi ketentuan Hukum yang
                                    berlaku.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Selama diperbolehkan oleh Hukum yang berlaku, Remid akan
                                    memberitahukan melalui email kepada Konsumen Remid perihal pembatasan
                                    akses dan/atau penangguhan Layanan serta sebab dilakukannya hal
                                    tersebut.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Berdasarkan hasil pemeriksaan yang Remid lakukan, Remid dapat
                                    memutuskan untuk: (i) mengangkat pembatasan akses dan penangguhan
                                    Layanan dan mengembalikan hal-hal tersebut seperti sediakala, atau (ii)
                                    menghentikan akses serta Layanan secara permanen.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda selaku Konsumen Remid memahami bahwa seluruh kerugian yang
                                    diderita oleh Anda selama pembatasan akses dan penangguhan Layanan
                                    merupakan tanggung jawab Konsumen Remid sepenuhnya.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Penangguhan Layanan untuk sementara waktu atau permanen tidak
                                    membatasi hak Kami untuk menuntut ganti kerugian, melaporkan Konsumen
                                    Remid kepada pihak berwenang, dan/atau mengambil tindakan lain yang
                                    Remid anggap perlu untuk melindungi kepentingan Remid.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Penutupan Akun
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Penutupan Akun milik Konsumen Remid dapat terjadi apabila:
                                    <ul style={{ listStyleType: 'none' }}>
                                        <li style={{ listStyleType: 'decimal' }}>Kami mendapat permintaan dari Anda selaku pemilik Akun dan/atau
                                            ahli waris sah Anda untuk menutup Akun Anda dengan ketentuan
                                            Anda atau ahli waris Anda telah menyelesaikan segala kewajiban
                                            kepada Kami;</li>
                                        <li style={{ listStyleType: 'decimal' }}>Akun atau transaksi dalam Akun Anda dinyatakan fraud atau
                                            terindikasi sebagai sarana atau digunakan untuk tindak pidana,
                                            berdasarkan surat dan/atau putusan pengadilan dan/atau permintaan
                                            penutupan dari otoritas, instansi pemerintahan atau kepolisian terkait
                                            yang mengikat secara Hukum;</li>
                                        <li style={{ listStyleType: 'decimal' }}>Kebijakan Remid sesuai dengan peraturan perundangan yang
                                            berlaku; dan/atau</li>
                                        <li style={{ listStyleType: 'decimal' }}>Penghentian kegiatan operasional Remid.</li>
                                    </ul>
                                </li>
                                <li style={{ listStyleType: 'lower-roman' }}>Berikut adalah hal yang perlu diperhatikan oleh Anda selaku Konsumen
                                    Remid sehubungan dengan penutupan Akun:
                                    <ul style={{ listStyleType: 'none' }}>
                                        <li style={{ listStyleType: 'decimal' }}>Pengiriman uang yang dalam status pending (belum diambil atau
                                            diterima oleh Penerima) wajib dilakukan pembatalan.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Semua sambungan baik dengan pihak perbankan maupun pihak
                                            lainnya dapat diinisiasi untuk dilakukan pemutusan hubungan.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Penjelasan lebih lanjut dapat menghubungi call center Remid melalui
                                            nomor <strong>021 - 50127667</strong>.</li>
                                    </ul>
                                </li>
                                <li style={{ listStyleType: 'lower-roman' }}>Dalam hal terjadi penutupan Akun sebagaimana dimaksud sebelumnya di
                                    atas, Konsumen Remid wajib menyelesaikan seluruh kewajiban Konsumen
                                    Remid kepada Remid yang timbul dan telah ada sebelum penutupan Akun.
                                    Konsumen Remid setuju bahwa Syarat dan Ketentuan ini akan tetap berlaku
                                    sampai seluruh kewajiban Konsumen Remid telah dipenuhi.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Kami berhak untuk mengurangi biaya-biaya atau kewajiban Anda yang masih
                                    terutang, termasuk namun tidak terbatas pada pembayaran yang dibutuhkan
                                    dalam menjalankan layanan Remid</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Untuk melakukan penutupan Akun, Anda dapat menghubungi call center
                                    melalui nomor <strong>021 - 50127667</strong> atau customer service melalui email <strong>cs@rem.id</strong>.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda selaku Konsumen Remid dengan ini menyatakan bahwa akan
                                    membebaskan Remiddari dari segala bentuk kerugian, klaim, pengeluaran,
                                    kerusakan, kewajiban pembayaran biaya, dan/atau gugatan dalam bentuk
                                    apapun sehubungan dengan penutupan Akun termasuk yang diajukan oleh
                                    para ahli waris, pelaksana, pengurus atau pihak ketiga manapun yang
                                    mewakili Konsumen Remid.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Sehubungan dengan penutupan Akun, Konsumen Remid dan Remid setuju
                                    untuk mengesampingkan ketentuan Pasal 1266 Kitab Undang-Undang
                                    Hukum Perdata, sepanjang mengenai diperlukannya suatu putusan
                                    pengadilan untuk penutupan Akun dan/atau pengakhiran Syarat dan
                                    Ketentuan ini.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Penutupan Akun tidak membatasi hak Kami untuk menuntut ganti kerugian,
                                    melaporkan Konsumen Remid kepada pihak berwenang, dan/atau
                                    mengambil tindakan lain yang Remid anggap perlu untuk melindungi
                                    kepentingan Remid.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Pemblokiran Akun
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Kami memiliki kewenangan untuk melakukan pemblokiran Akun baik secara
                                    sementara atau pun permanen terhadap Akun Anda, termasuk semua fitur
                                    dan Layanan.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Pemblokiran Akun dapat terjadi apabila:
                                    <ul style={{ listStyleType: 'none' }}>
                                        <li style={{ listStyleType: 'decimal' }}>Konsumen Remid salah memasukkan pin transaksi sesuai dengan
                                            batasan maksimal yang Kami berlakukan, yaitu sebanyak 3 (tiga) kali.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Konsumen Remid salah memasukkan password untuk masuk ke
                                            aplikasi Remid sesuai dengan batasan maksimal yang Kami
                                            berlakukan, yaitu sebanyak 3 (tiga) kali.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Kami mendapat permintaan dari Anda selaku pemilik Akun untuk
                                            memblokir Akun Anda.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Permintaan dari otoritas negara atau otoritas penegak hukum yang
                                            berwenang.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Jika terjadi hal-hal yang merugikan Anda, Kami, atau pihak ketiga
                                            lainnya, termasuk tindakan penipuan, tindakan curang atau tindakan
                                            yang tidak sesuai dengan ketentuan yang telah Kami tetapkan.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Laporan Anda bahwa telepon seluler Anda telah dicuri atau hilang
                                            atau diretas, dengan menyampaikan alasan dan bukti-bukti terkait hal
                                            tersebut.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Sistem Kami membaca bahwa terdapat indikasi transaksi yang
                                            mencurigakan.</li>
                                        <li style={{ listStyleType: 'decimal' }}>Berdasarkan hasil investigasi Kami, Akun Konsumen Remid
                                            terindikasi melakukan fraud atau terindikasi sebagai sarana atau
                                            digunakan untuk tindak pidana sampai dengan dikeluarkannya
                                            putusan pengadilan terkait yang mengikat secara Hukum.</li>
                                    </ul>
                                </li>
                                <li style={{ listStyleType: 'lower-roman' }}>Untuk melakukan pemblokiran Akun, Anda dapat menghubungi call center
                                    melalui nomor <strong>021 - 50127667</strong> atau customer service melalui email <strong>cs@rem.id</strong> untuk informasi lebih lanjut.</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Anda dapat mengajukan buka blokir apabila Anda memiliki bukti pendukung
                                    yang kuat yang dapat menunjukkan bahwa tindakan mencurigakan dan/atau
                                    merugikan tidak terjadi, yaitu dengan menyampaikannya kepada call center
                                    melalui nomor <strong>021 - 50127667</strong> atau customer service melalui email <strong>cs@rem.id</strong>. Kami akan melakukan analisa atas bukti pendukung yang
                                    diberikan untuk menentukan apakah buka blokir dapat dilakukan atau tidak.</li>
                            </ul>
                        </li>
                    </ul>
                );
            case "Perlindungan dan Keamanan Informasi Pribadi":
                return (
                    <ul>
                        Kami sangat mengutamakan keamanan dan kenyamanan Anda dalam menggunakan
                        Layanan. Dengan mengakses atau menggunakan Layanan yang disediakan oleh Remid
                        (termasuk penggunaan Layanan melalui Mitra, maka Anda telah setuju bahwa
                        pengumpulan, pemanfaatan dan penggunaan data yang Anda sampaikan kepada Kami
                        telah tunduk pada Syarat dan Ketentuan serta Kebijakan Privasi yang telah kami tetapkan
                        dan merupakan satu kesatuan dan bagian yang tidak terpisahkan dengan Syarat dan
                        Ketentuan ini.
                    </ul>
                );
            case "Hak Kekayaan Intelektual":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Layanan yang disediakan oleh Remid termasuk nama, logo, kode, merek, desain,
                            tampilan aplikasi, teknologi, dan model bisnis sepenuhnya dilindungi oleh hak cipta,
                            merek dan hak kekayaan intelektual lainnya berdasarkan Hukum yang berlaku di
                            negara Republik Indonesia. Syarat dan Ketentuan ini tidak dan dengan cara apa pun
                            tidak akan dianggap sebagai pemberian izin kepada Anda untuk menggunakan
                            setiap hak kekayaan intelektual Kami.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Jika Remid menemukan adanya indikasi/dugaan pelanggaran Syarat dan Ketentuan
                            ini khususnya perihal hak kekayaan intelektual, Remid berhak untuk melakukan
                            investigasi lebih lanjut, mengakhiri akses Anda terhadap Remid Transfer Link beserta
                            Layanan dan setiap dan segala fitur yang ada di dalamnya, serta melakukan upaya
                            hukum lainnya untuk menindaklanjuti indikasi/dugaan pelanggaran tersebut.</li>
                    </ul>
                );
            case "Penautan Akun":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Konsumen Remid dapat melakukan penautan Akun dengan aplikasi atau platform
                            milik Mitra/pihak ketiga lainnya yang bekerja sama dengan Remid dengan antara lain
                            memilih pilihan penautan atau pilihan lain sebagaimana ditentukan oleh aplikasi atau
                            platform Mitra/pihak ketiga tersebut.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Konsumen Remid dapat menghentikan penautan setiap saat dengan melakukan
                            pengakhiran penautan akun atau pilihan pengakhiran lain sebagaimana ditentukan
                            oleh aplikasi atau platform Mitra/pihak ketiga tersebut.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Konsumen Remid mengerti dan memahami untuk tunduk pada syarat dan ketentuan
                            yang lebih khusus yang diatur oleh Mitra/pihak ketiga tersebut dan/atau syarat dan
                            ketentuan khusus yang ditetapkan lebih lanjut oleh Kami pada aplikasi atau platform
                            milik Mitra/pihak ketiga tersebut (sebagaimana relevan). Kami tidak bertanggung
                            jawab atas kelalaian/kesalahan Konsumen Remid yang melakukan transaksi di
                            aplikasi atau platform milik Mitra/pihak ketiga tersebut.</li>
                    </ul>
                );
            case "Keadaan Kahar (Force Majeure)":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda memahami bahwa layanan dapat terhenti baik sebagian maupun seluruhnya
                            yang disebabkan karena kejadian-kejadian atau hal tertentu yang disebabkan oleh
                            atau timbul karena suatu kejadian keadaan kahar (Force Majeure) yang meliputi
                            peristiwa-peristiwa di luar kekuasaan manusia, termasuk namun tidak terbatas pada
                            kebakaran, ledakan atau bencana lain, angin topan, blokade, konflik bersenjata,
                            huru-hara, pemogokan atau gangguan perburuhan lain, kerusuhan/kegaduhan
                            masyarakat, bencana alam, gangguan keadaan peralatan, sistem yang tidak
                            berfungsi, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah,
                            kegagalan sistem perbankan, layanan atau jasa internet service provider atau
                            layanan pihak ketiga lainnya yang bukan disebabkan oleh karena kelalaian Kami
                            (<strong>“Keadaan Kahar”</strong>).</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Apabila pelaksanaan Transfer Dana tidak terlaksana karena terjadinya Keadaan
                            Kahar dan sepanjang Dana yang Anda kirimkan sedang dalam penguasaan Kami,
                            maka Kami akan segera memberitahukan kepada Pengirim atas Keadaan Kahar
                            tersebut dan melaksanakan Perintah Transfer Dana pada:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'decimal' }}>5 (lima) Hari Kerja setelah berakhirnya Keadaan Kahar seperti bencana alam,
                                    keadaan bahaya, huru-hara, konflik bersenjata, dan/atau keadaan darurat lain
                                    yang ditetapkan pemerintah yang terjadi di daerah atau lokasi Kami; atau</li>
                                <li style={{ listStyleType: 'decimal' }}>1 (satu) Hari Kerja setelah berakhirnya Keadaan Kahar seperti kerusakan
                                    pada sistem infrastruktur elektronik atau non-elektronik yang berpengaruh
                                    langsung terhadap pelaksanaan Perintah Transfer Dana yang tidak dapat
                                    dikontrol oleh Kami atau kegagalan sistem kliring atau sistem Transfer Dana;
                                    atau</li>
                                <li style={{ listStyleType: 'decimal' }}>Sesuai waktu yang ditentukan oleh Bank Indonesia untuk keadaan yang
                                    menyebabkan kendala pelaksanaan Perintah Transfer Dana yang disebabkan
                                    oleh ketetapan Bank Indonesia.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda setuju untuk melepaskan Kami dari setiap tuntutan dan klaim, jika Kami tidak
                            dapat memenuhi instruksi Anda melalui layanan Remid baik sebagian maupun
                            seluruhnya yang disebabkan karena Keadaan Kahar.</li>
                    </ul>
                );
            case "Hukum Yang Berlaku dan Penyelesaian Sengketa":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Syarat dan Ketentuan ini diatur dan ditafsirkan berdasarkan hukum negara Republik
                            Indonesia.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Segala perselisihan atau pertentangan yang timbul sehubungan dengan atau terkait
                            dengan hal-hal yang diatur dalam Syarat dan Ketentuan (maupun bagian
                            daripadanya) termasuk perbedaan penafsiran atas ketentuan Syarat dan Ketentuan
                            ini (<strong>“Perselisihan”</strong>) wajib diselesaikan dengan cara sebagai berikut:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'decimal' }}>Anda dan Kami (untuk selanjutnya secara bersama-sama disebut sebagai
                                    “Para Pihak”) setuju untuk menggunakan usaha terbaik untuk menyelesaikan
                                    Perselisihan yang timbul berdasarkan Syarat dan Ketentuan ini secara
                                    musyawarah untuk mencapai mufakat dalam jangka waktu 30 (tiga puluh)
                                    Hari Kalender.</li>
                                <li style={{ listStyleType: 'decimal' }}>Apabila dalam jangka waktu 30 (tiga puluh) Hari Kalender sejak
                                    disampaikannya maksud untuk menyelesaikan masalah secara musyawarah
                                    oleh salah satu pihak kepada pihak lainnya, melalui surat tertulis, Para Pihak
                                    tidak dapat menyelesaikan Perselisihan secara musyawarah, maka Para
                                    Pihak akan menyelesaikan permasalahannya melalui Pengadilan Negeri
                                    Jakarta Selatan.</li>
                            </ul>
                        </li>
                    </ul>
                );
            case "Pernyataan dan Jaminan":
                return (
                    <ul>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda merupakan individu yang sah secara hukum memiliki KTP berdasarkan hukum
                            Republik Indonesia. Segala risiko dan kerugian yang timbul akibat tidak dipenuhinya
                            persyaratan ini menjadi risiko Anda dan Anda tidak berhak untuk membatalkan atau
                            mengesampingkan persetujuan yang telah Anda berikan berdasarkan Syarat dan
                            Ketentuan ini atau kewajiban yang mungkin timbul atas penggunaan layanan Kami
                            yang telah Anda adakan.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>
                            Anda menyatakan dan menjamin bahwa:
                            <ul style={{ listStyleType: 'none' }}>
                                <li style={{ listStyleType: 'lower-roman' }}>Dana bukan merupakan dana yang berasal dari Pencucian Uang atau tindak
                                    pidana atau dari aktivitas yang bertentangan dengan Hukum yang berlaku;
                                    dan</li>
                                <li style={{ listStyleType: 'lower-roman' }}>Layanan tidak akan digunakan untuk memfasilitasi Pencucian Uang atau
                                    tindak pidana yang dilarang berdasarkan Hukum yang berlaku, termasuk
                                    namun tidak terbatas untuk tujuan kriminal, penipuan, pelanggaran Hukum
                                    atau aktivitas lain yang bertentangan dengan Hukum yang berlaku, Syarat
                                    dan Ketentuan, syarat dan ketentuan mitra Kami dan dapat merugikan pihak
                                    manapun.</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda menyatakan dan menjamin bahwa Transfer Dana yang Anda perintahkan
                            bukan merupakan transaksi untuk maksud mengelabui, mengaburkan, atau
                            menghindari pelaporan kepada Pusat Pelaporan dan Analisis Transaksi Keuangan
                            (PPATK) berdasarkan ketentuan Hukum yang berlaku di Republik Indonesia. Anda
                            sepenuhnya bertanggung jawab serta melepaskan Remid dari tuntutan, klaim, atau
                            ganti rugi dalam bentuk apapun apabila Anda dinyatakan melakukan tindakan pidana
                            Pencucian Uang berdasarkan ketentuan Hukum yang berlaku.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda dilarang untuk melakukan Perbuatan Curang atau tindakan yang dapat
                            mengganggu atau merusak reputasi Kami melalui Layanan yang disediakan oleh
                            Remid.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>
                            Anda menyatakan dan menjamin bahwa Informasi Data merupakan data yang benar,
                            akurat, dan tidak melanggar hak (tidak terbatas pada Hak Kekayaan Intelektual) atau
                            kepentingan manapun.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>
                            Anda tidak akan memberikan hak, wewenang dan/atau kuasa untuk menggunakan
                            data, Kode MTCN, Kode Setor dan Kode Tarik yang berada dalam penguasaan Anda
                            dalam kondisi apa pun dan karena alasan apa pun kepada pihak lain yang tidak
                            berwenang.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Anda setuju dan mengizinkan Kami memberikan dan/atau mendistribusikan Data
                            Pribadi Anda kepada perwakilan dan/atau perusahaan induk dan/atau pemegang
                            saham dan/atau perusahaan afiliasinya dan/atau pihak ketiga yang telah melakukan
                            kerja sama dengan Kami, untuk tujuan administrasi dan/atau penawaran
                            produk/layanan keuangan dan/atau dalam rangka peningkatan layanan terhadap
                            Anda. Dalam hal Anda tidak memberikan persetujuan kepada Kami (yang
                            disampaikan melalui Remid Transfer Link) untuk memberikan dan/atau
                            menyebarluaskan Data Pribadi Anda kepada pihak ketiga lain yang telah melakukan
                            kerja sama dengan Kami untuk tujuan sebagaimana disebutkan dalam butir ini, Kami
                            hanya akan menggunakan Data Pribadi Anda untuk kepentingan internal Kami dan
                            Data Pribadi tersebut tidak akan diberikan dan/atau disebarluaskan kepada pihak
                            lain di luar Kami, kecuali sebagaimana diatur pada Hukum dan Peraturan
                            Pelindungan Data Pribadi atau diminta oleh badan-badan pemerintah.</li>
                        <li style={{ listStyleType: 'lower-alpha' }}>Dengan menggunakan Layanan dan/atau Remid Transfer Link maka Anda telah
                            memahami dan menerima seluruh instruksi dan ketentuan yang telah ditetapkan oleh
                            Kami.</li>
                    </ul>
                );
            case "Perubahan":
                return (
                    <ul>
                        Kami berhak untuk mengubah Syarat dan Ketentuan ini dari waktu ke waktu dengan
                        mengumumkannya kepada Anda, misalnya melalui website Kami atau sarana komunikasi
                        lainnya. Kami mendorong Anda untuk selalu mengetahui setiap perubahan pada Syarat dan
                        Ketentuan ini. Jika Anda terus menggunakan layanan Remid setelah adanya perubahan,
                        penambahan dan/atau variasi apa pun dalam Syarat dan Ketentuan ini, Anda dianggap
                        mengetahui dan menerima perubahan tersebut. Jika Anda tidak setuju atas perubahan,
                        penambahan dan/atau variasi yang kami cantumkan, maka Anda dapat berhenti
                        menggunakan Layanan yang disediakan oleh Remid.
                    </ul>
                );
            case "Keberlakuan":
                return (
                    <ul>
                        Jika terdapat suatu ketentuan dalam Syarat dan Ketentuan yang ternyata diketahui
                        melanggar ketentuan Hukum yang berlaku, baik sebagian atau seluruhnya, maka ketentuan
                        yang dianggap melanggar tersebut dikesampingkan dari Syarat dan Ketentuan dan atas
                        ketentuan lain dalam Syarat dan Ketentuan tetap berlaku dan mengikat.
                    </ul>
                );
            case "Hubungi Kami":
                return (
                    <ul>
                        Dalam hal Anda memiliki pertanyaan, permintaan, keluhan, pengaduan, atau kepentingan
                        penyampaian informasi apa pun terkait Layanan yang disediakan oleh Remid dan/atau
                        Syarat dan Ketentuan ini, Anda dapat menghubungi Kami melalui call center <strong>021 -50127667</strong> atau melalui email ke <strong>cs@rem.id</strong>.
                    </ul>
                );
            // Add cases for other items

            default:
                return null; // Return null if no content is defined for the item
        }
    };

    return (
        <LayoutAppBar title="Syarat dan Ketentuan" primary="white">
            <Stack spacing={3} sx={{ p: "1rem" }}>
                <Typography sx={{ fontWeight: 800, fontSize: "1.5rem" }}>
                    Syarat dan Ketentuan Remid Transfer Link (“Syarat dan Ketentuan”)
                </Typography>
                <br /> {/* Line break added */}
                <Box sx={{ fontSize: ".875rem" }}>
                    <Typography paragraph fontSize="inherit">
                        A. Pendahuluan Syarat dan Ketentuan
                    </Typography>
                    <Typography paragraph fontSize="inherit">
                        Remid adalah layanan Transfer Dana yang disediakan oleh PT Remid Karya Indonesia,
                        sebuah perseroan terbatas yang didirikan dan beroperasi secara sah berdasarkan hukum
                        negara Republik Indonesia (<strong>“Remid”</strong> atau <strong>“Kami”</strong>). Dengan menggunakan layanan Remid
                        baik secara langsung maupun melalui mitra-mitra yang bekerja sama dengan Remid, anda,
                        baik selaku Pengirim atau Penerima (<strong>“Anda”</strong>) mengakui dan menyetujui bahwa Anda telah
                        membaca, mengerti dan setuju untuk mematuhi seluruh Syarat dan Ketentuan Remid yang
                        berlaku. Syarat dan Ketentuan ini akan berlaku sebagai perjanjian antara Anda dengan
                        Kami. Dalam hal Anda tidak menyetujui Syarat dan Ketentuan ini, maka Anda berhak
                        memilih untuk tidak menggunakan Layanan yang disediakan oleh Remid.
                    </Typography>
                    <Typography paragraph fontSize="inherit">
                        B. Isi Syarat dan Ketentuan
                    </Typography>
                    <Typography paragraph fontSize="inherit" sx={{ mb: 0 }}>
                        Berikut adalah penjabaran mengenai Syarat dan Ketentuan layanan Remid:
                    </Typography>
                </Box>
                <Box
                    sx={{
                        px: "1rem",
                        "& > ol": {
                            p: 0,
                        },
                        "& > ol > li": {
                            textDecoration: "underline",
                        },
                        "& > ol *": {
                            lineHeight: 1.75,
                            fontFamily: "Poppins"
                        }
                    }}
                >
                    <ol type="1">
                        {[
                            "Definisi",
                            "Pendaftaran Akun dan Proses Verifikasi",
                            "Ketentuan Umum",
                            "Pelaksanaan Transfer Dana",
                            "Biaya Layanan (Service fee)",
                            "Kode MTCN dan Masa Berlaku Kode MTCN",
                            "Pembatalan Perintah Transfer Dana",
                            "Kewajiban Konsumen Remid dan Larangan Konsumen Remid",
                            "Batasan Tanggung Jawab Kami dan Ganti Rugi",
                            "Penangguhan Layanan, Penutupan Akun dan Pemblokiran Akun",
                            "Perlindungan dan Keamanan Informasi Pribadi",
                            "Hak Kekayaan Intelektual",
                            "Penautan Akun",
                            "Keadaan Kahar (Force Majeure)",
                            "Hukum Yang Berlaku dan Penyelesaian Sengketa",
                            "Pernyataan dan Jaminan",
                            "Perubahan",
                            "Keberlakuan",
                            "Hubungi Kami",
                        ].map((item, idx) => (
                            <React.Fragment key={`${item}-${idx}`}>
                                <li>{item}</li>
                                {getContentByItem(item)}
                                <br /> {/* Line break added */}
                            </React.Fragment>
                        ))}
                    </ol>
                </Box>
            </Stack>
        </LayoutAppBar>
    );
};

export default TermOfServicePage;
