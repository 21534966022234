import IAuthRepository from "domain/repository/Auth";
import { PraRegisterRequest, RegisterRequest } from "domain/entity/Auth/model/Register";

export default class SignUpUseCase {
    private service: IAuthRepository;

    constructor(service: IAuthRepository) {
        this.service = service;
    }

    execute(body: RegisterRequest) {
        return this.service.doRegister(body);
    }

    praRegister(body: PraRegisterRequest) {
        return this.service.doPraRegister(body);
    }
}
