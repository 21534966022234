import React, { useState } from 'react';
import { SmsRounded } from "@mui/icons-material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import LayoutAppBar, { LayoutAppBarProps } from "config/components/layout-app-bar";
import { useNavigate } from 'react-router-dom';

type OTPMethodList = Readonly<{
    sms?: string | null;
    email?: string | null;
    whatsapp?: string | null;
}>

type OTPMethod = keyof OTPMethodList | ""

type OTPMobileLayoutProps = {} &
    Omit<LayoutAppBarProps, "children">;

const SendOtpToPage: React.FC<OTPMobileLayoutProps> = (props) => {
    const { onBack, title = "Verifikasi", primary, ...rest } = props;

    const navigate = useNavigate();
    
    const [selectedMethod, setSelectedMethod] = useState<OTPMethod>("")

    const handleMethodSelect = async (method: OTPMethod) => {
        navigate('/forgot-password?method='+method, {
            state: { goBack: navigate }
        });
    };

    return (
        <LayoutAppBar title={title} primary={primary} onBack={onBack}>
            <Stack alignItems="center" sx={{ textAlign: "center" }} spacing={3}>
                <Typography sx={{ fontWeight: 700 }}>
                    Pilih Metode Verifikasi
                </Typography>
                <Typography sx={{ fontSize: ".75rem" }}>
                    Pilih salah satu metode dibawah ini untuk
                    mendapatkan kode OTP
                </Typography>

                <IconButton
                    sx={{
                        width: "100%",
                        borderRadius: 0,
                        justifyContent: "flex-start",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                    onClick={() => handleMethodSelect("sms")}
                >
                    <SmsRounded fontSize="medium" sx={{ color: '#EBA440', padding: '1rem' }} />
                    <Box sx={{ width: '100%', textAlign: 'justify' }}>
                        <Box sx={{ fontSize: '16px', fontWeight: '600' }}>Kirim ke SMS</Box>
                    </Box>
                </IconButton>

                <IconButton
                    sx={{
                        width: "100%",
                        borderRadius: 0,
                        justifyContent: "flex-start",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                    onClick={() => handleMethodSelect("whatsapp")}
                >
                    <WhatsAppIcon fontSize="medium" sx={{ color: '#EBA440', padding: '1rem' }} />
                    <Box sx={{ width: '100%', textAlign: 'justify' }}>
                        <Box sx={{ fontSize: '16px', fontWeight: '600' }}>Kirim ke WhatsApp</Box>
                    </Box>
                </IconButton>

                <IconButton
                    sx={{
                        width: "100%",
                        borderRadius: 0,
                        justifyContent: "flex-start",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                    }}
                    onClick={() => handleMethodSelect("email")}
                >
                    <AlternateEmailIcon fontSize="medium" sx={{ color: '#EBA440', padding: '1rem' }} />
                    <Box sx={{ width: '100%', textAlign: 'justify' }}>
                        <Box sx={{ fontSize: '16px', fontWeight: '600' }}>Kirim ke E-mail</Box>
                    </Box>
                </IconButton>
            </Stack>
        </LayoutAppBar>
    );
};

export default SendOtpToPage;