import React from "react";
import { Box } from "@mui/material";
import LayoutAppBar from "config/components/layout-app-bar";
import FormChangePassword from "presentation/pages/view/change-password/components/form-change-password";
import { ChangePasswordViewModel } from "presentation/pages/viewmodel/change-password";
import Message from "config/components/message";
import useLanguage from "config/hooks/useLanguage";

type ChangePasswordPageProps = {
    viewModel: ChangePasswordViewModel;
};

const ChangePasswordPage: React.FC<ChangePasswordPageProps> = (props) => {
    const { viewModel } = props;

    const { t } = useLanguage();

    return viewModel.success ? (
        <Box sx={{ height: "100vh", px: "2rem" }}>
            <Message
                header={t("general.header1")}
                subHeader={t("general.subheader2")}
                btnText={t("auth.next_text")}
                onClick={viewModel.handleSuccessRedirect}
                type="success"
            />
        </Box>
    ) : (
        <LayoutAppBar title={t("forgot_pass.label5")}>
            <FormChangePassword {...props} />
        </LayoutAppBar>
    );
};

export default ChangePasswordPage;
