import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";
import { OTPData } from "config/shared/types";
import TransactionUseCase from "domain/interactor/Transaction/TransactionUseCase";
import { useMutation, useQueries, useQuery } from "react-query";
import { GlobalContext } from "config/context/GlobalContext";
import log from "config/log";
import { TransactionData, TransactionResponse } from "domain/entity/Auth/model/Transaction";
import { TransactionDetailRequest } from "domain/entity/Auth/model/TransactionDetail";
import { AxiosError } from "axios";
import useLanguage from "config/hooks/useLanguage";
import GlobalResourceUseCase from "domain/interactor/GlobalResource";
import { useForm } from "react-hook-form";

type ListTrxUseCases = {
    useCase: TransactionUseCase;
    globalUseCase: GlobalResourceUseCase;
};

const initialValues = {
};

const useListTransaksiViewModel = ({
    globalUseCase,
    useCase
}: ListTrxUseCases) => {
    const { t } = useLanguage();

    const [{ user }, dispatch] = React.useContext(GlobalContext);

    const location = useLocation();

    const navigate = useNavigate();

    const [status, setStatus] = React.useState('');

    const [productId, setProductId] = React.useState('');

    const [success, setSuccess] = React.useState(false);

    const [showPIN, setShowPIN] = React.useState(false);

    const [showPINValidation, setShowPINValidation] = React.useState(false);

    const { data: trxData, isLoading } = useQuery(["/deposit/list", status, user?.userId, productId], () =>
        useCase.execute({ status, userId: user?.userId || "", productId: productId || "" })
    )

    const _email = "";

    const submitButtonStatus = _email !== "";

    const onVerificationSubmit = () => {
        setSuccess(true);
    };

    const [selectedItem, setSelectedItem] = React.useState<TransactionData | null>(null);

    const handlePIN = (item: any) => {
        setShowPIN(true);
        setSelectedItem(item)
    };

    const handleChangeStatus = (event: SelectChangeEvent) => {
        // console.log(event.target.value);
        setStatus(event.target.value as string);
    };

    const handleChangeProduct = (event: SelectChangeEvent) => {
        // console.log(event.target.value);
        setProductId(event.target.value as string);
        setStatus('');
    };

    const { mutateAsync, isLoading: pinloading } = useMutation((data: TransactionDetailRequest) =>
        useCase.listtrxdtl(data)
    );

    const [{ data: produkList }] = useQueries([
        {
            queryKey: "/produk",
            queryFn: () => globalUseCase.getProduk(),
            staleTime: Infinity,
        },
    ]);

    const { data: listStatus, refetch: refetchStatus } = useQuery(
        ["status", productId],
        () => globalUseCase.getStatus({ productId: productId }),
        { enabled: false }
    );

    const onPINValidation = async (pin: string) => {
        // setShowPINValidation(false);
        // navigate('/transaction-detail');
        try {
            if (!selectedItem) return;

            const userId = user?.userId ? user.userId.toString() : "";
            const result = await mutateAsync({ depositId: selectedItem.transDepositId, pin, userId: userId || "" });
            navigate("/transaction-detail", {
                state: {
                    parent: "/dashboard/transaction",
                    data: result,
                },
            });
        } catch (e: unknown) {
            // handle err
            // throw err;
            log(e);

            const { response = null } = e as AxiosError;

            if (!response) {
                dispatch({
                    type: "SHOW_OVERLAY",
                    payload: {
                        header: t("general.general_error"),
                        subHeader: "Terjadi Kesalahan",
                        type: "warning",
                    },
                });
            } else {
                const { message = "", rc = "" } = response.data as any;

                dispatch({
                    type: "SHOW_OVERLAY",
                    payload: {
                        header: t("general.general_error"),
                        subHeader: t("rc." + rc),
                        type: "warning",
                    },
                });
            }
        }
        // setShowQR(true);
    };

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
    } = useForm({
        defaultValues: initialValues,
    });

    const onBack = () => {
        setShowPIN(false);
        return showPIN;
    };

    React.useEffect(() => {
        if (productId !== "") refetchStatus();
    }, [setValue, productId]);

    return {
        success,
        showPIN,
        onVerificationSubmit,
        handleChangeStatus,
        handleChangeProduct,
        handlePIN,
        onPINValidation,
        onBack,
        trxData,
        isLoading,
        status,
        productId,
        pinloading,
        produkList,
        listStatus,
    };
};

export type ListTransaksiViewModel = ReturnType<
    typeof useListTransaksiViewModel
>;

export default useListTransaksiViewModel;